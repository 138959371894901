import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 120
  }
}))

const IncludeExcludeSelect = ({ name, onChange, value }) => {
  const classes = useStyles()

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={value}
        onChange={onChange}
        inputProps={{
          name,
          id: name
        }}
      >
        <MenuItem value="include-all">Include all</MenuItem>
        <MenuItem value="include">Include only the selected</MenuItem>
        <MenuItem value="exclude">Exclude the selected</MenuItem>
      </Select>
    </FormControl>
  )
}

IncludeExcludeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default IncludeExcludeSelect
