import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: 'pointer',
    display: 'inline-block',
    marginLeft: theme.spacing(1),
    height: '1.2em',
    position: 'relative',
    top: '-0.1em',
    verticalAlign: 'middle',
    width: '1.2em',
  },
}))

const HtmlTooltip = withStyles(theme => {
  return {
    tooltip: {
      backgroundColor: blueGrey[50],
      border: `1px solid ${blueGrey[100]}`,
      color:
        theme.palette.type === 'dark' ? theme.palette.primary.dark : 'inherit',
      padding: theme.spacing(2),
    },
  }
})(Tooltip)

const InfoButton = ({ iconComponent, tooltipContent }) => {
  const classes = useStyles()

  return (
    <HtmlTooltip title={tooltipContent}>
      {iconComponent || (
        <InfoIcon className={classes.icon} fontSize="inherit" />
      )}
    </HtmlTooltip>
  )
}

InfoButton.propTypes = {
  iconComponent: PropTypes.any,
  tooltipContent: PropTypes.any,
}
InfoButton.defaultProps = {
  iconComponent: null,
  tooltipContent: null,
}

export default InfoButton
