import axios from 'axios'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  error: false,
  isLoaded: false,
  items: [],
}

const FETCH_SHOP_TYPES = 'FETCH_SHOP_TYPES'
const POPULATE_SHOP_TYPES = 'POPULATE_SHOP_TYPES'
const SET_SHOP_TYPES_ERROR = 'SET_SHOP_TYPES_ERROR'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SHOP_TYPES:
      return {
        ...state,
        items: [],
        isLoaded: false,
      }
    case POPULATE_SHOP_TYPES:
      return {
        ...state,
        items: action.payload,
        isLoaded: true,
      }
    case SET_SHOP_TYPES_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true,
      }
    default:
      return state
  }
}

export const fetchShopTypesAction = () => async dispatch => {
  try {
    dispatch({ type: SET_SHOP_TYPES_ERROR, payload: false })
    dispatch({ type: FETCH_SHOP_TYPES })
    const response = await axios.get('/getShopTypes')
    dispatch({
      type: POPULATE_SHOP_TYPES,
      payload: response.data,
    })
  } catch (e) {
    dispatch({
      type: SET_SHOP_TYPES_ERROR,
      payload: parseError(`fetchShopTypesAction - ${parseError(e)}`),
    })
  }
}

export default reducer
