import * as constants from '../../constants'
import axios from 'axios'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import differenceInMinutes from 'date-fns/differenceInMinutes'

import { parseError } from '../../../helpers'
import { setWebSocketMessageAction } from '../global/actions'

export const setFindStockProgressAction = stockUnitsFound => ({
  type: constants.SET_FIND_STOCK_PROGRESS,
  payload: stockUnitsFound
})

export const setFindStockErrorAction = value => ({
  type: constants.SET_FIND_STOCK_ERROR,
  payload: value
})

export const findStockAction = () => async (dispatch, getState) => {
  const startTimeMs = Date.now()
  const timer = setInterval(() => {
    const now = Date.now()
    const secs = differenceInSeconds(now, startTimeMs)
    const mins = differenceInMinutes(now, startTimeMs)
    dispatch({
      type: constants.FIND_STOCK_TIMER,
      payload: `${mins}m ${secs % 60}s`
    })
  }, 1000)

  const {
    criteria,
    data: {
      skus: { total: skuCount }
    },
  } = getState()
  const {
    branchRecallPercentage,
    colourLevelFilters,
    cumulativeWeeks,
    gender,
    maxWarehouseStock,
    maxWarehouseStockExcluded,
    minBranchStock,
    minBranchStockExcluded,
    minMargin,
    minMarginExcluded,
    minRecallPrice,
    minRecallPriceExcluded,
    productGroups,
    reallocatePercentage,
    reallocationBranches,
    recallBranches,
    recallTimeframe,
    removeTriplicates,
    seasons,
    shopTypes,
    sizeLevelFilters,
    skuLevelFilters,
    sortValue,
    suppliers,
    unitsToRecall
  } = criteria
  const filters = {
    branchRecallPercentage,
    colourLevelFilters,
    cumulativeWeeks,
    gender,
    maxWarehouseStock,
    maxWarehouseStockExcluded,
    minBranchStock,
    minBranchStockExcluded,
    minMargin,
    minMarginExcluded,
    minRecallPrice,
    minRecallPriceExcluded,
    productGroups,
    reallocatePercentage,
    reallocationBranches,
    recallBranches,
    recallTimeframe,
    removeTriplicates,
    seasons,
    shopTypes,
    sizeLevelFilters,
    skuLevelFilters,
    sortValue,
    suppliers,
    unitsToRecall,
  }

  try {
    dispatch(setFindStockErrorAction(false))
    dispatch({ type: constants.FIND_STOCK })
    const response = await axios.post('/postFindStock', {
      filters,
      skuCount: skuCount || 0
    })
    // console.log(response.data)
    dispatch({
      type: constants.POPULATE_FIND_STOCK,
      payload: response.data
    })
  } catch (e) {
    dispatch(
      setFindStockErrorAction(parseError(`findStockAction - ${parseError(e)}`))
    )
  } finally {
    dispatch(setWebSocketMessageAction(null))
    dispatch({
      type: constants.SET_ACTIVE_SCREEN,
      payload: constants.SCREEN_SKUS
    })
    clearInterval(timer)
    dispatch({
      type: constants.FIND_STOCK_TIMER,
      payload: null,
    })
  }
}
