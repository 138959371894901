// Screens
export const SCREEN_CRITERIA = 'SCREEN_CRITERIA'
export const SCREEN_HOME = 'SCREEN_HOME'
export const SCREEN_SKUS = 'SCREEN_SKUS'

// App action constants
export const RESET_APP = 'RESET_APP'
export const SET_ACTIVE_RECALL_ID = 'SET_ACTIVE_RECALL_ID'
export const SET_ACTIVE_SCREEN = 'SET_ACTIVE_SCREEN'
export const SET_SIDEBAR_OPEN = 'SET_SIDEBAR_OPEN'
export const SET_THEME = 'SET_THEME'
export const SET_THEME_TYPE = 'SET_THEME_TYPE'
export const SET_WEBSOCKET_MESSAGE = 'SET_WEBSOCKET_MESSAGE'
export const SHOW_REFUNDS = 'SHOW_REFUNDS'

// Criteria steps
export const STEP_START = 0
export const STEP_FILTERS = 1
export const STEP_PARAMETERS = 2
export const STEP_SKUS = 3
export const STEP_SORTING = 4
export const STEP_RECALL_REALLOC = 5
export const STEP_CONFIRM = 6

// Criteria action constants
export const RESET_CRITERIA = 'RESET_CRITERIA'
export const SET_BRANCHES = 'SET_BRANCHES'
export const SET_BRANCHES_INCLUDE = 'SET_BRANCHES_INCLUDE'
export const SET_COLOURS = 'SET_COLOURS'
export const SET_COLOURS_INCLUDE = 'SET_COLOURS_INCLUDE'
export const SET_BRANCH_RECALL_PERCENTAGE = 'SET_BRANCH_RECALL_PERCENTAGE'
export const SET_CUMULATIVE_WEEKS = 'SET_CUMULATIVE_WEEKS'
export const SET_GENDER = 'SET_GENDER'
export const SET_IS_DIRTY = 'SET_IS_DIRTY' // has the user started to define criteria?
export const SET_MAX_WAREHOUSE_STOCK = 'SET_MAX_WAREHOUSE_STOCK'
export const SET_MAX_WAREHOUSE_STOCK_EXCLUDED =
  'SET_MAX_WAREHOUSE_STOCK_EXCLUDED'
export const SET_MIN_BRANCH_STOCK = 'SET_MIN_BRANCH_STOCK'
export const SET_MIN_BRANCH_STOCK_EXCLUDED = 'SET_MIN_BRANCH_STOCK_EXCLUDED'
export const SET_MIN_MARGIN = 'SET_MIN_MARGIN'
export const SET_MIN_MARGIN_EXCLUDED = 'SET_MIN_MARGIN_EXCLUDED'
export const SET_MIN_RECALL_PRICE = 'SET_MIN_RECALL_PRICE'
export const SET_MIN_RECALL_PRICE_EXCLUDED = 'SET_MIN_RECALL_PRICE_EXCLUDED'
export const SET_PRODUCT_GROUPS = 'SET_PRODUCT_GROUPS'
export const SET_PRODUCT_GROUPS_INCLUDE = 'SET_PRODUCT_GROUPS_INCLUDE'
export const SET_REALLOCATE_PERCENTAGE = 'SET_REALLOCATE_PERCENTAGE'
export const SET_RECALL_TIMEFRAME_DAYS = 'SET_RECALL_TIMEFRAME_DAYS'
export const SET_REMOVE_TRIPLICATES = 'SET_REMOVE_TRIPLICATES'
export const SET_SEASONS = 'SET_SEASONS'
export const SET_SEASONS_INCLUDE = 'SET_SEASONS_INCLUDE'
export const SET_SIZES = 'SET_SIZES'
export const SET_SIZES_INCLUDE = 'SET_SIZES_INCLUDE'
export const SET_SKUS = 'SET_SKUS'
export const SET_SKUS_INCLUDE = 'SET_SKUS_INCLUDE'
export const SET_SHOP_TYPES = 'SET_SHOP_TYPES'
export const SET_SORT_VALUE = 'SET_SORT_VALUE'
export const SET_STEP = 'SET_STEP'
export const SET_STEP_COMPLETED = 'SET_STEP_COMPLETED'
export const SET_SUPPLIERS = 'SET_SUPPLIERS'
export const SET_SUPPLIERS_INCLUDE = 'SET_SUPPLIERS_INCLUDE'
export const SET_UNITS_TO_RECALL = 'SET_UNITS_TO_RECALL'
export const SET_WEBSOCKET_ID = 'SET_WEBSOCKET_ID'

// Find stock action constants
export const FIND_STOCK = 'FIND_STOCK'
export const POPULATE_FIND_STOCK = 'POPULATE_FIND_STOCK'
export const SET_FIND_STOCK_ERROR = 'SET_FIND_STOCK_ERROR'
export const SET_FIND_STOCK_PROGRESS = 'SET_FIND_STOCK_PROGRESS'
export const FIND_STOCK_TIMER = 'FIND_STOCK_TIMER'

// Recall action constants
export const COMPLETE_ISSUE_RECALL = 'COMPLETE_ISSUE_RECALL'
export const ISSUE_RECALL = 'ISSUE_RECALL'
export const SET_NEW_RECALL_ID = 'SET_NEW_RECALL_ID'
export const SET_RECALL_PROGRESS = 'SET_RECALL_PROGRESS'
export const SET_RECALL_ERROR = 'SET_RECALL_ERROR'
