import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist'

import rootReducer from './reducers'

// Remove some data from devtools for performance
// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
const composeEnhancers = composeWithDevTools({
  stateSanitizer: state => ({
    ...state,
    data: '...removed for redux-devtools performance',
    findStock: {
      ...state.findStock,
      findStockData: state.findStock.findStockData
        ? state.findStock.findStockData.map(sku => {
            const out = { ...sku }
            out.branchAnalysis = '...removed for redux-devtools performance'
            return out
          })
        : null,
    },
  }),
})

export default () => {
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
  )
  let persistor = persistStore(store)
  return { store, persistor }
}
