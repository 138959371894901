import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import global from './global'
import criteria from './criteria'
import data from './data'
import findStock from './findStock'
import recall from './recall'

export default combineReducers({
  global: persistReducer(
    // persist the global reducer in localStorage...
    {
      key: 'global',
      storage,
      // ...but don't persist these keys
      blacklist: [
        'activeRecallId',
        'activeScreen',
        'webSocketMessage',
        'webSocketId'
      ]
    },
    global
  ),
  criteria,
  data,
  findStock,
  recall
})
