import uniq from 'lodash/uniq'

import * as constants from '../../constants'
import { countSKUsAction } from '../data/skus'
import {
  filterProductGroupsByGender,
  filterProductGroupsBySeasons
} from '../../../components/CriteriaScreen/Filters/ProductGroups'

export const resetCriteriaAction = (overrides = {}) => ({
  type: constants.RESET_CRITERIA,
  payload: overrides
})

export const setStepCompletedAction = (step, isCompleted) => ({
  type: constants.SET_STEP_COMPLETED,
  payload: { step, isCompleted }
})

export const setBranchesAction = (type, items) => ({
  type: constants.SET_BRANCHES,
  payload: { type, items }
})

export const setBranchesIncludeAction = (type, value) => ({
  type: constants.SET_BRANCHES_INCLUDE,
  payload: { type, value }
})

export const setBranchRecallPercentageAction = value => ({
  type: constants.SET_BRANCH_RECALL_PERCENTAGE,
  payload: value
})

export const setColoursAction = (value, options = {}) => dispatch => {
  dispatch({
    type: constants.SET_COLOURS,
    payload: value
  })
  if (options.recountSKUs !== false) {
    dispatch(countSKUsAction())
  }
}

export const setColoursIncludeAction = (value, options = {}) => dispatch => {
  dispatch({ type: constants.SET_COLOURS_INCLUDE, payload: value })
  if (options.recountSKUs !== false) {
    dispatch(countSKUsAction())
  }
}

export const setCumulativeWeeksAction = value => ({
  type: constants.SET_CUMULATIVE_WEEKS,
  payload: parseInt(value, 10)
})

export const setGenderAction = value => (dispatch, getState) => {
  dispatch({
    type: constants.SET_GENDER,
    payload: value
  })
  // Remove invalid selected product groups
  const { gender, productGroups } = getState().criteria
  const validSelectedProductGroups = filterProductGroupsByGender(
    gender,
    productGroups.selectedItems
  )
  dispatch(setProductGroupsAction(validSelectedProductGroups))
}

export const setIsDirtyAction = value => ({
  type: constants.SET_IS_DIRTY,
  payload: value
})

export const setMaxWarehouseStockAction = value => ({
  type: constants.SET_MAX_WAREHOUSE_STOCK,
  payload: value
})

export const setMaxWarehouseStockExcludedAction = value => ({
  type: constants.SET_MAX_WAREHOUSE_STOCK_EXCLUDED,
  payload: value
})

export const setMinBranchStockAction = value => ({
  type: constants.SET_MIN_BRANCH_STOCK,
  payload: value
})

export const setMinBranchStockExcludedAction = value => ({
  type: constants.SET_MIN_BRANCH_STOCK_EXCLUDED,
  payload: value
})

export const setMinMarginAction = value => ({
  type: constants.SET_MIN_MARGIN,
  payload: value
})

export const setMinMarginExcludedAction = value => ({
  type: constants.SET_MIN_MARGIN_EXCLUDED,
  payload: value
})

export const setMinRecallPriceAction = value => ({
  type: constants.SET_MIN_RECALL_PRICE,
  payload: value
})

export const setMinRecallPriceExcludedAction = value => ({
  type: constants.SET_MIN_RECALL_PRICE_EXCLUDED,
  payload: value
})

export const setProductGroupsAction = value => dispatch => {
  dispatch({ type: constants.SET_PRODUCT_GROUPS, payload: value })
}

export const setProductGroupsIncludeAction = value => dispatch => {
  dispatch({ type: constants.SET_PRODUCT_GROUPS_INCLUDE, payload: value })
}

export const setReallocatePercentageAction = value => ({
  type: constants.SET_REALLOCATE_PERCENTAGE,
  payload: value
})

export const setRecallTimeframeAction = value => ({
  type: constants.SET_RECALL_TIMEFRAME_DAYS,
  payload: value
})

export const setRemoveTriplicatesAction = value => ({
  type: constants.SET_REMOVE_TRIPLICATES,
  payload: value
})

export const setSKUsAction = items => dispatch => {
  // make sure selectedColours and selectedItem keys are present
  // default to all checked
  const payload = items.map(i => ({
    ...i,
    selectedColours: i.selectedColours || i.colours.map(c => c.id.toString()),
    selectedSizes: i.selectedSizes || i.sizes.map(s => s.id.toString())
  }))
  dispatch({ type: constants.SET_SKUS, payload })
}

export const setSKUsIncludeAction = (value, options = {}) => dispatch => {
  dispatch({ type: constants.SET_SKUS_INCLUDE, payload: value })
  if (options.recountSKUs !== false) {
    dispatch(countSKUsAction())
  }
}

// Remove now invalid selected product groups after season changes
// This is somewhat convoluted and I'm wondering if it's worth it...
const removeInvalidSeasonProductGroupsAction = () => (dispatch, getState) => {
  const {
    criteria: {
      productGroups,
      seasons: {
        includeOrExclude: seasonsInclude,
        selectedItems: selectedSeasonItems
      }
    },
    data: {
      productGroups: { items: allProductGroupItems },
      seasons: { items: allSeasonItems }
    }
  } = getState()

  let selectedSeasons
  switch (seasonsInclude) {
    case 'include-all':
      selectedSeasons = allSeasonItems.map(s => s.season_year)
      break
    case 'include':
      selectedSeasons = selectedSeasonItems.map(s => s.value)
      break
    case 'exclude':
      selectedSeasons = allSeasonItems
        .map(s => s.season_year)
        .filter(s => !selectedSeasonItems.map(s => s.value).includes(s))
      break
    default:
      selectedSeasons = []
      break
  }

  const validProductGroups = filterProductGroupsBySeasons(
    selectedSeasons,
    allProductGroupItems
  )
  const allowedGroups = uniq(validProductGroups.map(g => g.description))

  const validSelectedProductGroups = productGroups.selectedItems.filter(g => {
    return allowedGroups.includes(g.value)
  })
  dispatch(setProductGroupsAction(validSelectedProductGroups))
}

export const setSeasonsAction = value => dispatch => {
  dispatch({
    type: constants.SET_SEASONS,
    payload: value
  })
  dispatch(removeInvalidSeasonProductGroupsAction())
}

export const setSeasonsIncludeAction = value => dispatch => {
  dispatch({
    type: constants.SET_SEASONS_INCLUDE,
    payload: value
  })
  dispatch(removeInvalidSeasonProductGroupsAction())
}

export const setSizesAction = (value, options = {}) => dispatch => {
  dispatch({
    type: constants.SET_SIZES,
    payload: value
  })
  if (options.recountSKUs !== false) {
    dispatch(countSKUsAction())
  }
}

export const setSizesIncludeAction = (value, options = {}) => dispatch => {
  dispatch({
    type: constants.SET_SIZES_INCLUDE,
    payload: value
  })
  if (options.recountSKUs !== false) {
    dispatch(countSKUsAction())
  }
}

export const setShopTypesAction = value => ({
  type: constants.SET_SHOP_TYPES,
  payload: value
})

export const setSortValueAction = value => ({
  type: constants.SET_SORT_VALUE,
  payload: value
})

export const setSuppliersAction = value => ({
  type: constants.SET_SUPPLIERS,
  payload: value
})

export const setSuppliersIncludeAction = value => ({
  type: constants.SET_SUPPLIERS_INCLUDE,
  payload: value
})

export const setStepAction = value => ({
  type: constants.SET_STEP,
  payload: value
})

export const setUnitsToRecallAction = value => ({
  type: constants.SET_UNITS_TO_RECALL,
  payload: value
})
