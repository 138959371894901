import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'

// need title as state

const AddSizeGroupDialog = ({ isOpen, handleClose, handleAdd }) => {
  const [title, setTitle] = useState('')

  const handleTitleChange = e => setTitle(e.target.value)

  const handleSubmit = () => {
    if (title.trim().length === 0) {
      return
    }
    handleAdd(title)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Add Size Group</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Title"
          fullWidth
          value={title}
          onChange={handleTitleChange}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              e.preventDefault()
              handleSubmit()
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          autoFocus
          color="primary"
          onClick={handleSubmit}
          variant="contained"
        >
          Add Size Group
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddSizeGroupDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired
}

export default AddSizeGroupDialog
