import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiLink from '@material-ui/core/Link'
import Label from '../../../Label'

import {
  setColoursIncludeAction,
  setColoursAction
} from '../../../../redux/reducers/criteria/actions'
import DownshiftMultipleSelect from '../../DownshiftMultipleSelect'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'
import ColourDialog from './ColourDialog'
import { getIncludedItemsCount } from '../../../../helpers'

export const makeColourOptions = items =>
  items.map(g => ({
    label: g.colour,
    value: g.colour
  }))

const ColourLevel = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const {
    criteria: {
      colourLevelFilters: { includeOrExclude, selectedItems }
    },
    data: {
      colours: { isLoaded, items }
    }
  } = useSelector(state => state)

  const setSelectedItem = useCallback(
    item => dispatch(setColoursAction(item)),
    [dispatch]
  )

  const options = useMemo(() => (items ? makeColourOptions(items) : []), [
    items
  ])

  const handleDialogClose = useCallback(() => setDialogOpen(false), [])
  const handleDialogOpen = useCallback(() => setDialogOpen(true), [])

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Select colours to include'
      case 'exclude':
        return 'Select colours to exclude'
      default:
        return 'Select colours'
    }
  }

  const count = getIncludedItemsCount(
    includeOrExclude,
    items.length,
    selectedItems.length
  )

  if (!isLoaded) return <CircularProgress variant="indeterminate" size={14} />

  return (
    <Box display="flex" mb={1} width="100%" flexDirection="column">
      <Box mb={2}>
        <Typography variant="caption" color="error">
          <em>NOTE: RMIS generic colour data is currently incomplete (many SKUs do not have a generic colour set), so this filter does not work perfectly. We're investigating potential fixes.</em>
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" mb={1}>
        <IncludeExcludeSelect
          value={includeOrExclude}
          name="coloursInclude"
          onChange={e => dispatch(setColoursIncludeAction(e.target.value))}
        />
        <Typography>colours</Typography>
        {isLoaded && (
          <Box display="flex" alignItems="center">
            <Label
              isError={count === 0}
              isSuccess={count > 0}
              text={`${count.toLocaleString()} / ${items.length}`}
            />
            <MuiLink as="button" onClick={handleDialogOpen}>
              View entire list
            </MuiLink>
          </Box>
        )}
      </Box>
      {includeOrExclude !== 'include-all' && (
        <DownshiftMultipleSelect
          id="colours"
          maxHeight={300}
          placeholder={getPlaceholder()}
          selectedItem={selectedItems}
          setSelectedItem={setSelectedItem}
          suggestions={options}
        />
      )}
      <ColourDialog isOpen={isDialogOpen} handleClose={handleDialogClose} />
    </Box>
  )
}

export default ColourLevel
