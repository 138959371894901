import * as constants from '../../constants'

export const criteriaInitialState = {
  // activeStep: constants.STEP_SKUS,
  // activeStep: constants.STEP_PARAMETERS,
  // activeStep: constants.STEP_FILTERS,
  // activeStep: constants.STEP_RECALL_REALLOC,
  // activeStep: constants.STEP_CONFIRM,
  activeStep: constants.STEP_START,
  branchRecallPercentage: 20,
  colourLevelFilters: {
    includeOrExclude: 'include-all',
    selectedItems: []
  },
  completedSteps: [],
  cumulativeWeeks: 4, // how many weeks data we look at for cumulative totals
  gender: 'All',
  isDirty: false, // has the user left the home screen and started defining criteria?
  maxWarehouseStock: 5,
  maxWarehouseStockExcluded: false,
  minBranchStock: 15,
  minBranchStockExcluded: false,
  minMargin: 35,
  minMarginExcluded: false,
  minRecallPrice: 20,
  minRecallPriceExcluded: false,
  productGroups: {
    includeOrExclude: 'exclude',
    selectedItems: [
      {
        label: 'Shoe Care',
        value: 'Shoe Care',
        type: 'Other'
      },
      {
        label: 'Sundries',
        value: 'Sundries',
        type: 'Other'
      },
      {
        label: 'Gents Bags',
        value: 'Gents Bags',
        type: 'Other'
      },
      {
        label: 'Ladies Bags',
        value: 'Ladies Bags',
        type: 'Other'
      },
      {
        label: 'Accessories Wallets',
        value: 'Accessories Wallets',
        type: 'Other'
      },
      {
        label: 'Accessories Purses',
        value: 'Accessories Purses',
        type: 'Other'
      },
      {
        label: 'Accessories Other Accessories',
        value: 'Accessories Other Accessories',
        type: 'Other'
      },
      {
        label: 'Accessories Ladies Socks',
        value: 'Accessories Ladies Socks',
        type: 'Other'
      },
      {
        label: 'Accessories Ladies Scarves',
        value: 'Accessories Ladies Scarves',
        type: 'Other'
      },
      {
        label: 'Accessories Ladies Hats',
        value: 'Accessories Ladies Hats',
        type: 'Other'
      },
      {
        label: 'Accessories Ladies Gloves',
        value: 'Accessories Ladies Gloves',
        type: 'Other'
      },
      {
        label: 'Accessories Gift Sets',
        value: 'Accessories Gift Sets',
        type: 'Other'
      },
      {
        label: 'Accessories Gents Socks',
        value: 'Accessories Gents Socks',
        type: 'Other'
      },
      {
        label: 'Accessories Gents Scarves',
        value: 'Accessories Gents Scarves',
        type: 'Other'
      },
      {
        label: 'Accessories Gents Hats',
        value: 'Accessories Gents Hats',
        type: 'Other'
      },
      {
        label: 'Accessories Gents Gloves',
        value: 'Accessories Gents Gloves',
        type: 'Other'
      },
      {
        label: 'Accessories Clothing Accessories',
        value: 'Accessories Clothing Accessories',
        type: 'Other'
      },
      {
        label: 'Accessories',
        value: 'Accessories',
        type: 'Other'
      }
    ]
  },
  reallocatePercentage: 50,
  reallocationBranches: {
    includeOrExclude: 'include-all',
    selectedItems: []
  },
  recallBranches: {
    includeOrExclude: 'include-all',
    selectedItems: []
  },
  recallTimeframe: 15, // days. Not currently implemented.
  removeTriplicates: 'no',
  seasons: {
    includeOrExclude: 'include-all',
    selectedItems: [
      // { label: "Autumn Winter 19", value: "Autumn Winter 19" },
      // { label: "Spring Summer 19", value: "Spring Summer 19" }
    ]
  },
  shopTypes: ['outlet', 'jones', 'full price'],
  sizeLevelFilters: {
    includeOrExclude: 'include-all',
    selectedItems: []
  },
  skuLevelFilters: {
    includeOrExclude: 'include-all',
    selectedItems: []
  },
  sortValue: 'last-weeks-profit',
  suppliers: {
    includeOrExclude: 'include-all',
    selectedItems: []
  },
  unitsToRecall: 1000
}

const reducer = (state = criteriaInitialState, action) => {
  switch (action.type) {
    case constants.RESET_APP:
      return criteriaInitialState
    case constants.SET_BRANCHES: {
      if (action.payload.type === 'recall') {
        return {
          ...state,
          recallBranches: {
            ...state.recallBranches,
            selectedItems: action.payload.items
          }
        }
      } else if (action.payload.type === 'reallocation') {
        return {
          ...state,
          reallocationBranches: {
            ...state.reallocationBranches,
            selectedItems: action.payload.items
          }
        }
      } else {
        return state
      }
    }
    case constants.SET_BRANCHES_INCLUDE: {
      if (action.payload.type === 'recall') {
        return {
          ...state,
          recallBranches: {
            ...state.recallBranches,
            includeOrExclude: action.payload.value
          }
        }
      } else if (action.payload.type === 'reallocation') {
        return {
          ...state,
          reallocationBranches: {
            ...state.reallocationBranches,
            includeOrExclude: action.payload.value
          }
        }
      } else {
        return state
      }
    }
    case constants.SET_BRANCH_RECALL_PERCENTAGE:
      return {
        ...state,
        branchRecallPercentage: action.payload
      }
    case constants.SET_COLOURS:
      return {
        ...state,
        colourLevelFilters: {
          ...state.colourLevelFilters,
          selectedItems: action.payload
        }
      }
    case constants.SET_COLOURS_INCLUDE:
      return {
        ...state,
        colourLevelFilters: {
          ...state.colourLevelFilters,
          includeOrExclude: action.payload
        }
      }
    case constants.SET_CUMULATIVE_WEEKS:
      return {
        ...state,
        cumulativeWeeks: action.payload
      }
    case constants.SET_GENDER:
      return {
        ...state,
        gender: action.payload
      }
    case constants.SET_IS_DIRTY:
      return {
        ...state,
        isDirty: action.payload
      }
    case constants.SET_MAX_WAREHOUSE_STOCK:
      return {
        ...state,
        maxWarehouseStock: action.payload
      }
    case constants.SET_MAX_WAREHOUSE_STOCK_EXCLUDED:
      return {
        ...state,
        maxWarehouseStockExcluded: action.payload
      }
    case constants.SET_MIN_BRANCH_STOCK:
      return {
        ...state,
        minBranchStock: action.payload
      }
    case constants.SET_MIN_BRANCH_STOCK_EXCLUDED:
      return {
        ...state,
        minBranchStockExcluded: action.payload
      }
    case constants.SET_MIN_MARGIN:
      return {
        ...state,
        minMargin: action.payload
      }
    case constants.SET_MIN_MARGIN_EXCLUDED:
      return {
        ...state,
        minMarginExcluded: action.payload
      }
    case constants.SET_MIN_RECALL_PRICE:
      return {
        ...state,
        minRecallPrice: action.payload
      }
    case constants.SET_MIN_RECALL_PRICE_EXCLUDED:
      return {
        ...state,
        minRecallPriceExcluded: action.payload
      }
    case constants.SET_REALLOCATE_PERCENTAGE:
      return {
        ...state,
        reallocatePercentage: action.payload
      }
    case constants.SET_RECALL_TIMEFRAME_DAYS:
      return {
        ...state,
        recallTimeframe: action.payload // days
      }
    case constants.SET_REMOVE_TRIPLICATES:
      return {
        ...state,
        removeTriplicates: action.payload
      }
    case constants.SET_STEP:
      return {
        ...state,
        activeStep: action.payload
      }
    case constants.SET_STEP_COMPLETED:
      let completedSteps = [...state.completedSteps]
      if (action.payload.isCompleted) {
        completedSteps.push(action.payload.step)
      } else {
        const index = completedSteps.findIndex(action.payload.step)
        if (index > -1) {
          completedSteps.splice(index, 1)
        }
      }
      return {
        ...state,
        completedSteps
      }
    case constants.SET_PRODUCT_GROUPS:
      return {
        ...state,
        productGroups: {
          ...state.productGroups,
          selectedItems: action.payload
        }
      }
    case constants.SET_PRODUCT_GROUPS_INCLUDE:
      return {
        ...state,
        productGroups: {
          ...state.productGroups,
          includeOrExclude: action.payload
        }
      }
    case constants.RESET_CRITERIA:
      return {
        ...criteriaInitialState,
        ...action.payload
      }
    case constants.SET_SEASONS:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          selectedItems: action.payload
        }
      }
    case constants.SET_SEASONS_INCLUDE:
      return {
        ...state,
        seasons: {
          ...state.seasons,
          includeOrExclude: action.payload
        }
      }
    case constants.SET_SIZES:
      return {
        ...state,
        sizeLevelFilters: {
          ...state.sizeLevelFilters,
          selectedItems: action.payload
        }
      }
    case constants.SET_SIZES_INCLUDE:
      return {
        ...state,
        sizeLevelFilters: {
          ...state.sizeLevelFilters,
          includeOrExclude: action.payload
        }
      }
    case constants.SET_SHOP_TYPES:
      return {
        ...state,
        shopTypes: action.payload
      }
    case constants.SET_SKUS:
      return {
        ...state,
        skuLevelFilters: {
          ...state.skuLevelFilters,
          selectedItems: action.payload
        }
      }
    case constants.SET_SKUS_INCLUDE:
      return {
        ...state,
        skuLevelFilters: {
          ...state.skuLevelFilters,
          includeOrExclude: action.payload
        }
      }
    case constants.SET_SORT_VALUE:
      return {
        ...state,
        sortValue: action.payload
      }
    case constants.SET_SUPPLIERS:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          selectedItems: action.payload
        }
      }
    case constants.SET_SUPPLIERS_INCLUDE:
      return {
        ...state,
        suppliers: {
          ...state.suppliers,
          includeOrExclude: action.payload
        }
      }
    case constants.SET_UNITS_TO_RECALL:
      return {
        ...state,
        unitsToRecall: action.payload
      }
    default:
      return state
  }
}

export default reducer
