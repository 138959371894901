import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'

import AltcodeRow from './AltcodeRow'
import AltcodeSearch from './AltcodeSearch'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'
import {
  setSKUsIncludeAction,
  setSKUsAction,
} from '../../../../redux/reducers/criteria/actions'
import { countSKUsAction } from '../../../../redux/reducers/data/skus'

const useStyles = makeStyles(theme => ({
  search: {
    borderBottom: 'none',
  },
}))

const AltcodeLevel = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const {
    criteria: {
      skuLevelFilters: { includeOrExclude, selectedItems },
    },
  } = useSelector(state => state)

  const countSKUs = useCallback(() => {
    dispatch(countSKUsAction())
  }, []) // eslint-disable-line
  // disabled dispatch dependency above to stop running on dismount. Is this good practice?

  const setAltcodes = useCallback(
    selectedItems => {
      dispatch(setSKUsAction(selectedItems))
      countSKUs()
    },
    [dispatch, countSKUs]
  )

  const handleDelete = useCallback(
    index => () => {
      const newAltcodes = [...selectedItems]
      newAltcodes.splice(index, 1)
      setAltcodes(newAltcodes)
    },
    [selectedItems, setAltcodes]
  )

  const handleColourChange = useCallback(
    index => e => {
      // console.log('handleColourChange', index, e.target)
      const newAltcodes = [...selectedItems].map((a, i) => {
        if (index !== i) return a
        return {
          ...a,
          selectedColours: e.target.value,
        }
      })
      dispatch(setSKUsAction(newAltcodes))
    },
    [selectedItems, dispatch]
  )

  const handleSizeChange = useCallback(
    index => e => {
      // console.log('handleSizeChange', index, e.target)
      const newAltcodes = [...selectedItems].map((a, i) => {
        if (index !== i) return a
        return {
          ...a,
          selectedSizes: e.target.value,
        }
      })
      dispatch(setSKUsAction(newAltcodes))
    },
    [selectedItems, dispatch]
  )

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Search altcodes to include'
      case 'exclude':
        return 'Search altcodes to exclude'
      default:
        return 'Search altcodes'
    }
  }

  return (
    <Box width="100%">
      <Box display="flex" mb={1}>
        <Box display="flex" alignItems="center">
          <IncludeExcludeSelect
            value={includeOrExclude}
            name="altcodeInclude"
            onChange={e => dispatch(setSKUsIncludeAction(e.target.value))}
          />
          <Typography>SKUs</Typography>
        </Box>
      </Box>
      {includeOrExclude !== 'include-all' && (
        <Table size="small">
          {selectedItems.length > 0 && (
            <TableHead>
              <TableRow>
                <TableCell>Altcode</TableCell>
                <TableCell>RMIS Colours</TableCell>
                <TableCell>Sizes</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {selectedItems.map((a, index) => {
              return (
                <AltcodeRow
                  key={index}
                  altcode={a}
                  countSKUs={countSKUs}
                  handleColourChange={handleColourChange(index)}
                  handleDelete={handleDelete(index)}
                  handleSizeChange={handleSizeChange(index)}
                />
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              {/* Setting padding on next line doesn't seem to work in classes. */}
              <TableCell
                colSpan={4}
                className={classes.search}
                style={{ paddingRight: 0, paddingLeft: 0 }}
              >
                <AltcodeSearch
                  id="altcodes"
                  placeholder={getPlaceholder()}
                  selectedItem={selectedItems}
                  setSelectedItem={setAltcodes}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      )}
    </Box>
  )
}

export default AltcodeLevel
