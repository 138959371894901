import * as constants from '../../constants'

export const appInitialState = {
  activeRecallId: null,
  activeScreen: constants.SCREEN_HOME,
  // activeScreen: constants.SCREEN_CRITERIA,
  explicitlyShowRefunds: false,
  isSidebarOpen: false,
  theme: 'pavers',
  themeType: 'light',
  webSocketId: null,
  webSocketMessage: null,
}

const reducer = (state = appInitialState, action) => {
  switch (action.type) {
    case constants.RESET_APP:
      return {
        ...appInitialState,
        activeScreen:
          action.payload.activeScreen || appInitialState.activeScreen,
        isSidebarOpen: state.isSidebarOpen,
        theme: state.theme,
        themeType: state.themeType,
      }
    case constants.SET_ACTIVE_RECALL_ID:
      return {
        ...state,
        activeRecallId: action.payload
      }
    case constants.SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.payload,
        // force this to null if on home screen, handles browser back button naviagtions
        activeRecallId:
          action.payload === constants.SCREEN_HOME ? null : state.activeRecallId
      }
    case constants.SET_SIDEBAR_OPEN:
      return {
        ...state,
        isSidebarOpen: action.payload
      }
    case constants.SET_THEME:
      return {
        ...state,
        theme: action.payload
      }
    case constants.SET_THEME_TYPE:
      return {
        ...state,
        themeType: action.payload
      }
    case constants.SET_WEBSOCKET_ID:
      return {
        ...state,
        webSocketId: action.payload
      }
    case constants.SET_WEBSOCKET_MESSAGE:
      return {
        ...state,
        webSocketMessage: action.payload
      }
    case constants.SHOW_REFUNDS:
      return {
        ...state,
        explicitlyShowRefunds: action.payload,
      }
    default:
      return state
  }
}

export default reducer
