import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import uniqBy from 'lodash/uniqBy'

import Label from '../../../Label'
import DownshiftMultipleSelect from '../../DownshiftMultipleSelect'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'
import { getIncludedItemsCount } from '../../../../helpers'
import {
  setProductGroupsAction,
  setProductGroupsIncludeAction
} from '../../../../redux/reducers/criteria/actions'
import ProductGroupsListDialog from './ProductGroupsListDialog'

export const filterProductGroupsByGender = (gender, productGroups) => {
  return productGroups.filter(g => {
    switch (gender) {
      case 'Gents':
        return g.type.toLowerCase().indexOf('gents') > -1
      case 'Ladies':
        return g.type.toLowerCase().indexOf('ladies') > -1
      case 'Other':
        return (
          g.type.toLowerCase().indexOf('gents') === -1 &&
          g.type.toLowerCase().indexOf('ladies') === -1
        )
      case 'All':
      default:
        return true
    }
  })
}

export const filterProductGroupsBySeasons = (seasons, productGroups) => {
  return productGroups.filter(g => seasons.includes(g.season_year))
}

// filter options by seasons and gender
export const makeProductGroupOptions = (
  allItems,
  selectedSeasons = [],
  selectedGender = 'all'
) => {
  // console.log(allItems)
  const filteredBySeasons = filterProductGroupsBySeasons(
    selectedSeasons,
    allItems
  )

  return uniqBy(
    filterProductGroupsByGender(selectedGender, filteredBySeasons).map(g => ({
      label: g.description,
      value: g.description,
      type: g.type // We also need the type to filter in setGenderAction()
    })),
    'value'
  )
}

const ProductGroups = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const {
    criteria: {
      gender: selectedGender,
      productGroups: { includeOrExclude, selectedItems },
      seasons: {
        includeOrExclude: seasonsInclude,
        selectedItems: selectedSeasonItems
      }
    },
    data: {
      productGroups: {
        isLoaded: productGroupsIsLoaded,
        items: productGroupItems
      },
      seasons: { items: allSeasonItems }
    }
  } = useSelector(state => state)

  const setSelectedItem = useCallback(
    item => dispatch(setProductGroupsAction(item)),
    [dispatch]
  )

  const handleDialogClose = useCallback(() => setDialogOpen(false), [])
  const handleDialogOpen = useCallback(() => setDialogOpen(true), [])

  let selectedSeasons = useMemo(() => {
    switch (seasonsInclude) {
      case 'include-all':
        return allSeasonItems.map(s => s.season_year)
      case 'include':
        return selectedSeasonItems.map(s => s.value)
      case 'exclude':
        return allSeasonItems
          .map(s => s.season_year)
          .filter(s => !selectedSeasonItems.map(s => s.value).includes(s))
      default:
        return []
    }
  }, [allSeasonItems, seasonsInclude, selectedSeasonItems])

  // filter options by season & gender
  const filteredProductGroups = useMemo(() => {
    return makeProductGroupOptions(
      productGroupItems,
      selectedSeasons,
      selectedGender
    )
  }, [productGroupItems, selectedGender, selectedSeasons])
  // console.log(filteredProductGroups)

  const count = getIncludedItemsCount(
    includeOrExclude,
    filteredProductGroups.length,
    selectedItems.length
  )

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Select product groups to include'
      case 'exclude':
        return 'Select product groups to exclude'
      default:
        return 'Select product groups'
    }
  }

  if (!productGroupsIsLoaded) return null

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" mb={1} flexWrap="wrap">
        <IncludeExcludeSelect
          value={includeOrExclude}
          name="productGroupInclude"
          onChange={e =>
            dispatch(setProductGroupsIncludeAction(e.target.value))
          }
        />
        <Typography component="span">product groups</Typography>
        <Box display="flex" alignItems="center">
          <Label
            isError={count === 0}
            isSuccess={count > 0}
            text={`${count.toLocaleString()} / ${filteredProductGroups.length}`}
          />
          <MuiLink as="button" onClick={handleDialogOpen}>
            View entire list
          </MuiLink>
        </Box>
      </Box>
      {includeOrExclude !== 'include-all' && (
        <DownshiftMultipleSelect
          id="product-groups"
          maxHeight={300}
          placeholder={getPlaceholder()}
          selectedItem={selectedItems}
          setSelectedItem={setSelectedItem}
          suggestions={filteredProductGroups}
        />
      )}
      <ProductGroupsListDialog
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
      />
    </Box>
  )
}

export default ProductGroups
