import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const CheckboxMultipleSelect = ({
  handleChange,
  handleClose,
  name,
  options,
  selectedItems,
}) => {
  const isAllItemsChecked = options.length === selectedItems.length
  const [isSelectAllChecked, setSelectAllChecked] = useState(isAllItemsChecked)

  return (
    <Select
      displayEmpty
      input={<Input id={name} name={name} />}
      onChange={(e, child) => {
        // handle the (de)select all items checkbox first
        if (child.props.value === 'select-all') {
          if (isSelectAllChecked) {
            // deselect all options
            handleChange({ target: { value: [] } })
          } else {
            // select all options
            handleChange({
              target: {
                value: options.map(o => o.value),
              },
            })
          }

          setSelectAllChecked(!isSelectAllChecked)
          return
        }

        // handle a single item click
        // remove the 'select-all' option from the values array
        handleChange({
          ...e,
          target: {
            ...e.target,
            value: e.target.value.filter(x => x !== 'select-all'),
          },
        })
      }}
      onClose={handleClose}
      MenuProps={MenuProps}
      multiple
      renderValue={selected => `${selected.length}/${options.length} selected`}
      value={selectedItems}
    >
      <MenuItem value="select-all">
        <Checkbox checked={isSelectAllChecked} />
        <ListItemText
          primary={isSelectAllChecked ? 'Deselect all' : 'Select all'}
        />
      </MenuItem>
      <Divider />
      {options.map(o => (
        <MenuItem key={o.value} value={o.value}>
          <Checkbox checked={selectedItems.indexOf(o.value) > -1} />
          <ListItemText primary={o.label} />
        </MenuItem>
      ))}
    </Select>
  )
}

CheckboxMultipleSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default CheckboxMultipleSelect
