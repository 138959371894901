// based on https://github.com/tannerlinsley/react-table/tree/master/examples/pagination-controlled

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { useTable, usePagination, useExpanded } from 'react-table'
import isEqual from 'lodash/isEqual'

import ProductImage from '../../ProductImage'
import HeaderRows from '../HeaderRows'
import Pagination from '../../Pagination'
import TableLoader from '../../TableLoader'

const ItemsBySKUTable = React.memo(
  props => {
    const {
      data,
      error,
      handleSort,
      isLoading,
      filters,
      pageIndex,
      pageSize,
      setPageIndex,
      setPageSize,
      setSKUFilter,
      skuFilter,
      sortBy,
      sortDirection,
      totalItems
    } = props
    // console.log(props)

    const columns = React.useMemo(
      () => [
        {
          Header: 'SKU',
          Cell: cell => {
            const {
              ALT_CODE1,
              COL_DESC_SHORT,
              CURRENT_MARGIN,
              current_sp,
              GENERICCOLOUR,
              productGroup,
              SIZES_DESC,
            } = cell.row.original
            return (
              <Box display="flex" alignItems="center">
                <Box mr={4}>
                  <ProductImage
                    altCode={ALT_CODE1}
                    colourDescShort={COL_DESC_SHORT}
                    margin={CURRENT_MARGIN}
                    productGroup={productGroup}
                    sellPrice={current_sp}
                    genericColour={GENERICCOLOUR}
                  />
                </Box>
                <Typography>
                  {ALT_CODE1} {COL_DESC_SHORT} {SIZES_DESC}
                </Typography>
              </Box>
            )
          },
          alignHeader: 'center'
        },
        {
          id: 'push',
          Header: () => {
            return (
              <Typography>
                Push ({filters.reallocatePercentage}% reallocated)
              </Typography>
            )
          },
          Cell: cell => {
            return (
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>To branch</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      {cell.row.original.pushToBranch}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>To warehouse</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      {cell.row.original.pushToWarehouse}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>To Home Shopping</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      {cell.row.original.pushToHomeShopping}
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell>Total</TableCell>
                    <TableCell align="right" style={{ paddingRight: 0 }}>
                      {cell.row.original.push}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            )
          },
          align: 'right',
          alignHeader: 'right'
          // colSpan: 2,
        }
      ],
      [filters]
    )

    const table = useTable(
      {
        columns,
        data,
        manualPagination: true,
        expandSubRows: false
      },
      useExpanded,
      usePagination
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows
    } = table

    // console.log(table)

    const handleChangePage = useCallback(
      (_, newPage) => {
        setPageIndex(newPage)
      },
      [setPageIndex]
    )

    const handleChangeRowsPerPage = useCallback(
      e => {
        setPageSize(parseInt(e.target.value, 10))
        setPageIndex(0)
      },
      [setPageIndex, setPageSize]
    )

    const handleSKUFilterChange = useCallback(
      e => setSKUFilter(e.target.value),
      [setSKUFilter]
    )

    return (
      <Box mt={2} position="relative">
        {error && (
          <Typography gutterBottom color="error">
            {error}
          </Typography>
        )}
        {isLoading && <TableLoader />}
        <Table
          size="small"
          {...getTableProps()}
          style={{ opacity: isLoading ? 0.33 : 1 }}
        >
          <TableHead>
            <Pagination
              totalItems={totalItems}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
            <HeaderRows
              headerGroups={headerGroups}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleSort={handleSort}
            />
            <TableRow>
              <TableCell>
                <TextField
                  value={skuFilter}
                  fullWidth
                  onChange={handleSKUFilterChange}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            align: cell.column.align || 'left',
                            colSpan: cell.column.colSpan || 1,
                            style: {
                              borderBottom: row.isExpanded ? 'none' : null
                            }
                          })}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                </React.Fragment>
              )
            })}
          </TableBody>
          <TableFooter>
            <Pagination
              totalItems={totalItems}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </TableFooter>
        </Table>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    if (
      isEqual(prevProps.filters, nextProps.filters) &&
      isEqual(nextProps.data, prevProps.data)
    )
      return true
    return false
  }
)

ItemsBySKUTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  filters: PropTypes.object.isRequired,
  handleSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setSKUFilter: PropTypes.func.isRequired,
  skuFilter: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired
}

ItemsBySKUTable.displayName = 'ItemsBySKUTable'
// ItemsBySKUTable.whyDidYouRender = true

export default ItemsBySKUTable
