import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

import InfoButton from '../../InfoButton'
import Pagination from '../../Pagination'

const useStyles = makeStyles(theme => ({
  highlightReallocation: {
    background:
      theme.palette.type === 'dark'
        ? theme.palette.grey[500]
        : theme.palette.grey[50],
  },
  titleColumn: {
    minWidth: 180,
  },
}))

const TableHeader = React.memo(props => {
  const classes = useStyles()
  const {
    setIsTestFilter,
    setTitleFilter,
    handleChangePage,
    handleChangeRowsPerPage,
    pageIndex,
    pageSize,
    totalItems,
    sortBy,
    sortDirection,
    handleSort,
    isTestFilter,
    titleFilter,
  } = props

  const handleIsTestFilterChange = useCallback(
    e => {
      setIsTestFilter(e.target.value)
    },
    [setIsTestFilter]
  )

  const handleTitleFilterChange = useCallback(
    e => {
      setTitleFilter(e.target.value)
    },
    [setTitleFilter]
  )

  const handleSortClick = useCallback(field => () => handleSort(field), [
    handleSort,
  ])

  return (
    <TableHead>
      <Pagination
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalItems={totalItems}
      />
      <TableRow>
        <TableCell colSpan={7} />
        <TableCell
          align="center"
          colSpan={3}
          className={classes.highlightReallocation}
        >
          Reallocations
        </TableCell>
        <TableCell />
      </TableRow>
      <TableRow>
        <TableCell>
          Test?
          <InfoButton
            tooltipContent={
              <Typography variant="body2">
                Test v3 recalls do not write to RMIS.dbo.DailyRecall_Items and
                will therefore not be picked up by the in-store Sabot tablet
                application.
              </Typography>
            }
          />
        </TableCell>
        <TableCell className={classes.titleColumn}>
          <TableSortLabel
            active={sortBy === 'title'}
            direction={sortDirection}
            onClick={handleSortClick('title')}
          >
            Title
          </TableSortLabel>
        </TableCell>
        <TableCell />
        <TableCell>
          <TableSortLabel
            active={sortBy === 'issuedDate'}
            direction={sortDirection}
            onClick={handleSortClick('issuedDate')}
          >
            Issued
          </TableSortLabel>
        </TableCell>
        <TableCell>
            Sorting
        </TableCell>
        <TableCell>Status</TableCell>
        <TableCell align="right">
          <TableSortLabel
            active={sortBy === 'unitsRecalled'}
            direction={sortDirection}
            onClick={handleSortClick('unitsRecalled')}
          >
            Units recalled
          </TableSortLabel>
        </TableCell>
        <TableCell align="right" className={classes.highlightReallocation}>
          <TableSortLabel
            active={sortBy === 'unitsReallocated'}
            direction={sortDirection}
            onClick={handleSortClick('unitsReallocated')}
          >
            Br
          </TableSortLabel>
        </TableCell>
        <TableCell align="right" className={classes.highlightReallocation}>
          <TableSortLabel
            active={sortBy === 'unitsHomeShopping'}
            direction={sortDirection}
            onClick={handleSortClick('unitsHomeShopping')}
          >
            HS
          </TableSortLabel>
        </TableCell>
        <TableCell align="right" className={classes.highlightReallocation}>
          <TableSortLabel
            active={sortBy === 'unitsWarehouse'}
            direction={sortDirection}
            onClick={handleSortClick('unitsWarehouse')}
          >
            WH
          </TableSortLabel>
        </TableCell>
        <TableCell align="center">Criteria</TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Select onChange={handleIsTestFilterChange} value={isTestFilter}>
            <MenuItem value={-1}>Show all</MenuItem>
            <MenuItem value={1}>Test</MenuItem>
            <MenuItem value={0}>Not test</MenuItem>
          </Select>
        </TableCell>
        <TableCell>
          <TextField
            fullWidth
            onChange={handleTitleFilterChange}
            value={titleFilter}
          />
        </TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
    </TableHead>
  )
})

TableHeader.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  isTestFilter: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setIsTestFilter: PropTypes.func.isRequired,
  setTitleFilter: PropTypes.func.isRequired,
  sortBy: PropTypes.oneOf([
    'title',
    'issuedDate',
    'unitsRecalled',
    'unitsReallocated',
    'unitsHomeShopping',
    'unitsWarehouse',
  ]).isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  titleFilter: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
}

TableHeader.displayName = 'RecallTableHeader'
// TableHeader.whyDidYouRender = true

export default TableHeader
