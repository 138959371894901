import some from 'lodash/some'
import axios from 'axios'

// import { makeProductGroupOptions } from './components/CriteriaScreen/Filters/ProductGroups'
import { makeSupplierOptions } from './components/CriteriaScreen/Filters/Suppliers'
import { makeSeasonOptions } from './components/CriteriaScreen/Filters/Seasons'
import { makeBranchOptions } from './components/CriteriaScreen/RecallRealloc/RecallBranches'
import { makeColourOptions } from './components/CriteriaScreen/SKUs/ColourLevel'

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

// Return value in px
// Note value includes top border width if not MUI theme
export const getAppBarHeight = theme => {
  switch (theme.toLowerCase()) {
    case 'pavers':
    case 'paversdark':
      return 55
    case 'jones':
    case 'jonesdark':
      return 55
    case 'mui':
    case 'muidark':
      return 49
    default:
      return 49
  }
}

// Returns a nicer error message
export const parseError = e => {
  // Axios errors are in the e.response object
  if (e.response && e.response.data) {
    const str = JSON.stringify(e.response.data)
    return str.length < 500 ? str : `${str.substring(0, 500)}...`
  }

  // Errors from the API usually have a message key
  if (e.message) return e.message

  // All other errors
  return e.toString()
}

/*
For criteria of the form:
{
  includeOrExclude: '', // 'include-all', 'include', 'exclude'
  selectedItems: [{}]
}

return a count of how many items are included by the filter.
*/
export const getIncludedItemsCount = (
  includeOrExclude,
  allItemsCount,
  selectedItemsCount
) => {
  // const { selectedItems, includeOrExclude } = productGroups
  switch (includeOrExclude) {
    case 'include-all':
      return allItemsCount
    case 'include':
      return selectedItemsCount
    case 'exclude':
      const n = allItemsCount - selectedItemsCount
      return n < 0 ? 0 : n
    default:
      return 0
  }
}

// Validate JSON representing criteria definition, called from the Load Existing Criteria button.
// Returns true on valid JSON, or an array of error strings.
// Altcodes are validated via requests to the API.
// This might be a bit over the top...
export const validateCriteria = async (criteriaJSON, appData) => {
  const includeOrExcludeOptions = ['include-all', 'include', 'exclude']
  const genderOptions = ['all', 'gents', 'ladies', 'other']
  const sortOptions = [
    'last-weeks-profit',
    'last-weeks-cover',
    'last-weeks-sales',
    'cumulative-profit',
    'cumulative-cover',
    'cumulative-sales'
  ]
  const removeTriplicateOptions = ['yes', 'no']

  const errors = []
  const {
    cumulativeWeeks,
    shopTypes,
    gender,
    productGroups,
    recallTimeframe,
    suppliers,
    seasons,
    skuLevelFilters,
    sortValue,
    recallBranches,
    reallocationBranches,
    maxWarehouseStock,
    minBranchStock,
    unitsToRecall,
    minRecallPrice,
    minMargin,
    removeTriplicates,
    reallocatePercentage,
    branchRecallPercentage,
    colourLevelFilters,
    sizeLevelFilters
  } = criteriaJSON

  // cumulativeWeeks must exist and be numeric
  if (!cumulativeWeeks) {
    errors.push('cumulative weeks not specified.')
  } else if (!isNumeric(cumulativeWeeks) || cumulativeWeeks < 1) {
    errors.push(`invalid cumulative weeks value ${cumulativeWeeks}.`)
  }

  // shopType must exist and have be have one of the allowed values
  if (!shopTypes || !Array.isArray(shopTypes) || shopTypes.length === 0) {
    errors.push('shop types not specified.')
  } else {
    const appShopTypes = appData.shopTypes.items.map(s =>
      s.shopType.toLowerCase()
    )
    shopTypes.forEach(st => {
      if (!appShopTypes.includes(st.toLowerCase())) {
        errors.push(`invalid shop type '${st}'.`)
      }
    })
  }

  // gender must exist and have one of the allowed values
  if (!gender) {
    errors.push('gender not specified.')
  } else if (
    !genderOptions.map(g => g.toLowerCase()).includes(gender.toLowerCase())
  ) {
    errors.push(`invalid gender '${gender}'.`)
  }

  // product groups
  if (!productGroups) {
    errors.push('product groups not specified.')
  } else {
    if (!includeOrExcludeOptions.includes(productGroups.includeOrExclude)) {
      errors.push('product groups include or exclude missing or invalid value.')
    }
    if (!productGroups.selectedItems) {
      errors.push('product groups selected items not specified.')
    } else if (!Array.isArray(productGroups.selectedItems)) {
      errors.push('product groups selected items is not an array.')
    } else {
      // productGroups.selectedItems.forEach(g => {
      //   if (!some(makeProductGroupOptions(appData.productGroups.items), g)) {
      //     errors.push(`invalid product group ${JSON.stringify(g)}.`)
      //   }
      // })
    }
  }

  // suppliers
  if (!suppliers) {
    errors.push('suppliers not specified.')
  } else {
    if (!includeOrExcludeOptions.includes(suppliers.includeOrExclude)) {
      errors.push('suppliers include or exclude missing or invalid value.')
    }
    if (!suppliers.selectedItems) {
      errors.push('suppliers selected items not specified.')
    } else if (!Array.isArray(suppliers.selectedItems)) {
      errors.push('suppliers selected items is not an array.')
    } else {
      suppliers.selectedItems.forEach(s => {
        if (!some(makeSupplierOptions(appData.suppliers.items), s)) {
          errors.push(`invalid supplier ${JSON.stringify(s)}.`)
        }
      })
    }
  }

  // seasons
  if (!seasons) {
    errors.push('seasons not specified.')
  } else {
    if (!includeOrExcludeOptions.includes(seasons.includeOrExclude)) {
      errors.push('seasons include or exclude missing or invalid value.')
    }
    if (!seasons.selectedItems) {
      errors.push('seasons selected items not specified.')
    } else if (!Array.isArray(seasons.selectedItems)) {
      errors.push('seasons selected items is not an array.')
    } else {
      seasons.selectedItems.forEach(s => {
        if (!some(makeSeasonOptions(appData.seasons.items), s)) {
          errors.push(`invalid season ${JSON.stringify(s)}.`)
        }
      })
    }
  }

  // colour
  if (!colourLevelFilters) {
    errors.push('colours not specified.')
  } else {
    if (
      !includeOrExcludeOptions.includes(colourLevelFilters.includeOrExclude)
    ) {
      errors.push('colours include or exclude missing or invalid value.')
    }
    if (!colourLevelFilters.selectedItems) {
      errors.push('colour selected items not specified.')
    } else if (!Array.isArray(colourLevelFilters.selectedItems)) {
      errors.push('colour selected items is not an array.')
    } else {
      colourLevelFilters.selectedItems.forEach(s => {
        if (!some(makeColourOptions(appData.colours.items), s)) {
          errors.push(`invalid colour ${JSON.stringify(s)}.`)
        }
      })
    }
  }

  // sizes
  if (!sizeLevelFilters) {
    errors.push('size groups not specified.')
  } else {
    if (!includeOrExcludeOptions.includes(sizeLevelFilters.includeOrExclude)) {
      errors.push('size groups include or exclude missing or invalid value.')
    }
  }

  // altcodes
  if (!skuLevelFilters) {
    errors.push('skuLevelFilters not specified.')
  } else {
    if (!includeOrExcludeOptions.includes(skuLevelFilters.includeOrExclude)) {
      errors.push(
        'skuLevelFilters include or exclude missing or invalid value.'
      )
    } else if (!skuLevelFilters.selectedItems) {
      errors.push('skuLevelFilters selected items not specified.')
    } else if (!Array.isArray(skuLevelFilters.selectedItems)) {
      errors.push('skuLevelFilters selected items is not an array.')
    } else {
      const isValidRequests = skuLevelFilters.selectedItems.map(a =>
        axios.post('/postValidateAltcodeJSON', {
          id: a.id,
          selectedColours: a.selectedColours,
          selectedSizes: a.selectedSizes
        })
      )
      const isValidResponses = await Promise.all(isValidRequests)
      isValidResponses.forEach((r, index) => {
        if (!r.data.isValid === true) {
          errors.push(
            `invalid altcode ${JSON.stringify(
              skuLevelFilters.selectedItems[index]
            )}`
          )
        }
      })
    }
  }

  // sort value must exist and have one of the allowed values
  if (!sortValue) {
    errors.push('sort value not specified.')
  } else if (!sortOptions.includes(sortValue)) {
    errors.push(`invalid sort value '${sortValue}'.`)
  }

  // branches
  if (!recallBranches) {
    errors.push('recallBranches not specified.')
  } else {
    if (!includeOrExcludeOptions.includes(recallBranches.includeOrExclude)) {
      errors.push('recallBranches include or exclude missing or invalid value.')
    }
    if (!recallBranches.selectedItems) {
      errors.push('recallBranches selected items not specified.')
    } else if (!Array.isArray(recallBranches.selectedItems)) {
      errors.push('recallBranches selected items is not an array.')
    } else {
      recallBranches.selectedItems.forEach(b => {
        if (!some(makeBranchOptions(appData.branches.items), b)) {
          errors.push(`invalid branch ${JSON.stringify(b)}.`)
        }
      })
    }
  }

  if (!reallocationBranches) {
    errors.push('reallocationBranches not specified.')
  } else {
    if (
      !includeOrExcludeOptions.includes(reallocationBranches.includeOrExclude)
    ) {
      errors.push(
        'reallocationBranches include or exclude missing or invalid value.'
      )
    }
    if (!reallocationBranches.selectedItems) {
      errors.push('reallocationBranches selected items not specified.')
    } else if (!Array.isArray(reallocationBranches.selectedItems)) {
      errors.push('reallocationBranches selected items is not an array.')
    } else {
      reallocationBranches.selectedItems.forEach(b => {
        if (!some(makeBranchOptions(appData.branches.items), b)) {
          errors.push(`invalid branch ${JSON.stringify(b)}.`)
        }
      })
    }
  }

  // maxWarehouseStock must exist and be numeric
  if (maxWarehouseStock !== 0 && !maxWarehouseStock) {
    errors.push('maximum warehouse stock not specified.')
  } else if (!isNumeric(maxWarehouseStock)) {
    errors.push(`invalid maximum warehouse stock value '${maxWarehouseStock}'.`)
  }

  // minBranchStock must exist and be numeric
  if (minBranchStock !== 0 && !minBranchStock) {
    errors.push('minimum branch stock not specified.')
  } else if (!isNumeric(minBranchStock)) {
    errors.push(`invalid minimum branch stock value '${minBranchStock}'.`)
  }

  // unitsToRecall must exist and be numeric
  if (unitsToRecall !== 0 && !unitsToRecall) {
    errors.push('units to recall not specified.')
  } else if (!isNumeric(unitsToRecall)) {
    errors.push(`invalid units to recall value '${unitsToRecall}'.`)
  }

  // minRecallPrice must exist and be numeric
  if (minRecallPrice !== 0 && !minRecallPrice) {
    errors.push('minimum recall price not specified.')
  } else if (!isNumeric(minRecallPrice)) {
    errors.push(`invalid minimum recall price value '${minRecallPrice}'.`)
  }

  // minMargin must exist and be numeric
  if (minMargin !== 0 && !minMargin) {
    errors.push('minimum margin not specified.')
  } else if (!isNumeric(minMargin)) {
    errors.push(`invalid minimum margin value '${minMargin}'.`)
  }

  // removeTriplicates must exists and have an allowed value
  if (!removeTriplicates) {
    errors.push('remove triplicates not specified.')
  } else if (!removeTriplicateOptions.includes(removeTriplicates)) {
    errors.push(`invalid remvoe triplicates value '${removeTriplicates}'.`)
  }

  // reallocatePercentage must exist and be numeric
  if (reallocatePercentage !== 0 && !reallocatePercentage) {
    errors.push('reallocate percentage not specified.')
  } else if (!isNumeric(reallocatePercentage)) {
    errors.push(
      `invalid reallocate percentage value '${reallocatePercentage}'.`
    )
  }

  // branchRecallPercentage must exist and be numeric
  if (branchRecallPercentage !== 0 && !branchRecallPercentage) {
    errors.push('branch recall percentage not specified.')
  } else if (!isNumeric(branchRecallPercentage)) {
    errors.push(
      `invalid branch recall percentage value '${branchRecallPercentage}'.`
    )
  }

  // recallTimeframe must exist and be numeric
  if (recallTimeframe !== 0 && !recallTimeframe) {
    errors.push('recall timeframe not specified.')
  } else if (!isNumeric(recallTimeframe)) {
    errors.push(`invalid recall timeframe value '${recallTimeframe}'.`)
  }

  return errors.length > 0 ? errors : true
}
