import axios from 'axios'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  error: false,
  isLoaded: false,
  isRequestInProgress: false,
  items: [],
  totalItems: 0,
  totalFilteredItems: 0,
}

const FETCH_RECALLS = 'FETCH_RECALLS'
const POPULATE_RECALLS = 'POPULATE_RECALLS'
const SET_RECALLS_ERROR = 'SET_RECALLS_ERROR'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RECALLS:
      return {
        ...state,
        isLoaded: false,
        isRequestInProgress: true,
      }
    case POPULATE_RECALLS:
      return {
        ...state,
        isRequestInProgress: false,
        items: action.payload.items,
        totalItems: action.payload.totalItems,
        totalFilteredItems: action.payload.totalFilteredItems,
        isLoaded: true,
      }
    case SET_RECALLS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true,
      }
    default:
      return state
  }
}

export const fetchRecallsAction = (
  page = 1,
  perPage = 5,
  titleFilter = '',
  isTest,
  sortBy,
  sortDirection
) => async (dispatch, getState) => {
  try {
    if (getState().data.recalls.isRequestInProgress) return // prevent too many requests
    dispatch({ type: SET_RECALLS_ERROR, payload: false })
    dispatch({ type: FETCH_RECALLS })
    const response = await axios.get(
      `/getRecalls?page=${page}&perPage=${perPage}&titleFilter=${titleFilter}&sortBy=${sortBy}&sortDirection=${sortDirection}&isTest=${isTest}`
    )
    // console.log(response.data)
    dispatch({ type: POPULATE_RECALLS, payload: response.data })
  } catch (e) {
    dispatch({
      type: SET_RECALLS_ERROR,
      payload: parseError(`fetchRecallsAction - ${parseError(e)}`),
    })
  }
}

export default reducer
