import axios from 'axios'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  error: false,
  isLoaded: false,
  items: [],
}

const FETCH_SEASONS = 'FETCH_SEASONS'
const POPULATE_SEASONS = 'POPULATE_SEASONS'
const SET_SEASONS_ERROR = 'SET_SEASONS_ERROR'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SEASONS:
      return {
        ...state,
        items: [],
        isLoaded: false,
      }
    case POPULATE_SEASONS:
      return {
        ...state,
        items: action.payload,
        isLoaded: true,
      }
    case SET_SEASONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true,
      }
    default:
      return state
  }
}

export const fetchSeasonsAction = () => async dispatch => {
  try {
    dispatch({ type: SET_SEASONS_ERROR, payload: false })
    dispatch({ type: FETCH_SEASONS })
    const response = await axios.get('/getSeasons')
    dispatch({
      type: POPULATE_SEASONS,
      payload: response.data,
    })
  } catch (e) {
    dispatch({
      type: SET_SEASONS_ERROR,
      payload: parseError(`fetchSeasonsAction - ${parseError(e)}`),
    })
  }
}

export default reducer
