import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import { getIncludedItemsCount } from '../../../helpers'

const RecallBranches = () => {
  const {
    criteria: {
      recallBranches: { includeOrExclude, selectedItems }
    },
    data: {
      branches: { items: allBranches }
    }
  } = useSelector(state => state)

  const count = getIncludedItemsCount(
    includeOrExclude,
    allBranches.length,
    selectedItems.length
  )

  return (
    <ListItem dense disableGutters>
      <ListItemText>Recall branches:</ListItemText>
      <ListItemSecondaryAction>
        {`${count.toLocaleString()} / ${allBranches.length} included`}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default RecallBranches
