import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import AppHeader from './AppHeader'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  error: {
    color: theme.palette.error.main,
    fontStyle: 'italic',
  },
}))

const ErrorScreen = ({ errors, action, actionButtonText, hideAppHeader }) => {
  const classes = useStyles()

  return (
    <Box p={4} display="flex" alignItems="center" flexDirection="column">
      {!hideAppHeader && <AppHeader />}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Typography variant="h5" component="h6" color="error">
          Something went wrong:
        </Typography>
        <List>
          {errors.map((e, index) => (
            <ListItem key={index} className={classes.error}>
              {e}
            </ListItem>
          ))}
        </List>
        {actionButtonText && (
          <Button
            className={classes.button}
            onClick={action}
            variant="outlined"
          >
            {actionButtonText}
          </Button>
        )}
      </Box>
    </Box>
  )
}

ErrorScreen.propTypes = {
  action: PropTypes.func.isRequired,
  actionButtonText: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  hideAppHeader: PropTypes.bool,
}
ErrorScreen.defaultProps = {
  hideAppHeader: false,
}

export default ErrorScreen
