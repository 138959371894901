import axios from 'axios'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  error: false,
  isLoaded: false,
  items: [],
}

const FETCH_GENDERS = 'FETCH_GENDERS'
const POPULATE_GENDERS = 'POPULATE_GENDERS'
const SET_GENDERS_ERROR = 'SET_GENDERS_ERROR'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_GENDERS:
      return {
        ...state,
        items: [],
        isLoaded: false,
      }
    case POPULATE_GENDERS:
      return {
        ...state,
        items: action.payload,
        isLoaded: true,
      }
    case SET_GENDERS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true,
      }
    default:
      return state
  }
}

export const fetchGendersAction = () => async dispatch => {
  try {
    dispatch({ type: SET_GENDERS_ERROR, payload: false })
    dispatch({ type: FETCH_GENDERS })
    const response = await axios.get('/getGenders')
    dispatch({
      type: POPULATE_GENDERS,
      payload: response.data,
    })
  } catch (e) {
    dispatch({
      type: SET_GENDERS_ERROR,
      payload: parseError(`fetchGendersAction - ${parseError(e)}`),
    })
  }
}

export default reducer
