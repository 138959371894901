// based on https://github.com/tannerlinsley/react-table/tree/master/examples/pagination-controlled

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { useTable, usePagination, useExpanded } from 'react-table'
import isEqual from 'lodash/isEqual'

import HeaderRows from '../HeaderRows'
import Pagination from '../../Pagination'
import ProductImage from '../../ProductImage'
import TableLoader from '../../TableLoader'
import expanderColumn from '../expanderColumn'

const renderSubRows = subRows =>
  subRows.map((r, i) => (
    <TableRow key={r.original.BRANCH_ID}>
      <TableCell
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      ></TableCell>
      <TableCell
        align="right"
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      ></TableCell>
      <TableCell
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      >
        {r.original.BRANCH_CODE} {r.original.fromBranch}
      </TableCell>
      <TableCell
        align="right"
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      >
        {r.original.pull}
      </TableCell>
    </TableRow>
  ))

const ItemsBySKUTable = React.memo(
  props => {
    const {
      data,
      error,
      isLoading,
      filters,
      handleSort,
      pageIndex,
      pageSize,
      setPageIndex,
      setPageSize,
      setSKUFilter,
      skuFilter,
      sortBy,
      sortDirection,
      totalItems
    } = props
    // console.log(props)

    const columns = React.useMemo(
      () => [
        expanderColumn,
        {
          id: 'sku',
          Header: 'SKU',
          Cell: cell => {
            // console.log(cell.row.original)
            const {
              ALT_CODE1,
              COL_DESC_SHORT,
              CURRENT_MARGIN,
              current_sp,
              GENERICCOLOUR,
              productGroup,
              SIZES_DESC,
            } = cell.row.original
            return (
              <Box display="flex" alignItems="center">
                <Box mr={4}>
                  <ProductImage
                    altCode={ALT_CODE1}
                    colourDescShort={COL_DESC_SHORT}
                    margin={CURRENT_MARGIN}
                    productGroup={productGroup}
                    sellPrice={current_sp}
                    genericColour={GENERICCOLOUR}
                  />
                </Box>
                <Typography>
                  {ALT_CODE1} {COL_DESC_SHORT} {SIZES_DESC}
                </Typography>
              </Box>
            )
          },
          alignHeader: 'center'
        },
        {
          id: 'pull',
          Header: () => {
            return (
              <Typography>
                Pull
                {filters.removeTriplicates === 'yes' &&
                  ' (triplicates removed)'}
              </Typography>
            )
          },
          accessor: 'pull',
          align: 'right',
          alignHeader: 'right',
          colSpan: 2
        }
      ],
      [filters.removeTriplicates]
    )

    const table = useTable(
      {
        columns,
        data,
        manualPagination: true,
        expandSubRows: false
      },
      useExpanded,
      usePagination
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      dispatch
    } = table

    const handleChangePage = useCallback(
      (_, newPage) => {
        dispatch({ type: 'resetExpanded' }) // https://github.com/tannerlinsley/react-table/blob/master/src/plugin-hooks/useExpanded.js#L26
        setPageIndex(newPage)
      },
      [dispatch, setPageIndex]
    )

    const handleChangeRowsPerPage = useCallback(
      e => {
        setPageSize(parseInt(e.target.value, 10))
        setPageIndex(0)
      },
      [setPageSize, setPageIndex]
    )

    const handleSKUFilterChange = useCallback(
      e => setSKUFilter(e.target.value),
      [setSKUFilter]
    )

    return (
      <Box mt={2} position="relative">
        {error && (
          <Typography gutterBottom color="error">
            {error}
          </Typography>
        )}
        {isLoading && <TableLoader />}
        <Table
          size="small"
          {...getTableProps()}
          style={{ opacity: isLoading ? 0.33 : 1 }}
        >
          <TableHead>
            <Pagination
              totalItems={totalItems}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
            <HeaderRows
              headerGroups={headerGroups}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleSort={handleSort}
            />
            <TableRow>
              <TableCell />
              <TableCell>
                <TextField
                  value={skuFilter}
                  fullWidth
                  onChange={handleSKUFilterChange}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      // console.log(cell.getCellProps())
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            align: cell.column.align || 'left',
                            colSpan: cell.column.colSpan || 1,
                            style: {
                              borderBottom: row.isExpanded ? 'none' : null
                            }
                          })}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  {row.isExpanded && renderSubRows(row.subRows)}
                </React.Fragment>
              )
            })}
          </TableBody>
          <TableFooter>
            <Pagination
              totalItems={totalItems}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </TableFooter>
        </Table>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    if (
      isEqual(prevProps.filters, nextProps.filters) &&
      isEqual(nextProps.data, prevProps.data)
    )
      return true
    return false
  }
)

ItemsBySKUTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  filters: PropTypes.object.isRequired,
  handleSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  setSKUFilter: PropTypes.func.isRequired,
  skuFilter: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired
}

ItemsBySKUTable.displayName = 'ItemsBySKUTable'
// ItemsBySKUTable.whyDidYouRender = true

export default ItemsBySKUTable
