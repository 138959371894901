import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const Parameters = () => {
  const {
    criteria: {
      branchRecallPercentage,
      reallocatePercentage,
      recallTimeframe,
      removeTriplicates,
      unitsToRecall
    }
  } = useSelector(state => state)

  const getRemoveTriplicatesText = () => {
    switch (removeTriplicates) {
      case 'yes':
        return 'Yes'
      case 'no':
        return 'No'
      default:
        return removeTriplicates
    }
  }

  return (
    <>
      <ListItem dense disableGutters>
        <ListItemText>Units to recall:</ListItemText>
        <ListItemSecondaryAction>
          {unitsToRecall.toLocaleString()}
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem dense disableGutters>
        <ListItemText>Percentage of branches to recall from:</ListItemText>
        <ListItemSecondaryAction>
          {branchRecallPercentage}%
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem dense disableGutters>
        <ListItemText>
          Remove triplicates and above if branch is part of recall:
        </ListItemText>
        <ListItemSecondaryAction>
          {getRemoveTriplicatesText()}
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem dense disableGutters>
        <ListItemText>Percentage of stock to reallocate:</ListItemText>
        <ListItemSecondaryAction>
          {reallocatePercentage}%
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem dense disableGutters>
        <ListItemText>Recall timeframe:</ListItemText>
        <ListItemSecondaryAction>
          {recallTimeframe} day
          {recallTimeframe === 1 ? '' : 's'}
        </ListItemSecondaryAction>
      </ListItem>
    </>
  )
}

export default Parameters
