import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import axios from 'axios'
import { withSnackbar } from 'notistack'
import groupBy from 'lodash/groupBy'

import SizeGroupsList from './SizeGroupsList'
import SizeGroupItems from './SizeGroupItems'
import SizeGroupHeader from './SizeGroupHeader'
import AddSizeGroupButton from './AddSizeGroupButton'
import DeleteSizeGroup from './DeleteSizeGroup'
import { setSizesAction } from '../../../../../redux/reducers/data/sizes'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(4),
    flex: 1,
    fontFamily: theme.typography.fontFamily
  },
  paper: {
    // height: "100%",
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SizeGroupsListDialog = React.memo(
  ({ handleClose, isOpen, enqueueSnackbar }) => {
    const classes = useStyles()
    const {
      data: {
        sizes: { isLoaded: isDataLoaded, sizeGroups, sizes }
      }
    } = useSelector(state => state)
    // console.log(sizes)

    const [isLoading, setIsLoading] = useState(true)
    const [selectedSizeGroupId, setSelectedSizeGroupId] = useState(
      sizeGroups && sizeGroups.length > 0 ? sizeGroups[0].sizeGroupId : null
    )
    const [rmisSizeIds, setRMISSizeIds] = useState([])
    const dispatch = useDispatch()
    const [isRequestInProgress, setRequestInProgress] = useState(false)

    useEffect(() => {
      let didCancel = false

      const fetchSizes = async () => {
        try {
          if (!selectedSizeGroupId) return null
          setIsLoading(true)
          const response = await axios.get(
            `/getSizesByGroup?sizeGroupId=${selectedSizeGroupId}`
          )
          // console.log(response.data)
          if (didCancel) return
          setRMISSizeIds(response.data.map(s => s.RMIS_SIZES_ID))
        } catch (e) {
          // handle error
        } finally {
          if (didCancel) return
          setIsLoading(false)
        }
      }

      fetchSizes()

      return () => {
        didCancel = true
      }
    }, [selectedSizeGroupId])

    const onClose = useCallback(() => {
      handleClose()
    }, [handleClose])

    const handleSizeGroupClick = useCallback(id => {
      setSelectedSizeGroupId(id)
    }, [])

    const handleAddSizeGroup = useCallback(
      async title => {
        try {
          setIsLoading(true)
          const response = await axios.post('/postAddSizeGroup', {
            title
          })
          // console.log(response.data)
          const { sizeGroupId } = response.data
          enqueueSnackbar('Size group added.', { variant: 'success' })
          const newSizeGroups = [...sizeGroups, { sizeGroupId, title }]
          dispatch(
            setSizesAction({
              sizes,
              sizeGroups: newSizeGroups
            })
          )
          setSelectedSizeGroupId(sizeGroupId)
        } catch (e) {
          enqueueSnackbar('Unable to add size group.', { variant: 'error' })
        }
      },
      [enqueueSnackbar, dispatch, sizeGroups, sizes]
    )

    const handleItemChange = useCallback(
      async (item, isChecked) => {
        // note item is an array of SIZES_IDs, there are duplicate SIZE_DESC_SHORTs in RMIS.dbo.sizes
        // console.log(item, isChecked)

        const endpoint = isChecked
          ? '/postAddSizeGroupItem'
          : '/postDeleteSizeGroupItem'

        try {
          setRequestInProgress(true)
          await axios.post(endpoint, {
            sizeGroupId: selectedSizeGroupId,
            RMIS_SIZES_ID: item
          })
          enqueueSnackbar('Size group item added.', { variant: 'success' })
          const newSizeIds = isChecked
            ? [...rmisSizeIds, ...item]
            : rmisSizeIds.filter(s => !item.includes(s))
          setRMISSizeIds(newSizeIds)
        } catch (e) {
          enqueueSnackbar('Unable to add size group item.', {
            variant: 'error'
          })
        } finally {
          setRequestInProgress(false)
        }
      },
      [selectedSizeGroupId, enqueueSnackbar, rmisSizeIds]
    )

    const handleDeleteSizeGroup = useCallback(async () => {
      try {
        setIsLoading(true)
        await axios.post('/postDeleteSizeGroup', {
          sizeGroupId: selectedSizeGroupId
        })
        enqueueSnackbar('Size group deleted.', { variant: 'success' })
        const newSizeGroups = sizeGroups.filter(
          g => g.sizeGroupId !== selectedSizeGroupId
        )
        dispatch(
          setSizesAction({
            sizes,
            sizeGroups: newSizeGroups
          })
        )
        if (newSizeGroups.length > 0) {
          setSelectedSizeGroupId(newSizeGroups[0].sizeGroupId)
        } else {
          setSelectedSizeGroupId(null)
        }
      } catch (e) {
        enqueueSnackbar('Unable to delete size group.', { variant: 'error' })
      }
    }, [enqueueSnackbar, selectedSizeGroupId, dispatch, sizes, sizeGroups])

    const handleTitleSave = useCallback(
      async newTitle => {
        if (newTitle.trim().length === 0) {
          return
        }

        try {
          // setRequestInProgress(true)
          await axios.post('/postUpdateSizeGroup', {
            sizeGroupId: selectedSizeGroupId,
            title: newTitle
          })
          // console.log(response)
          // update locally, avoid an unesscessary additional network request
          dispatch(
            setSizesAction({
              sizes,
              sizeGroups: sizeGroups.map(g => {
                if (g.sizeGroupId === selectedSizeGroupId) {
                  return {
                    ...g,
                    title: newTitle
                  }
                }
                return g
              })
            })
          )
          enqueueSnackbar('Title updated.', { variant: 'success' })
        } catch (e) {
          enqueueSnackbar('Unable to update title.', { variant: 'error' })
        }
        // } finally {
        //   setRequestInProgress(false)
        // }
      },
      [selectedSizeGroupId, enqueueSnackbar, dispatch, sizes, sizeGroups]
    )

    if (!isDataLoaded) return null

    let selectedSizeGroup = sizeGroups.find(
      g => g.sizeGroupId === selectedSizeGroupId
    )

    // There are duplicate SIZE_DESC_SHORTs in RMIS.dbo.sizes, hence the grouping
    const groupedSizes = groupBy(sizes, 'SIZE_DESC_SHORT')
    // How many unique SIZE_DESC_SHORTs are selected?
    let includedCount = 0
    Object.keys(groupedSizes).forEach(s => {
      const ids = groupedSizes[s].map(x => x.SIZES_ID)
      // CHecking the first item is sufficient
      if (rmisSizeIds.includes(ids[0])) {
        includedCount++
      }
    })

    return (
      <Dialog
        open={isOpen}
        // open
        onClose={onClose}
        fullScreen
        scroll="paper"
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Manage Size Groups
            </Typography>
            <Button autoFocus color="inherit" onClick={onClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Container maxWidth="xl">
            <Box mt={2}>
              <Box display="flex" mb={2} alignItems="center">
                <Box flex={1} mr={2}>
                  <Typography gutterBottom>
                    Select a size group to edit the comprising RMIS sizes.
                  </Typography>
                </Box>
                <Box
                  flex={1}
                  alignItems="center"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <AddSizeGroupButton handleClick={handleAddSizeGroup} />
                </Box>
              </Box>

              {sizeGroups.length > 0 ? (
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item sm={3}>
                      <Paper className={classes.paper}>
                        <SizeGroupsList
                          handleSizeGroupClick={handleSizeGroupClick}
                          selectedSizeGroupId={selectedSizeGroupId}
                          sizeGroups={sizeGroups}
                        />
                      </Paper>
                    </Grid>
                    <Grid item sm={9}>
                      <Paper className={classes.paper}>
                        {isLoading || !selectedSizeGroup ? (
                          <CircularProgress />
                        ) : (
                          <>
                            <SizeGroupHeader
                              title={selectedSizeGroup.title}
                              handleTitleSave={handleTitleSave}
                              sizesCount={includedCount}
                            />
                            <SizeGroupItems
                              handleItemChange={handleItemChange}
                              isDisabled={isRequestInProgress}
                              rmisSizeIds={rmisSizeIds}
                              groupedSizes={groupedSizes}
                            />
                          </>
                        )}
                      </Paper>
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <DeleteSizeGroup handleDelete={handleDeleteSizeGroup} />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Typography>
                  No size groups are defined. Create one with the New Size Group
                  + button.
                </Typography>
              )}
            </Box>
          </Container>
        </DialogContent>
      </Dialog>
    )
  }
)

SizeGroupsListDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired
}

SizeGroupsListDialog.displayName = 'SizeGroupsListDialog'
// SizeGroupsListDialog.whyDidYouRender = true

export default withSnackbar(SizeGroupsListDialog)
