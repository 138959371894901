// based on https://github.com/tannerlinsley/react-table/tree/master/examples/pagination-controlled

import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { useTable, usePagination, useExpanded } from 'react-table'
import isEqual from 'lodash/isEqual'

import HeaderRows from '../HeaderRows'
import Pagination from '../../Pagination'
import TableLoader from '../../TableLoader'
import expanderColumn from '../expanderColumn'

// Create a function that will render our row sub components
const renderSubRows = subRows => {
  // console.log(subRows)
  return subRows.map((r, i) => (
    <TableRow key={r.original.skuId}>
      <TableCell
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      ></TableCell>
      <TableCell
        align="right"
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      ></TableCell>
      <TableCell
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      >
        {r.original.ALT_CODE1} {r.original.COL_DESC_SHORT}{' '}
        {r.original.SIZES_DESC}
      </TableCell>
      <TableCell
        align="right"
        style={{
          borderTop: 'none',
          borderBottom: i === subRows.length - 1 ? null : 'none'
        }}
      >
        {r.original.pull}
      </TableCell>
    </TableRow>
  ))
}

const ItemsByBranchTable = React.memo(
  props => {
    const {
      branchFilter,
      data,
      error,
      handleSort,
      isLoading,
      filters,
      pageIndex,
      pageSize,
      setBranchFilter,
      setPageIndex,
      setPageSize,
      sortBy,
      sortDirection,
      totalItems
    } = props

    const columns = React.useMemo(
      () => [
        expanderColumn,
        {
          id: 'branch',
          Header: 'Branch',
          Cell: cell => {
            const { BRANCH_CODE, fromBranch } = cell.row.original
            return (
              <Box display="flex" alignItems="center">
                <Typography>
                  {BRANCH_CODE} {fromBranch}
                </Typography>
              </Box>
            )
          },
          alignHeader: 'center'
        },
        {
          id: 'pull',
          Header: () => {
            return (
              <Typography>
                Pull
                {filters.removeTriplicates === 'yes' &&
                  ' (triplicates removed)'}
              </Typography>
            )
          },
          accessor: 'pull',
          align: 'right',
          alignHeader: 'right',
          colSpan: 2
        }
      ],
      [filters.removeTriplicates]
    )

    const table = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 5 },
        manualPagination: true,
        expandSubRows: false
      },
      useExpanded,
      usePagination
    )

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,
      dispatch
    } = table

    // console.log(table)

    const handleChangePage = useCallback(
      (_, newPage) => {
        dispatch({ type: 'resetExpanded' }) // https://github.com/tannerlinsley/react-table/blob/master/src/plugin-hooks/useExpanded.js#L26
        setPageIndex(newPage)
      },
      [setPageIndex, dispatch]
    )

    const handleChangeRowsPerPage = useCallback(
      e => {
        setPageSize(parseInt(e.target.value, 10))
        setPageIndex(0)
      },
      [setPageSize, setPageIndex]
    )

    return (
      <Box mt={2} position="relative">
        {error && (
          <Typography gutterBottom color="error">
            {error}
          </Typography>
        )}
        {isLoading && <TableLoader />}
        <Table
          size="small"
          {...getTableProps()}
          style={{ opacity: isLoading ? 0.33 : 1 }}
        >
          <TableHead>
            <Pagination
              totalItems={totalItems}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
            <HeaderRows
              headerGroups={headerGroups}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleSort={handleSort}
            />
            <TableRow>
              <TableCell />
              <TableCell>
                <TextField
                  value={branchFilter}
                  fullWidth
                  onChange={e => setBranchFilter(e.target.value)}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <React.Fragment key={row.getRowProps().key}>
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            align: cell.column.align || 'left',
                            colSpan: cell.column.colSpan || 1,
                            style: {
                              borderBottom: row.isExpanded ? 'none' : null
                            }
                          })}
                        >
                          {cell.render('Cell')}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  {row.isExpanded && renderSubRows(row.subRows)}
                </React.Fragment>
              )
            })}
          </TableBody>
          <TableFooter>
            <Pagination
              totalItems={totalItems}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              pageIndex={pageIndex}
              pageSize={pageSize}
            />
          </TableFooter>
        </Table>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    if (
      isEqual(prevProps.filters, nextProps.filters) &&
      isEqual(nextProps.data, prevProps.data)
    )
      return true
    return false
  }
)

ItemsByBranchTable.propTypes = {
  branchFilter: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  filters: PropTypes.object.isRequired,
  handleSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  setBranchFilter: PropTypes.func.isRequired,
  setPageIndex: PropTypes.func.isRequired,
  setPageSize: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired
}

ItemsByBranchTable.displayName = 'ItemsByBranchTable'
// ItemsByBranchTable.whyDidYouRender = true

export default ItemsByBranchTable
