import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import { setCumulativeWeeksAction } from '../../../redux/reducers/criteria/actions'

const CumulativeWeeks = () => {
  const dispatch = useDispatch()
  const cumulativeWeeks = useSelector(state => state.criteria.cumulativeWeeks)

  return (
    <Box>
      <Typography>
        Over how many weeks should cumulative metrics (stock, sales, rate of
        sale) be calculated?
      </Typography>
      <TextField
        disabled
        id="cumulative-weeks"
        label="Weeks"
        value={cumulativeWeeks}
        onChange={e => dispatch(setCumulativeWeeksAction(e.target.value))}
        type="number"
        inputProps={{
          min: '1',
          step: '1',
        }}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
      />
    </Box>
  )
}

export default CumulativeWeeks
