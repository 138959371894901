import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import InfoButton from '../../InfoButton'

import { setUnitsToRecallAction } from '../../../redux/reducers/criteria/actions'

const marks = [
  { value: 0, label: '0' },
  { value: 500 },
  { value: 1000, label: '1k' },
  { value: 1500 },
  { value: 2000, label: '2k' },
  { value: 2500 },
  { value: 3000, label: '3k' },
  { value: 3500 },
  { value: 4000, label: '4k' },
  { value: 4500 },
  { value: 5000, label: '5k' },
  { value: 5500 },
  { value: 6000, label: '6k' },
  { value: 6500 },
  { value: 7000, label: '7k' },
  { value: 7500 },
  { value: 8000, label: '8k' },
  { value: 8500 },
  { value: 9000, label: '9k' },
  { value: 9500 },
  { value: 10000, label: '10k' },
]

const UnitsToRecall = () => {
  const dispatch = useDispatch()
  const unitsToRecall = useSelector(state => state.criteria.unitsToRecall)
  const [localValue, setLocalValue] = useState(unitsToRecall)

  return (
    <>
      <Box display="flex">
        <Typography id="units-to-recall">
          Units to recall
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Units to recall
                </Typography>
                <Typography variant="body2" gutterBottom>
                  How many stock units should be recalled.
                </Typography>
                <Typography variant="caption">
                  Note: Depending on the filters and other parameters, there may
                  not be enough eligible stock units for this value to be
                  reached.
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <Slider
        aria-labelledby="units-to-recall"
        value={localValue}
        getAriaValueText={v => v.toString()}
        marks={marks}
        max={10000}
        min={0}
        onChange={(_, value) => setLocalValue(value)}
        onChangeCommitted={() => dispatch(setUnitsToRecallAction(localValue))}
        onDragEnd={(a, b) => console.log(a, b)}
        step={100}
        valueLabelDisplay="auto"
      />
    </>
  )
}

export default UnitsToRecall
