import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const useStyles = makeStyles(theme => ({
  row: {
    background:
      theme.palette.type === 'dark'
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
  },
}))

const HeaderRows = React.memo(
  ({ handleSort, headerGroups, sortBy, sortDirection }) => {
    const classes = useStyles()

    const handleSortClick = useCallback(id => () => handleSort(id), [
      handleSort,
    ])

    return (
      <>
        {headerGroups.map(hg => {
          return (
            <TableRow {...hg.getHeaderGroupProps()} className={classes.row}>
              {hg.headers.map(column => {
                return (
                  <TableCell
                    {...column.getHeaderProps({
                      align: column.alignHeader || 'left',
                      colSpan: column.colSpan || 1,
                    })}
                  >
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortDirection}
                      onClick={handleSortClick(column.id)}
                    >
                      {column.render('Header')}
                    </TableSortLabel>
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
      </>
    )
  }
)

HeaderRows.propTypes = {
  handleSort: PropTypes.func.isRequired,
  headerGroups: PropTypes.arrayOf(PropTypes.object.isRequired),
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
}

HeaderRows.displayName = 'HeaderRows'
// HeaderRows.whyDidYouRender = true

export default HeaderRows
