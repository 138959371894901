import { combineReducers } from 'redux'

import skus from './skus'
import branches, { fetchBranchesAction } from './branches'
import colours, { fetchColoursAction } from './colours'
import genders, { fetchGendersAction } from './genders'
import recalls from './recalls'
import seasons, { fetchSeasonsAction } from './seasons'
import suppliers, { fetchSuppliersAction } from './suppliers'
import productGroups, { fetchProductGroupsAction } from './productGroups'
import shopTypes, { fetchShopTypesAction } from './shopTypes'
import sizes, { fetchSizesAction } from './sizes'

export const fetchInitialDataAction = () => dispatch => {
  dispatch(fetchBranchesAction())
  dispatch(fetchColoursAction())
  dispatch(fetchGendersAction())
  dispatch(fetchProductGroupsAction())
  dispatch(fetchShopTypesAction())
  dispatch(fetchSizesAction())
  dispatch(fetchSuppliersAction())
  dispatch(fetchSeasonsAction())
}

export default combineReducers({
  skus,
  branches,
  colours,
  genders,
  productGroups,
  recalls,
  seasons,
  shopTypes,
  sizes,
  suppliers,
})
