import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const Sorting = () => {
  const { sortValue, cumulativeWeeks } = useSelector(state => state.criteria)

  const getSortValueText = () => {
    switch (sortValue) {
      case 'last-weeks-profit':
        return 'Last weeks profit'
      case 'last-weeks-cover':
        return 'Last weeks cover'
      case 'last-weeks-sales':
        return 'Last weeks sales units'
      case 'cumulative-profit':
        return `${cumulativeWeeks} week profit`
      case 'cumulative-cover':
        return `${cumulativeWeeks} week cover`
      case 'cumulative-sales':
        return `${cumulativeWeeks} week sales units`
      default:
        return 'Not selected'
    }
  }

  return (
    <ListItem dense disableGutters>
      <ListItemText>Sort by:</ListItemText>
      <ListItemSecondaryAction>{getSortValueText()}</ListItemSecondaryAction>
    </ListItem>
  )
}

export default Sorting
