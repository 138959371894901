import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

import NavError from '../NavError'
import SKUsContainer from './SKUsContainer'
import NoPaddingContainer from '../../NoPaddingContainer'
import NavButtons from '../NavButtons'
import * as constants from '../../../redux/constants'
import {
  setStepAction,
  setStepCompletedAction,
} from '../../../redux/reducers/criteria/actions'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  column: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
  },
}))

const SKUsScreen = React.memo(() => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    data: {
      skus: { total: skusTotal },
    },
  } = useSelector(state => state)
  const isNextButtonDisabled = !skusTotal || skusTotal === 0
  // console.log(skusTotal)

  const handleBackButtonClick = useCallback(
    () => dispatch(setStepAction(constants.STEP_PARAMETERS)),
    [dispatch]
  )
  const handleNextButtonClick = useCallback(() => {
    dispatch(setStepCompletedAction(constants.STEP_SKUS, true))
    dispatch(setStepAction(constants.STEP_SORTING))
  }, [dispatch])

  return (
    <NoPaddingContainer maxWidth="lg">
      <Paper className={classes.paper} elevation={3}>
        <SKUsContainer />
      </Paper>
      <NavButtons
        backButtonText="Back"
        handleBack={handleBackButtonClick}
        handleNext={handleNextButtonClick}
        isDisabled={isNextButtonDisabled}
        nextButtonText="Sorting"
      />
      {skusTotal === 0 && (
        <NavError message="There must be at least one eligible SKU to continue." />
      )}
    </NoPaddingContainer>
  )
})

export default SKUsScreen
