import { useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'

import {
  setWebSocketMessageAction,
  setWebSocketIdAction
} from './redux/reducers/global/actions'
// import { setRecallProgressAction } from "./redux/reducers/recall/actions"
import { setFindStockProgressAction } from './redux/reducers/findStock/actions'

// import { parseError } from "./helpers"

// handle messages from API
const useWebSocket = enqueueSnackbar => {
  const dispatch = useDispatch()
  const wsPing = useRef(null)

  const initWebSocket = useCallback(() => {
    let ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_SERVER)

    ws.onerror = e => {
      enqueueSnackbar(
        `Unable to connect to WebSocket server: ${e.target.url}`,
        { variant: 'error', preventDuplicate: true }
      )
    }

    ws.onopen = () => {
      // console.log("WebSocket connection established")
      enqueueSnackbar(
        `Connected to WebSocket server: ${process.env.REACT_APP_WEBSOCKET_SERVER}`,
        { variant: 'success', preventDuplicate: true }
      )
      // ping the server every 30 secs to prevent connection closing
      wsPing.current = setInterval(() => {
        // console.log("pinging ws")
        ws.send('ping')
      }, 30000)
    }

    ws.onclose = () => {
      // clean up
      // console.log("WebSocket connection closed")
      ws = null
      dispatch(setWebSocketIdAction(null))
      // send the websocket client id with every request so it can be identified on the server
      axios.defaults.headers.common['x-websocket-id'] = null
      clearInterval(wsPing.current)
      wsPing.current = null

      // try to reconnect
      initWebSocket()
    }

    ws.onmessage = x => {
      const messageJSON = JSON.parse(x.data)
      // console.log(messageJSON)

      switch (messageJSON.source) {
        case 'connected': {
          dispatch(setWebSocketIdAction(messageJSON.id))
          axios.defaults.headers.common['x-websocket-id'] = messageJSON.id
          break
        }

        case 'postFindStock': {
          switch (messageJSON.messageType) {
            case 'get skus':
              dispatch(
                setWebSocketMessageAction(
                  `Analysing ${messageJSON.skuCount} SKUs`
                )
              )
              break
            case 'increment units found':
              dispatch(
                setWebSocketMessageAction(
                  `${messageJSON.cumulativeUnitsToRecall}/${messageJSON.unitsToRecall} recall units found`
                )
              )
              dispatch(
                setFindStockProgressAction(messageJSON.cumulativeUnitsToRecall)
              )
              break
            case 'done':
              dispatch(setWebSocketMessageAction('Rendering report'))
              break
            case 'no more skus':
            default:
              break
          }
          break
        }

        case 'postCreateRecall':
          // console.log('postCreateRecall message', data)
          switch (messageJSON.messageType) {
            case 'creating':
              dispatch(setWebSocketMessageAction('Creating recall records'))
              break
            case 'saving stock data':
              dispatch(setWebSocketMessageAction('Saving stock data'))
              break
            case 'populating DailyRecall_Items':
              dispatch(
                setWebSocketMessageAction('Populating DailyRecall_Items')
              )
              break
            default:
              break
          }
          break

        default:
          break
      }
    }
  }, [dispatch, enqueueSnackbar])

  // Be careful that this effect is only run once, otherwise there'll be multiple message handlers
  useEffect(initWebSocket, [])
}

export default useWebSocket
