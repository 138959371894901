import React, { useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import uniqBy from 'lodash/uniqBy'

import * as constants from '../../../redux/constants'
import {
  setStepAction,
  setStepCompletedAction,
} from '../../../redux/reducers/criteria/actions'
import NoPaddingContainer from '../../NoPaddingContainer'
import NavButtons from '../NavButtons'
import NavError from '../NavError'
import RecallBranches from './RecallBranches'
import ReallocationBranches from './ReallocationBranches'
import UnitsToRecall from './UnitsToRecall'
import RecallTimeframe from './RecallTimeframe'
import BranchRecallPercentage from './BranchRecallPercentage'
import RemoveTriplicates from './RemoveTriplicates'
import ReallocatePercentage from './ReallocatePercentage'
import { makeBranchOptions } from './RecallBranches'
import { getIncludedItemsCount } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    padding: theme.spacing(4),
  },
}))

const RecallRealloc = React.memo(() => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    data: {
      branches: { items: branchItems },
    },
    criteria: {
      recallBranches: { includeOrExclude, selectedItems },
    },
  } = useSelector(state => state)

  const branchOptions = useMemo(
    () =>
      uniqBy(
        makeBranchOptions(branchItems).filter(s => !!s.label && !!s.value),
        'value'
      ),
    [branchItems]
  )

  const recallBranchesCount = useMemo(
    () =>
      getIncludedItemsCount(
        includeOrExclude,
        branchOptions.length,
        selectedItems.length
      ),
    [branchOptions.length, selectedItems.length, includeOrExclude]
  )

  const handleBackButtonClick = useCallback(
    () => dispatch(setStepAction(constants.STEP_SORTING)),
    [dispatch]
  )
  const handleNextButtonClick = useCallback(() => {
    dispatch(setStepCompletedAction(constants.STEP_RECALL_REALLOC, true))
    dispatch(setStepAction(constants.STEP_CONFIRM))
  }, [dispatch])

  return (
    <NoPaddingContainer maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <UnitsToRecall />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <BranchRecallPercentage />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <RemoveTriplicates />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <ReallocatePercentage />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={3}>
            <RecallBranches />
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={3}>
            <ReallocationBranches />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={3}>
            <RecallTimeframe />
          </Paper>
        </Grid>
      </Grid>

      <NavButtons
        backButtonText="Back"
        handleBack={handleBackButtonClick}
        handleNext={handleNextButtonClick}
        isDisabled={recallBranchesCount === 0}
        nextButtonText="Confirm"
      />
      {recallBranchesCount === 0 && (
        <NavError message="There must be at least one recall branch." />
      )}
    </NoPaddingContainer>
  )
})

export default RecallRealloc
