import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import isEqual from 'lodash/isEqual'

import ConfirmResetAppDialog from '../../ConfirmResetAppDialog'
import ValidationErrors from '../ValidationErrors'
import { parseError, validateCriteria } from '../../../helpers'
import * as criteriaActions from '../../../redux/reducers/criteria/actions'
import { resetAppAction } from '../../../redux/reducers/global/actions'
import * as constants from '../../../redux/constants'
import { criteriaInitialState } from '../../../redux/reducers/criteria'

const useStyles = makeStyles(theme => ({
  input: {
    visibility: 'hidden', // instead of display: 'none', as we cannot call the click handler on a non-displayed file input
    width: 0
  },
  progress: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  }
}))

const LoadExistingCriteriaButton = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [errors, setErrors] = useState(false) // false or array of errors
  const [isValidating, setIsValidating] = useState(false)
  const appData = useSelector(state => state.data)
  const inputEl = useRef(null)
  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [isConfirmed, setConfirmed] = useState(false)
  const criteria = useSelector(state => state.criteria)

  useEffect(() => {
    if (isConfirmed) {
      dispatch(resetAppAction({ activeScreen: constants.SCREEN_CRITERIA }))
      dispatch(criteriaActions.setIsDirtyAction(true))
      inputEl.current.click()
    }
  }, [dispatch, isConfirmed])

  const handleConfirm = () => {
    setConfirmed(true)
    setConfirmOpen(false)
  }

  const handleClose = () => {
    setConfirmed(false)
    setConfirmOpen(false)
  }

  const loadJSONFile = e => {
    setErrors(false)

    const fileReader = new FileReader()
    fileReader.onerror = e => {
      setErrors([`Unable to read file. Code ${e.target.error.code}.`])
    }

    fileReader.onload = async e => {
      try {
        const criteriaJSON = JSON.parse(e.target.result)

        // Validate criteria JSON.
        const criteriaErrors = await validateCriteria(criteriaJSON, appData)
        if (criteriaErrors !== true) {
          // Invalid
          setErrors(criteriaErrors)
          setIsValidating(false)
          return
        }

        // Valid criteria, set redux state
        dispatch(criteriaActions.setIsDirtyAction(true))

        dispatch(
          criteriaActions.setCumulativeWeeksAction(criteriaJSON.cumulativeWeeks)
        )
        dispatch(criteriaActions.setShopTypesAction(criteriaJSON.shopTypes))
        dispatch(criteriaActions.setGenderAction(criteriaJSON.gender))
        dispatch(
          criteriaActions.setProductGroupsIncludeAction(
            criteriaJSON.productGroups.includeOrExclude
          )
        )
        dispatch(
          criteriaActions.setProductGroupsAction(
            criteriaJSON.productGroups.selectedItems
          )
        )
        dispatch(
          criteriaActions.setSuppliersIncludeAction(
            criteriaJSON.suppliers.includeOrExclude
          )
        )
        dispatch(
          criteriaActions.setSuppliersAction(
            criteriaJSON.suppliers.selectedItems
          )
        )
        dispatch(
          criteriaActions.setSeasonsIncludeAction(
            criteriaJSON.seasons.includeOrExclude
          )
        )
        dispatch(
          criteriaActions.setSeasonsAction(criteriaJSON.seasons.selectedItems)
        )
        dispatch(
          criteriaActions.setSKUsIncludeAction(
            criteriaJSON.skuLevelFilters.includeOrExclude,
            { recountSKUs: false }
          )
        )
        dispatch(
          criteriaActions.setSKUsAction(
            criteriaJSON.skuLevelFilters.selectedItems
          )
        )
        dispatch(
          criteriaActions.setColoursIncludeAction(
            criteriaJSON.colourLevelFilters.includeOrExclude,
            { recountSKUs: false }
          )
        )
        dispatch(
          criteriaActions.setColoursAction(
            criteriaJSON.colourLevelFilters.selectedItems,
            { recountSKUs: false }
          )
        )
        if (criteriaJSON.sizeLevelFilters) {
          dispatch(
            criteriaActions.setSizesIncludeAction(
              criteriaJSON.sizeLevelFilters.includeOrExclude,
              { recountSKUs: false }
            )
          )
          dispatch(
            criteriaActions.setSizesAction(
              criteriaJSON.sizeLevelFilters.selectedItems,
              { recountSKUs: false }
            )
          )
        }
        dispatch(criteriaActions.setSortValueAction(criteriaJSON.sortValue))
        dispatch(
          criteriaActions.setBranchesIncludeAction(
            'recall',
            criteriaJSON.recallBranches.includeOrExclude
          )
        )
        dispatch(
          criteriaActions.setBranchesAction(
            'recall',
            criteriaJSON.recallBranches.selectedItems
          )
        )
        dispatch(
          criteriaActions.setBranchesIncludeAction(
            'reallocation',
            criteriaJSON.reallocationBranches.includeOrExclude
          )
        )
        dispatch(
          criteriaActions.setBranchesAction(
            'reallocation',
            criteriaJSON.reallocationBranches.selectedItems
          )
        )
        dispatch(
          criteriaActions.setMaxWarehouseStockAction(
            criteriaJSON.maxWarehouseStock
          )
        )
        dispatch(
          criteriaActions.setMaxWarehouseStockExcludedAction(
            !!criteriaJSON.maxWarehouseStockExcluded
          )
        )
        dispatch(
          criteriaActions.setMinBranchStockAction(criteriaJSON.minBranchStock)
        )
        dispatch(
          criteriaActions.setMinBranchStockExcludedAction(
            !!criteriaJSON.minBranchStockExcluded
          )
        )
        dispatch(
          criteriaActions.setUnitsToRecallAction(criteriaJSON.unitsToRecall)
        )
        dispatch(
          criteriaActions.setMinRecallPriceAction(criteriaJSON.minRecallPrice)
        )
        dispatch(
          criteriaActions.setMinRecallPriceExcludedAction(
            !!criteriaJSON.minRecallPriceExcluded
          )
        )
        dispatch(criteriaActions.setMinMarginAction(criteriaJSON.minMargin))
        dispatch(
          criteriaActions.setMinMarginExcludedAction(
            !!criteriaJSON.minMarginExcluded
          )
        )
        dispatch(
          criteriaActions.setRemoveTriplicatesAction(
            criteriaJSON.removeTriplicates
          )
        )
        dispatch(
          criteriaActions.setReallocatePercentageAction(
            criteriaJSON.reallocatePercentage
          )
        )
        dispatch(
          criteriaActions.setBranchRecallPercentageAction(
            criteriaJSON.branchRecallPercentage
          )
        )
        dispatch(
          criteriaActions.setRecallTimeframeAction(
            criteriaJSON.recallTimeframe
          )
        )

        // Navigate to confirm screen
        dispatch(
          criteriaActions.setStepCompletedAction(constants.STEP_START, true)
        )
        dispatch(
          criteriaActions.setStepCompletedAction(constants.STEP_FILTERS, true)
        )
        dispatch(
          criteriaActions.setStepCompletedAction(
            constants.STEP_PARAMETERS,
            true
          )
        )
        dispatch(
          criteriaActions.setStepCompletedAction(constants.STEP_SKUS, true)
        )
        dispatch(
          criteriaActions.setStepCompletedAction(constants.STEP_SORTING, true)
        )
        dispatch(
          criteriaActions.setStepCompletedAction(
            constants.STEP_RECALL_REALLOC,
            true
          )
        )
        dispatch(criteriaActions.setStepAction(constants.STEP_CONFIRM))

        // don't need to reset isValidating here as component is unmonuted
        // when we navigate to the confirm screen
      } catch (e) {
        console.log(e)
        setErrors([parseError(e)])
        setIsValidating(false)
        return
      }
    }

    setIsValidating(true)
    fileReader.readAsText(e.target.files[0])
  }

  return (
    <>
      <input
        accept="application/json"
        className={classes.input}
        id="button-file"
        onChange={loadJSONFile} // fires after a file has been selected
        onClick={e => {
          if (isEqual(criteriaInitialState, criteria) || isConfirmed) {
            setConfirmed(false)
            inputEl.current.value = null // so we can reload the same file and onChange will fire
          } else {
            // open confirm dialog
            e.preventDefault()
            setConfirmOpen(true)
          }
        }}
        ref={inputEl}
        type="file"
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        <label htmlFor="button-file">
          <Button component="span" variant="outlined">
            Load Existing
          </Button>
        </label>
        {isValidating && (
          <>
            <CircularProgress className={classes.progress} />
            <Typography>Validating criteria...</Typography>
          </>
        )}
        {errors && errors.length > 0 && (
          <ValidationErrors errors={errors} maxErrors={5} />
        )}
      </Box>
      <ConfirmResetAppDialog
        isOpen={isConfirmOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </>
  )
}

export default LoadExistingCriteriaButton
