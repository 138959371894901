import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import uniqBy from 'lodash/uniqBy'

import Label from '../../../Label'
import DownshiftMultipleSelect from '../../DownshiftMultipleSelect'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'
import ErrorScreen from '../../../ErrorScreen'
import InfoButton from '../../../InfoButton'
import {
  setBranchesAction,
  setBranchesIncludeAction,
} from '../../../../redux/reducers/criteria/actions'
import { fetchBranchesAction } from '../../../../redux/reducers/data/branches'
import { getIncludedItemsCount } from '../../../../helpers'
import RecallBranchesListDialog from './RecallBranchesListDialog'

export const makeBranchOptions = items =>
  items.map(b => ({
    label: b.branch_desc,
    value: parseInt(b.branch_id, 10),
  }))

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
}))

const Header = () => {
  const classes = useStyles()

  return (
    <Typography variant="h5" component="h2">
      Recall Branches
      <InfoButton
        tooltipContent={
          <>
            <Typography variant="h6" gutterBottom>
              Recall Branches
            </Typography>
            <Typography variant="body2" paragraph>
              Which branches should be included in the recall?
            </Typography>
            <Typography variant="caption" paragraph>
              Stock and sales figures for excluded branches are{' '}
              <span className={classes.bold}>NOT</span> included in the
              calculation of the metrics on the previous screen.
            </Typography>
            <Typography variant="caption">
              If a branch excluded by these filters is ranked in the top
              performing branches for a SKU, it will be skipped and the next
              highest-ranked eligible branch included instead.
            </Typography>
          </>
        }
      />
    </Typography>
  )
}

const RecallBranches = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const {
    data: {
      branches: { error, isLoaded, items },
    },
    criteria: {
      recallBranches: { includeOrExclude, selectedItems },
    },
  } = useSelector(state => state)

  const setSelectedItem = useCallback(
    item => dispatch(setBranchesAction('recall', item)),
    [dispatch]
  )

  const handleDialogClose = useCallback(() => setDialogOpen(false), [])
  const handleDialogOpen = useCallback(() => setDialogOpen(true), [])

  const branchOptions = useMemo(
    () =>
      uniqBy(
        makeBranchOptions(items).filter(s => !!s.label && !!s.value),
        'value'
      ),
    [items]
  )

  const count = getIncludedItemsCount(
    includeOrExclude,
    branchOptions.length,
    selectedItems.length
  )

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Select recall branches to include'
      case 'exclude':
        return 'Select recall branches to exclude'
      default:
        return 'Select recall branches'
    }
  }

  if (error)
    return (
      <>
        <Header />
        <ErrorScreen
          action={() => dispatch(fetchBranchesAction())}
          actionButtonText="Try again"
          hideAppHeader
          errors={[error]}
        />
      </>
    )

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Header />
        {isLoaded && (
          <Box display="flex" alignItems="center">
            <Label
              isError={count === 0}
              isSuccess={count > 0}
              text={`${count.toLocaleString()} / ${branchOptions.length}`}
            />
            <MuiLink as="button" onClick={handleDialogOpen}>
              View entire list
            </MuiLink>
          </Box>
        )}
      </Box>
      {isLoaded && (
        <>
          <Box display="flex" alignItems="center" mb={1}>
            <IncludeExcludeSelect
              value={includeOrExclude}
              name="recallBranchInclude"
              onChange={e =>
                dispatch(setBranchesIncludeAction('recall', e.target.value))
              }
            />
            <Typography>branches</Typography>
          </Box>
          {includeOrExclude !== 'include-all' && (
            <DownshiftMultipleSelect
              id="recall-branches"
              maxHeight={300}
              placeholder={getPlaceholder()}
              selectedItem={selectedItems}
              setSelectedItem={setSelectedItem}
              suggestions={branchOptions}
            />
          )}
          <RecallBranchesListDialog
            isOpen={isDialogOpen}
            handleClose={handleDialogClose}
          />
        </>
      )}
    </>
  )
}

export default RecallBranches
