import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import InfoButton from '../../InfoButton'
import { setBranchRecallPercentageAction } from '../../../redux/reducers/criteria/actions'

const marks = [
  { value: 0, label: '0%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' },
]

const BranchRecallPercentage = () => {
  const dispatch = useDispatch()
  const branchRecallPercentage = useSelector(
    state => state.criteria.branchRecallPercentage
  )
  const [localValue, setLocalValue] = useState(branchRecallPercentage)

  return (
    <>
      <Box display="flex">
        <Typography id="branch-recall-percentage">
          Percentage of branches to recall from
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Percentage of branches to recall from
                </Typography>
                <Typography variant="body2" gutterBottom>
                  For each SKU, branches are ranked from worst to best performing by the sort value
                  set on the previous page.
                </Typography>
                <Typography variant="body2">
                  From what percentage of the worst performing branches should stock be recalled?
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <Slider
        aria-labelledby="branch-recall-percentage"
        value={localValue}
        getAriaValueText={v => v.toString()}
        marks={marks}
        max={100}
        min={0}
        onChange={(_, value) => setLocalValue(value)}
        onChangeCommitted={() => dispatch(setBranchRecallPercentageAction(localValue))}
        step={1}
        valueLabelDisplay="auto"
        valueLabelFormat={x => `${x.toString()}%`}
      />
    </>
  )
}

export default BranchRecallPercentage
