import React from 'react'
import { useSelector } from 'react-redux'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import { getIncludedItemsCount } from '../../../helpers'
import { makeProductGroupOptions } from '../Filters/ProductGroups'

const ProductGroups = () => {
  const {
    criteria: {
      productGroups: { includeOrExclude, selectedItems },
      gender: selectedGender,
      seasons: {
        includeOrExclude: seasonsInclude,
        selectedItems: selectedSeasonItems
      }
    },
    data: {
      productGroups: { items: allProductGroups },
      seasons: { items: allSeasonItems }
    }
  } = useSelector(state => state)

  let selectedSeasons
  switch (seasonsInclude) {
    case 'include-all':
      selectedSeasons = allSeasonItems.map(s => s.season_year)
      break
    case 'include':
      selectedSeasons = selectedSeasonItems.map(s => s.value)
      break
    case 'exclude':
      selectedSeasons = allSeasonItems
        .map(s => s.season_year)
        .filter(s => !selectedSeasonItems.map(s => s.value).includes(s))
      break
    default:
      selectedSeasons = []
      break
  }

  // filter options by season & gender
  const filteredProductGroups = makeProductGroupOptions(
    allProductGroups,
    selectedSeasons,
    selectedGender
  )

  const count = getIncludedItemsCount(
    includeOrExclude,
    filteredProductGroups.length,
    selectedItems.length
  )

  return (
    <List dense disablePadding>
      <ListItem dense disableGutters>
        <ListItemText>
          {`${selectedGender !== 'All' ? `${selectedGender} ` : ''}`}Product
          groups:
        </ListItemText>
        <ListItemSecondaryAction>
          {`${count.toLocaleString()} / ${
            filteredProductGroups.length
          } included`}
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}

export default ProductGroups
