import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import CopyToClipboard from 'react-copy-to-clipboard'
import { withSnackbar } from 'notistack'

const CopyCriteriaButton = ({ criteriaJSON, enqueueSnackbar, hideText }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {!hideText && <Typography>Copy criteria</Typography>}
      <Tooltip title="Copy criteria to clipboard">
        <CopyToClipboard
          text={criteriaJSON}
          onCopy={() =>
            enqueueSnackbar('Criteria copied.', { variant: 'success' })
          }
        >
          <IconButton aria-label="Copy criteria JSON">
            <Icon className={'fa fa-copy'} />
          </IconButton>
        </CopyToClipboard>
      </Tooltip>
    </Box>
  )
}

CopyCriteriaButton.propTypes = {
  criteriaJSON: PropTypes.string.isRequired,
  hideText: PropTypes.bool,
  enqueueSnackbar: PropTypes.func.isRequired, // from notistack
}
CopyCriteriaButton.defaultProps = {
  hideText: false,
}

export default withSnackbar(CopyCriteriaButton)
