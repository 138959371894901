import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import MuiLink from '@material-ui/core/Link'

import {
  setSizesIncludeAction,
  setSizesAction
} from '../../../../redux/reducers/criteria/actions'
import {
  countSKUsAction,
  clearSKUsCountCacheAction
} from '../../../../redux/reducers/data/skus'
import DownshiftMultipleSelect from '../../DownshiftMultipleSelect'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'
import SizeGroupsListDialog from './SizeGroupsListDialog'

export const makeSizeOptions = items =>
  items.map(g => ({
    label: g.title,
    value: g.sizeGroupId
  }))

const SizeLevel = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const {
    criteria: {
      sizeLevelFilters: { includeOrExclude, selectedItems }
    },
    data: {
      sizes: { isLoaded, sizeGroups }
    }
  } = useSelector(state => state)

  // console.log(items, sizeGroups)

  const setSelectedItem = useCallback(item => dispatch(setSizesAction(item)), [
    dispatch
  ])

  const options = useMemo(
    () => (sizeGroups ? makeSizeOptions(sizeGroups) : []),
    [sizeGroups]
  )

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Select size groups to include'
      case 'exclude':
        return 'Select size groups to exclude'
      default:
        return 'Select size groups'
    }
  }

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false)
    dispatch(clearSKUsCountCacheAction()) // because composition of size groups is not stored in the cache key
    dispatch(countSKUsAction())
  }, [dispatch])
  const handleDialogOpen = useCallback(() => setDialogOpen(true), [])

  if (!isLoaded) return <CircularProgress variant="indeterminate" size={14} />

  return (
    <Box display="flex" mb={1} width="100%" flexDirection="column">
      <Box display="flex" alignItems="center" mb={1}>
        <IncludeExcludeSelect
          value={includeOrExclude}
          name="sizeGroupsInclude"
          onChange={e => dispatch(setSizesIncludeAction(e.target.value))}
        />
        <Typography>sizes</Typography>
        <Box ml={1}>
          <MuiLink as="button" onClick={handleDialogOpen}>
            Manage size groups
          </MuiLink>
        </Box>
      </Box>
      {includeOrExclude !== 'include-all' && (
        <DownshiftMultipleSelect
          id="sizeGroups"
          maxHeight={300}
          placeholder={getPlaceholder()}
          selectedItem={selectedItems}
          setSelectedItem={setSelectedItem}
          suggestions={options}
        />
      )}
      <SizeGroupsListDialog
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
      />
    </Box>
  )
}

export default SizeLevel
