import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import InfoButton from '../../InfoButton'
import {
  setMaxWarehouseStockAction,
  setMaxWarehouseStockExcludedAction
} from '../../../redux/reducers/criteria/actions'

const marks = [
  { value: 0, label: '0' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' }
]

const MaxWarehouseStock = () => {
  const dispatch = useDispatch()
  const { maxWarehouseStock, maxWarehouseStockExcluded } = useSelector(
    state => state.criteria
  )
  const [localValue, setLocalValue] = useState(maxWarehouseStock)

  const handleExcludeChecked = e => {
    dispatch(setMaxWarehouseStockExcludedAction(e.target.checked))
  }

  return (
    <>
      <Box display="flex">
        <Typography id="max-warehouse-stock">
          Maximum warehouse / home shopping stock
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Maximum warehouse / home shopping stock
                </Typography>
                <Typography variant="body2" gutterBottom>
                  A SKU will only be considered for recall if the sum of the
                  current warehouse stock ("available to promise") and home shopping stock is less than
                  or equal to this value.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Stock levels are taken from <em>RMIS.dbo.Product_Availability</em>.
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <Slider
        aria-labelledby="max-warehouse-stock"
        value={localValue}
        disabled={maxWarehouseStockExcluded}
        getAriaValueText={v => v.toString()}
        marks={marks}
        max={30}
        min={0}
        onChange={(_, value) => setLocalValue(value)}
        onChangeCommitted={() => dispatch(setMaxWarehouseStockAction(localValue))}
        step={1}
        valueLabelDisplay="auto"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={maxWarehouseStockExcluded}
            onChange={handleExcludeChecked}
          />
        }
        label="Exclude this parameter"
      />
    </>
  )
}

export default MaxWarehouseStock
