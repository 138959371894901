import React, { useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import CircularProgress from '@material-ui/core/CircularProgress'

import { countSKUsAction } from '../../../redux/reducers/data/skus'

const SKUs = () => {
  const dispatch = useDispatch()
  const {
    data: {
      skus: { total: skusTotal }
    }
  } = useSelector(state => state)

  const countSKUs = useCallback(() => {
    if (!skusTotal && skusTotal !== 0) {
      dispatch(countSKUsAction())
    }
  }, [dispatch, skusTotal])

  useEffect(() => {
    let didCancel = false

    if (!didCancel) {
      countSKUs()
    }

    return () => {
      didCancel = true
    }
  }, [countSKUs])

  return (
    <ListItem dense disableGutters>
      <ListItemText>SKUs:</ListItemText>
      <ListItemSecondaryAction>
        {!skusTotal && skusTotal !== 0 ? (
          <CircularProgress size={12} />
        ) : (
          skusTotal.toLocaleString()
        )}{' '}
        will be analysed
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default SKUs
