import React, { useEffect, useRef, useReducer, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import useDebounce from '../../useDebounce'
import RecallTable from './RecallTable'
import NewRecallButton from './NewRecallButton'
import NoPaddingContainer from '../NoPaddingContainer'
import { fetchRecallsAction } from '../../redux/reducers/data/recalls'

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE_INDEX':
      return {
        ...state,
        pageIndex: action.payload,
        shouldUpdate: true,
      }
    case 'SET_ROWS_PER_PAGE':
      return {
        ...state,
        pageIndex: 0,
        rowsPerPage: action.payload,
        shouldUpdate: true,
      }
    case 'SET_IS_TEST_FILTER':
      return {
        ...state,
        pageIndex: 0,
        shouldUpdate: true,
        isTestFilter: action.payload,
      }
    case 'SET_TITLE_FILTER':
      return {
        ...state,
        pageIndex: 0,
        shouldUpdate: false,
        titleFilter: action.payload,
      }
    case 'SET_SHOULD_UPDATE':
      return {
        ...state,
        shouldUpdate: true,
      }
    case 'SET_SORTING': {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      }
    }
    default:
      return state
  }
}

const HomeScreen = React.memo(() => {
  const dispatch = useDispatch()
  const {
    data: {
      recalls: {
        items: recallItems,
        totalItems: totalRecallItems,
        totalFilteredItems: totalFilteredRecallItems,
        isLoaded,
        error,
      },
    },
  } = useSelector(state => state)
  const isFirstUpdate = useRef(true)

  // shouldUpdate is a flag to stop updating on titleFilter change
  // we only want to update when the debounced value changes, so it's flipped
  // after that happens in the callback to useDebounce()
  const [tableState, tableDispatch] = useReducer(reducer, {
    isTestFilter: -1,
    pageIndex: 0,
    rowsPerPage: 5,
    shouldUpdate: true,
    sortBy: 'issuedDate',
    sortDirection: 'desc',
    titleFilter: '',
  })
  const {
    isTestFilter,
    pageIndex,
    rowsPerPage,
    shouldUpdate,
    sortBy,
    sortDirection,
    titleFilter,
  } = tableState

  // third argument is callback, reset page to 0 after the filter changes
  const debouncedTitleFilter = useDebounce(
    titleFilter,
    300, // ms
    useCallback(
      () => tableDispatch({ type: 'SET_SHOULD_UPDATE', payload: true }),
      []
    )
  )

  useEffect(() => {
    if (!shouldUpdate) return
    dispatch(
      fetchRecallsAction(
        pageIndex + 1,
        rowsPerPage,
        debouncedTitleFilter,
        isTestFilter,
        sortBy,
        sortDirection
      )
    )
  }, [
    dispatch,
    isTestFilter,
    pageIndex,
    debouncedTitleFilter,
    rowsPerPage,
    shouldUpdate,
    sortBy,
    sortDirection,
  ])

  useEffect(() => {
    isFirstUpdate.current = false
  }, [])

  const handleChangePage = useCallback((_, newPageIndex) => {
    tableDispatch({ type: 'SET_PAGE_INDEX', payload: newPageIndex })
  }, [])

  const handleChangeRowsPerPage = useCallback(e => {
    tableDispatch({
      type: 'SET_ROWS_PER_PAGE',
      payload: parseInt(e.target.value, 10),
    })
  }, [])

  const handleSort = useCallback(
    column => {
      tableDispatch({
        type: 'SET_SORTING',
        payload: {
          sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
          sortBy: column,
        },
      })
    },
    [sortDirection]
  )

  const setTitleFilter = useCallback(value => {
    tableDispatch({
      type: 'SET_TITLE_FILTER',
      payload: value,
    })
  }, [])

  const setIsTestFilter = useCallback(value => {
    tableDispatch({
      type: 'SET_IS_TEST_FILTER',
      payload: value,
    })
  }, [])

  // console.log(recallItems)

  return (
    <>
      <NoPaddingContainer maxWidth="xl">
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          width="100%"
        >
          <Typography variant="h4" component="h2" gutterBottom>
            Recalls
          </Typography>
          <Box>
            <NewRecallButton />
          </Box>
        </Box>
        <Box mt={2}>
          {error && (
            <Typography color="error" gutterBottom>
              {error}
            </Typography>
          )}
          {isLoaded && totalRecallItems === 0 && (
            <Typography>
              No recalls found. You can issue a recall with the New Recall +
              button.
            </Typography>
          )}
          {totalRecallItems > 0 && (
            <RecallTable
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleSort={handleSort}
              isLoaded={isLoaded}
              isTestFilter={isTestFilter}
              pageIndex={pageIndex}
              rows={recallItems}
              rowsPerPage={rowsPerPage}
              setIsTestFilter={setIsTestFilter}
              setTitleFilter={setTitleFilter}
              sortBy={sortBy}
              sortDirection={sortDirection}
              titleFilter={titleFilter}
              totalItems={totalFilteredRecallItems}
            />
          )}
        </Box>
      </NoPaddingContainer>
    </>
  )
})

HomeScreen.displayName = 'HomeScreen'
// HomeScreen.whyDidYouRender = true

export default HomeScreen
