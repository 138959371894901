import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const Parameters = () => {
  const {
    criteria: {
      maxWarehouseStock,
      maxWarehouseStockExcluded,
      minBranchStock,
      minBranchStockExcluded,
      minMargin,
      minMarginExcluded,
      minRecallPrice,
      minRecallPriceExcluded
    }
  } = useSelector(state => state)

  if (
    maxWarehouseStockExcluded &&
    minBranchStockExcluded &&
    minMarginExcluded &&
    minRecallPriceExcluded
  ) {
    return (
      <ListItem dense disableGutters>
        All parameters excluded.
      </ListItem>
    )
  }

  return (
    <>
      {!maxWarehouseStockExcluded && (
        <ListItem dense disableGutters>
          <ListItemText>Maximum warehouse stock:</ListItemText>
          <ListItemSecondaryAction>
            {maxWarehouseStock.toLocaleString()}
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {!minBranchStockExcluded && (
        <ListItem dense disableGutters>
          <ListItemText>Minimum branch stock:</ListItemText>
          <ListItemSecondaryAction>
            {minBranchStock.toLocaleString()}
          </ListItemSecondaryAction>
        </ListItem>
      )}
      {!minMarginExcluded && (
        <ListItem dense disableGutters>
          <ListItemText>Minimum margin:</ListItemText>
          <ListItemSecondaryAction>{minMargin}%</ListItemSecondaryAction>
        </ListItem>
      )}
      {!minRecallPriceExcluded && (
        <ListItem dense disableGutters>
          <ListItemText>Minimum recall price:</ListItemText>
          <ListItemSecondaryAction>
            &pound;{minRecallPrice.toLocaleString()}
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </>
  )
}

export default Parameters
