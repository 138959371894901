import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: theme.spacing(2),
  },
  listItem: {
    padding: 0,
  },
  errorText: {
    color: theme.palette.error.main,
    fontStyle: 'italic',
  },
}))

const ValidationErrors = ({ errors, maxErrors }) => {
  const classes = useStyles()

  return (
    <List disablePadding className={classes.list}>
      {errors.slice(0, maxErrors).map((e, index) => (
        <ListItem key={index} disableGutters className={classes.listItem}>
          <ListItemText className={classes.errorText} variant="body2">
            Error: {e}
          </ListItemText>
        </ListItem>
      ))}
      {errors.length > maxErrors && (
        <Typography>
          ...plus {errors.length - maxErrors} more error
          {errors.length - maxErrors === 1 ? '' : 's'}.
        </Typography>
      )}
    </List>
  )
}

ValidationErrors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  maxErrors: PropTypes.number,
}
ValidationErrors.defaultProps = {
  maxErrors: 5,
}

export default ValidationErrors
