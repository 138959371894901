import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import clsx from 'clsx'
import { yellow, blueGrey } from '@material-ui/core/colors'
import { useSelector, useDispatch } from 'react-redux'

import { showRefundsAction } from '../../redux/reducers/global/actions'
import Pagination from '../Pagination'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 700,
  },
  highlight: {
    backgroundColor: yellow[50],
    color:
      theme.palette.type === 'dark' ? theme.palette.primary.contrastText : null,
  },
  pull: {
    backgroundColor: blueGrey[50],
  },
  pullText: {
    color:
      theme.palette.type === 'dark' ? theme.palette.primary.contrastText : null,
    fontSize: theme.typography.htmlFontSize * 1.2,
  },
  smallIcon: {
    height: theme.typography.htmlFontSize,
    width: theme.typography.htmlFontSize,
  },
  formControlLabel: {
    marginRight: 0
  }
}))

const TableHeader = ({
  cumulativeWeeks,
  reallocatePercentage,
  sortValue,
  totalItems,
  setPage,
  setRowsPerPage,
  page,
  rowsPerPage,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const explicitlyShowRefunds = useSelector(state => state.global.explicitlyShowRefunds)

  const handleToggleShowRefunds = () => {
    dispatch(showRefundsAction(!explicitlyShowRefunds))
  }

  return (
    <TableHead>
      <Pagination
        totalItems={totalItems}
        handleChangeRowsPerPage={e => {
          setPage(0)
          setRowsPerPage(parseInt(e.target.value, 10))
        }}
        handleChangePage={(_, newPage) => setPage(newPage)}
        pageIndex={page}
        pageSize={rowsPerPage}
      />
      <TableRow>
        <TableCell />
        <TableCell align="center" colSpan={4}>
          Current stock
        </TableCell>
        {explicitlyShowRefunds ? (
          <>
            <TableCell align="center" colSpan={2}>
              Last wk sales <Typography variant="caption">(-refunds)</Typography>
            </TableCell>
            <TableCell align="center" colSpan={2}>
              {cumulativeWeeks} wk sales <Typography variant="caption">(-refunds)</Typography>
            </TableCell>
          </>
        ) : (
          <>
            <TableCell align="center" colSpan={2}>
              Last wk net sales
            </TableCell>
            <TableCell align="center" colSpan={2}>
              {cumulativeWeeks} wk net sales
            </TableCell>
          </>
        )}
        <TableCell align="center" colSpan={2}>
          Profit
        </TableCell>
        <TableCell align="center" colSpan={2}>
          Cover
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={5} />
        <TableCell colSpan={4} align="center">
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={explicitlyShowRefunds}
                checkedIcon={<CheckBoxIcon className={classes.smallIcon} />}
                icon={<CheckBoxOutlineBlankIcon className={classes.smallIcon}  />}
                color="secondary"
                onChange={handleToggleShowRefunds}
                value="showRefunds"
              />
            }
            label={(
              <Typography variant="caption" color="secondary">
                Explicitly show refunds
              </Typography>
            )}
          />
        </TableCell>
        <TableCell colSpan={4} />
      </TableRow>
      <TableRow>
        <TableCell align="center">SKU</TableCell>
        <TableCell align="right">WH + HS stock</TableCell>
        <TableCell align="right">Branch stock</TableCell>
        <TableCell align="right">Branches having stock > 0</TableCell>
        <TableCell
          align="center"
          className={clsx(classes.bold, classes.pull, classes.pullText)}
        >
          Pull
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-sales',
          })}
        >
          Retail
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-sales',
          })}
        >
          WH + HS
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-sales',
          })}
        >
          Retail
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-sales',
          })}
        >
          WH + HS
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-profit',
          })}
        >
          Last wk
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-profit',
          })}
        >
          {cumulativeWeeks} wk
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-cover',
          })}
        >
          Last wk
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-cover',
          })}
        >
          {cumulativeWeeks} wk
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  cumulativeWeeks: PropTypes.number,
  reallocatePercentage: PropTypes.number,
  sortValue: PropTypes.string,
  totalItems: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
}
TableHeader.defaultProps = {
  cumulativeWeeks: null,
  reallocatePercentage: null,
  sortValue: null,
}

export default TableHeader
