import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'

import Label from '../../../Label'
import InfoButton from '../../../InfoButton'
import DownshiftMultipleSelect from '../../DownshiftMultipleSelect'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'

import {
  setSeasonsAction,
  setSeasonsIncludeAction,
} from '../../../../redux/reducers/criteria/actions'
import { getIncludedItemsCount } from '../../../../helpers'
import SeasonsListDialog from './SeasonsListDialog'

export const makeSeasonOptions = items =>
  items.map(g => ({
    label: g.season_year,
    value: g.season_year,
  }))

const Seasons = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const {
    criteria: {
      seasons: { includeOrExclude, selectedItems },
    },
    data: {
      seasons: { isLoaded, items },
    },
  } = useSelector(state => state)

  const setSelectedItem = useCallback(
    item => dispatch(setSeasonsAction(item)),
    [dispatch]
  )

  const handleDialogClose = useCallback(() => setDialogOpen(false), [])
  const handleDialogOpen = useCallback(() => setDialogOpen(true), [])

  const options = useMemo(() => (items ? makeSeasonOptions(items) : []), [
    items,
  ])

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Select seasons to include'
      case 'exclude':
        return 'Select seasons to exclude'
      default:
        return 'Select seasons'
    }
  }

  const count = getIncludedItemsCount(
    includeOrExclude,
    items.length,
    selectedItems.length
  )

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h5" component="h2">
          Seasons
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Seasons
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Only SKUs from the included seasons will be considered for
                  recall. Season information comes from{' '}
                  <em>RMIS.dbo.product_master`</em> via <em>j_code_link</em> to{' '}
                  <em>RMIS.dbo.Group_Master.season_year</em>.
                </Typography>
                <Typography variant="body2">
                  The
                  <Label text="label" isSuccess />
                  shows how many seasons are included. At least one must be
                  included.
                </Typography>
              </>
            }
          />
        </Typography>
        {isLoaded && (
          <Box display="flex" alignItems="center">
            <Label
              isError={count === 0}
              isSuccess={count > 0}
              text={`${count.toLocaleString()} / ${items.length}`}
            />
            <MuiLink as="button" onClick={handleDialogOpen}>
              View entire list
            </MuiLink>
          </Box>
        )}
      </Box>
      {isLoaded && (
        <>
          <Box display="flex" alignItems="center" mb={1}>
            <IncludeExcludeSelect
              value={includeOrExclude}
              name="seasonInclude"
              onChange={e => dispatch(setSeasonsIncludeAction(e.target.value))}
            />
            <Typography>seasons</Typography>
          </Box>
          {includeOrExclude !== 'include-all' && (
            <DownshiftMultipleSelect
              id="seasons"
              maxHeight={300}
              placeholder={getPlaceholder()}
              selectedItem={selectedItems}
              setSelectedItem={setSelectedItem}
              suggestions={options}
            />
          )}
          <SeasonsListDialog
            isOpen={isDialogOpen}
            handleClose={handleDialogClose}
          />
        </>
      )}
    </>
  )
}

export default Seasons
