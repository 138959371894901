import * as constants from '../../constants'

export const findStockInitialState = {
  error: false,
  findStockData: null,
  isFindStockLoading: false,
  stockUnitsFound: 0,
  timer: null
}

const reducer = (state = findStockInitialState, action) => {
  switch (action.type) {
    case constants.FIND_STOCK:
      return {
        ...state,
        isFindStockLoading: true,
        findStockData: [],
        stockUnitsFound: 0,
        timer: '0m 0s'
      }
    case constants.FIND_STOCK_TIMER:
      return {
        ...state,
        timer: action.payload
      }
    case constants.POPULATE_FIND_STOCK:
      return {
        ...state,
        isFindStockLoading: false,
        executionTimeMs: action.payload.executionTimeMs,
        findStockData: action.payload.data,
      }
    case constants.RESET_APP:
      return findStockInitialState
    case constants.SET_FIND_STOCK_ERROR:
      return {
        ...state,
        error: action.payload,
        isFindStockLoading: false
      }
    case constants.SET_FIND_STOCK_PROGRESS:
      return {
        ...state,
        stockUnitsFound: action.payload
      }
    default:
      return state
  }
}

export default reducer
