import axios from 'axios'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  error: false,
  isLoaded: false,
  items: [],
}

const FETCH_PRODUCT_GROUPS = 'FETCH_PRODUCT_GROUPS'
const POPULATE_PRODUCT_GROUPS = 'POPULATE_PRODUCT_GROUPS'
const SET_PRODUCT_GROUPS_ERROR = 'SET_PRODUCT_GROUPS_ERROR'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_PRODUCT_GROUPS:
      return {
        ...state,
        error: false,
        items: [],
        isLoaded: false,
      }
    case POPULATE_PRODUCT_GROUPS:
      return {
        ...state,
        items: action.payload,
        isLoaded: true,
      }
    case SET_PRODUCT_GROUPS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true,
      }
    default:
      return state
  }
}

export const fetchProductGroupsAction = () => async dispatch => {
  try {
    dispatch({ type: SET_PRODUCT_GROUPS_ERROR, payload: false })
    dispatch({ type: FETCH_PRODUCT_GROUPS })
    const response = await axios.get('/getProductGroups')
    dispatch({
      type: POPULATE_PRODUCT_GROUPS,
      payload: response.data,
    })
  } catch (e) {
    dispatch({
      type: SET_PRODUCT_GROUPS_ERROR,
      payload: parseError(`fetchProductGroupsAction - ${parseError(e)}`),
    })
  }
}

export default reducer
