import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import SortValue from './SortValue'
import NoPaddingContainer from '../../NoPaddingContainer'
import NavButtons from '../NavButtons'
import CumulativeWeeks from './CumulativeWeeks'
import * as constants from '../../../redux/constants'
import {
  setStepAction,
  setStepCompletedAction,
} from '../../../redux/reducers/criteria/actions'
import { getIncludedItemsCount } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    padding: theme.spacing(2),
  },
}))

const SortingScreen = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    criteria: {
      recallBranches: {
        includeOrExclude: recallBranchesIncludeOrExclude,
        selectedItems: selectedRecallBranches,
      },
      reallocationBranches: {
        includeOrExclude: reallocationBranchesIncludeOrExclude,
        selectedItems: selectedReallocationBranches,
      },
      sortValue,
    },
    data: {
      branches: { isLoaded: isBranchesLoaded, items: branchItems },
    },
  } = useSelector(state => state)

  const recallBranchesCount = getIncludedItemsCount(
    recallBranchesIncludeOrExclude,
    branchItems.length,
    selectedRecallBranches.length
  )
  const reallocationBranchCount = getIncludedItemsCount(
    reallocationBranchesIncludeOrExclude,
    branchItems.length,
    selectedReallocationBranches.length
  )
  const isNextButtonDisabled =
    !sortValue ||
    !isBranchesLoaded ||
    recallBranchesCount === 0 ||
    reallocationBranchCount === 0

  const handleBackButtonClick = useCallback(
    () => dispatch(setStepAction(constants.STEP_SKUS)),
    [dispatch]
  )
  const handleNextButtonClick = useCallback(() => {
    dispatch(setStepCompletedAction(constants.STEP_SORTING, true))
    dispatch(setStepAction(constants.STEP_RECALL_REALLOC))
  }, [dispatch])

  return (
    <NoPaddingContainer maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={3}>
            <CumulativeWeeks />
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={3}>
            <SortValue />
          </Paper>
        </Grid>
      </Grid>

      <NavButtons
        backButtonText="Back"
        handleBack={handleBackButtonClick}
        handleNext={handleNextButtonClick}
        isDisabled={isNextButtonDisabled}
        nextButtonText="Recall"
      />
    </NoPaddingContainer>
  )
}

export default SortingScreen
