import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const SizeGroups = () => {
  const {
    criteria: {
      sizeLevelFilters: { includeOrExclude, selectedItems }
    }
  } = useSelector(state => state)

  return (
    <ListItem dense disableGutters>
      <ListItemText>Size Groups:</ListItemText>
      <ListItemSecondaryAction>
        {includeOrExclude === 'include-all' && 'All included'}
        {includeOrExclude === 'include' && `${selectedItems.length} included`}
        {includeOrExclude === 'exclude' && `${selectedItems.length} excluded`}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default SizeGroups
