import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import { lightGreen } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  list: {
    maxHeight: 600,
    overflow: 'scroll'
  }
}))

const SizeGroupItem = ({
  isChecked,
  isDisabled,
  value,
  label,
  handleChange
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox checked={isChecked} onChange={handleChange} value={value} />
      }
      disabled={isDisabled}
      label={label}
      style={{ background: isChecked ? lightGreen[50] : null }}
    />
  )
}

SizeGroupItem.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.arrayOf(PropTypes.number).isRequired
}

const SizeGroupItems = React.memo(
  ({ groupedSizes, rmisSizeIds, handleItemChange, isDisabled }) => {
    // console.log(rmisSizeIds)
    const classes = useStyles()

    return (
      <Grid container className={classes.list}>
        {Object.keys(groupedSizes)
          .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
          .map(s => {
            const sizeIds = groupedSizes[s].map(s => s.SIZES_ID)
            // console.log(rmisSizeIds, sizeIds)
            const isChecked = rmisSizeIds.includes(sizeIds[0])
            const value = sizeIds
            return (
              <Grid item xs={2} key={s}>
                <SizeGroupItem
                  handleChange={e => handleItemChange(value, e.target.checked)}
                  isChecked={isChecked}
                  isDisabled={isDisabled}
                  value={value}
                  label={s}
                />
              </Grid>
            )
          })}
      </Grid>
    )
  }
)

SizeGroupItems.propTypes = {
  handleItemChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  groupedSizes: PropTypes.object.isRequired,
  rmisSizeIds: PropTypes.arrayOf(PropTypes.number).isRequired
}
SizeGroupItems.defaultProps = {
  isDisabled: false
}

// SizeGroupItems.whyDidYouRender = true
SizeGroupItems.displayName = 'SizeGroupItems'

export default SizeGroupItems
