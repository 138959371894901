import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const useStyles = makeStyles(themes => ({
  capitalize: {
    textTransform: 'capitalize'
  }
}))

const ShopType = () => {
  const classes = useStyles()
  const { shopTypes } = useSelector(state => state.criteria)

  const getShopTypeText = () => {
    return shopTypes.join(', ')
  }

  return (
    <ListItem dense disableGutters>
      <ListItemText>Shop types included:</ListItemText>
      <ListItemSecondaryAction className={classes.capitalize}>
        {getShopTypeText()}
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default ShopType
