import React from 'react'
import PropTypes from 'prop-types'

import RadioButtonsGroup from '../../RadioButtonsGroup'

const Gender = ({ handleChange, options, value }) => (
  <RadioButtonsGroup
    groupName="gender"
    handleChange={handleChange}
    options={options}
    value={value || ''}
  />
)

Gender.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
Gender.defaultProps = {
  value: null,
}

export default Gender
