import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import isEqual from 'lodash/isEqual'

import ConfirmResetAppDialog from '../../ConfirmResetAppDialog'
import * as constants from '../../../redux/constants'
import { criteriaInitialState } from '../../../redux/reducers/criteria'
import {
  setIsDirtyAction,
  setStepAction,
  setStepCompletedAction,
} from '../../../redux/reducers/criteria/actions'
import { resetAppAction } from '../../../redux/reducers/global/actions'

const DefineNewCriteriaButton = () => {
  const dispatch = useDispatch()
  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const criteria = useSelector(state => state.criteria)

  const handleClose = useCallback(() => setConfirmOpen(false), [])
  const handleConfirm = useCallback(() => {
    dispatch(resetAppAction({ activeScreen: constants.SCREEN_CRITERIA }))
    dispatch(setIsDirtyAction(true))
    dispatch(setStepCompletedAction(constants.STEP_START, true))
    dispatch(setStepAction(constants.STEP_FILTERS))
    setConfirmOpen(false)
  }, [dispatch])

  return (
    <>
      <Button
        onClick={() => {
          // if initial criteria has not been changed,
          // there is no need to show the confirm dialog
          if (isEqual(criteriaInitialState, criteria)) {
            dispatch(setIsDirtyAction(true))
            dispatch(setStepCompletedAction(constants.STEP_START, true))
            dispatch(setStepAction(constants.STEP_FILTERS))
          } else {
            setConfirmOpen(true)
          }
        }}
        variant="outlined"
      >
        Define New Criteria
      </Button>
      <ConfirmResetAppDialog
        isOpen={isConfirmOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </>
  )
}

export default DefineNewCriteriaButton
