import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'

import ErrorScreen from '../../ErrorScreen'
import InfoButton from '../../InfoButton'
import SKUCount from './SKUCount'
import { countSKUsAction } from '../../../redux/reducers/data/skus'
import AltcodeLevel from './AltcodeLevel'
import ColourLevel from './ColourLevel'
import SizeLevel from './SizeLevel'

const Header = ({ text }) => (
  <Typography variant="h5" component="h2" gutterBottom>
    {text}
  </Typography>
)

Header.propTypes = {
  text: PropTypes.string.isRequired
}

const useStyles = makeStyles(theme => ({
  grid: {
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    minHeight: 44
  }
}))

const SKUsContainer = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const {
    criteria: { colourLevelFilters, sizeLevelFilters, skuLevelFilters },
    data: {
      skus: { error }
    }
  } = useSelector(state => state)

  const defaultExpanded = []
  const [expanded, setExpanded] = useState(defaultExpanded)
  if (colourLevelFilters.includeOrExclude !== 'include-all') {
    defaultExpanded.push('colour')
  }
  if (sizeLevelFilters.includeOrExclude !== 'include-all') {
    defaultExpanded.push('size')
  }
  if (skuLevelFilters.includeOrExclude !== 'include-all') {
    defaultExpanded.push('altcode')
  }

  const handleChange = panel => (e, isExpanded) => {
    setExpanded(
      isExpanded ? [...expanded, panel] : [...expanded.filter(e => e !== panel)]
    )
  }

  if (error)
    return (
      <>
        <Header text="SKUs" />
        <ErrorScreen
          action={() => dispatch(countSKUsAction())}
          actionButtonText="Try again"
          hideAppHeader
          errors={[error]}
        />
      </>
    )

  return (
    <>
      <Header text="SKUs" />
      <Grid container className={classes.grid}>
        <Grid item>
          <Typography>Based on the filters,</Typography>
        </Grid>
        <Grid item>
          <SKUCount />
        </Grid>
        <Grid item>
          <Typography>
            SKUs from <em>RMIS.dbo.Product_Availability</em> will be analysed for recall. This can be further refined below.
          </Typography>
        </Grid>
      </Grid>

      <Box mb={2}>
        <ExpansionPanel
          expanded={expanded.includes('altcode')}
          onChange={handleChange('altcode')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Filters at altcode or SKU level</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <AltcodeLevel />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded.includes('colour')}
          onChange={handleChange('colour')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" alignItems="center">
              <Typography>
                Filters at generic (web) colour level
                <InfoButton
                  tooltipContent={
                    <>
                      <Typography variant="h6" gutterBottom>
                        Generic Colour
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        Generic Colour is taken from <em>StilettoXT.dbo.COLOURS.GENERICCOLOUR</em>.
                      </Typography>
                      <Typography variant="caption">
                        Note: many SKUs do not have a generic colour set. In this case, we attempt to match by text on the RMIS colour of a SKU.
                      </Typography>
                    </>
                  }
                />
              </Typography>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <ColourLevel />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded.includes('size')}
          // expanded
          onChange={handleChange('size')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Box display="flex" alignItems="center">
              <Typography>
                Filters at size level
                <InfoButton
                  tooltipContent={
                    <>
                      <Typography variant="h6" gutterBottom>
                        Size
                      </Typography>
                      <Typography variant="body2">
                        Sizes are organised in to "size groups". A size group
                        consists of multiple records from{' '}
                        <em>RMIS.dbo.sizes</em>.
                      </Typography>
                    </>
                  }
                />
              </Typography>
            </Box>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SizeLevel />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Box>
    </>
  )
}

export default SKUsContainer
