import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import sortBy from 'lodash/sortBy'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import { lightGreen } from '@material-ui/core/colors'

import RadioButtonsGroup from '../../../RadioButtonsGroup'
import {
  setBranchesAction,
  setBranchesIncludeAction
} from '../../../../redux/reducers/criteria/actions'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontFamily: theme.typography.fontFamily
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Row = ({ data, index, style }) => {
  const [isActive, setActive] = useState(false)
  const item = data[index]
  // console.log(item)

  let background = item.isIncluded ? lightGreen[50] : null
  let opacity = 0.66
  if (isActive) opacity = 1

  return (
    <Box
      alignItems="center"
      display="flex"
      onClick={item.handleClick}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={{
        ...style,
        background,
        cursor: 'pointer',
        opacity,
        padding: 8
      }}
    >
      <Box flex={1}>
        {item.branch_code} | {item.branch_desc}
      </Box>
      <Box>
        <Checkbox checked={item.isIncluded} />
      </Box>
    </Box>
  )
}
Row.propTypes = {
  data: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired
}

const ReallocationBranchesListDialog = React.memo(({ handleClose, isOpen }) => {
  const classes = useStyles()
  const {
    criteria: {
      reallocationBranches: { includeOrExclude, selectedItems }
    },
    data: {
      branches: { isLoaded, items }
    }
  } = useSelector(state => state)
  const [includedFilter, setIncludedFilter] = useState('all')
  const [filter, setFilter] = useState('')
  const dispatch = useDispatch()

  // console.log(items, selectedItems)

  const onClose = () => {
    handleClose()
    setFilter('')
  }

  const createItemData = useCallback(
    (items, filter, includeOrExclude, selectedItems, toggleItem) => {
      const filteredItems = items.filter(i => {
        if (filter.length < 2) return true
        return (
          i.branch_desc.toLowerCase().indexOf(filter) > -1 ||
          i.branch_code.indexOf(filter) > -1
        )
      })

      // console.log(filteredItems)

      const sortedItems = sortBy(filteredItems, 'branch_desc').map(i => {
        return {
          ...i,
          handleClick: () => toggleItem(i)
        }
      })

      // console.log(filteredItems[0], selectedItems)

      const out = sortedItems.map(i => {
        let isIncluded = false
        if (includeOrExclude === 'include-all') {
          isIncluded = true
        } else if (includeOrExclude === 'include') {
          isIncluded = selectedItems
            .map(i => parseInt(i.value, 10))
            .includes(parseInt(i.branch_id, 10))
        } else if (includeOrExclude === 'exclude') {
          isIncluded = !selectedItems
            .map(i => parseInt(i.value, 10))
            .includes(parseInt(i.branch_id, 10))
        }

        return {
          ...i,
          isIncluded
        }
      })

      return out
    },
    []
  )

  const toggleItem = useCallback(
    item => {
      // console.log(item)
      if (includeOrExclude === 'include-all') {
        dispatch(
          setBranchesAction('reallocation', [
            { label: item.branch_desc, value: parseInt(item.branch_id, 10) }
          ])
        )
        dispatch(setBranchesIncludeAction('reallocation', 'exclude'))
      } else {
        if (
          !selectedItems
            .map(i => i.value)
            .includes(parseInt(item.branch_id, 10))
        ) {
          dispatch(
            setBranchesAction('reallocation', [
              ...selectedItems,
              { label: item.branch_desc, value: parseInt(item.branch_id, 10) }
            ])
          )
        } else {
          dispatch(
            setBranchesAction(
              'reallocation',
              selectedItems.filter(
                i => parseInt(i.value, 10) !== parseInt(item.branch_id, 10)
              )
            )
          )
        }
      }
    },
    [dispatch, includeOrExclude, selectedItems]
  )

  const sortedItems = createItemData(
    items,
    filter,
    includeOrExclude,
    selectedItems,
    toggleItem
  )

  const filteredItems = sortedItems.filter(i => {
    if (includedFilter === 'not-included') return !i.isIncluded
    if (includedFilter === 'included') return i.isIncluded
    return i
  })

  const handleFilterChange = e => setFilter(e.target.value)
  const handleRadioChange = e => setIncludedFilter(e.target.value)

  if (!isLoaded) return null

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      scroll="paper"
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Reallocation Branches
          </Typography>
          <Button autoFocus color="inherit" onClick={onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box display="flex" height="100%" width="100%" flexDirection="column">
          <Box
            my={2}
            className={classes.filter}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              value={filter}
              onChange={handleFilterChange}
              placeholder="Filter"
            />
            <RadioButtonsGroup
              groupName="included"
              options={[
                { label: 'All', value: 'all' },
                {
                  label: (
                    <span style={{ background: lightGreen[50] }}>Included</span>
                  ),
                  value: 'included'
                },
                { label: 'Not included', value: 'not-included' }
              ]}
              value={includedFilter}
              handleChange={handleRadioChange}
            />
          </Box>
          <Box flex={1}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  className="List"
                  height={height}
                  itemCount={filteredItems.length}
                  itemData={filteredItems}
                  itemSize={40}
                  width={width}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
})

ReallocationBranchesListDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

ReallocationBranchesListDialog.displayName = 'ReallocationBranchesListDialog'
// ReallocationBranchesListDialog.whyDidYouRender = true

export default ReallocationBranchesListDialog
