import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import ConfirmDeleteSizeGroupDialog from './ConfirmDeleteSizeGroupDialog'

const useStyles = makeStyles(theme => {
  return {
    link: {
      color: theme.palette.error.main
    }
  }
})

const DeleteSizeGroup = ({ handleDelete }) => {
  const classes = useStyles()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const handleCloseDialog = () => setDialogOpen(false)
  const handleOpenDialog = () => setDialogOpen(true)

  return (
    <>
      <MuiLink as="button" onClick={handleOpenDialog} className={classes.link}>
        Delete this size group
      </MuiLink>
      <ConfirmDeleteSizeGroupDialog
        isOpen={isDialogOpen}
        handleClose={handleCloseDialog}
        handleConfirm={() => {
          handleDelete()
          handleCloseDialog()
        }}
      />
    </>
  )
}

DeleteSizeGroup.propTypes = {
  handleDelete: PropTypes.func.isRequired
}

export default DeleteSizeGroup
