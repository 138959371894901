import React from 'react'
import PropTypes from 'prop-types'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

const SizeGroupsList = ({
  sizeGroups,
  selectedSizeGroupId,
  handleSizeGroupClick
}) => {
  return (
    <List component="nav">
      {sizeGroups.map(i => {
        return (
          <ListItem
            key={i.sizeGroupId}
            button
            selected={selectedSizeGroupId === i.sizeGroupId}
            onClick={() => handleSizeGroupClick(i.sizeGroupId)}
          >
            <ListItemText primary={i.title} />
          </ListItem>
        )
      })}
    </List>
  )
}

SizeGroupsList.propTypes = {
  sizeGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedSizeGroupId: PropTypes.number,
  handleSizeGroupClick: PropTypes.func.isRequired
}
SizeGroupsList.defaultProps = {
  selectedSizeGroupId: null
}

export default SizeGroupsList
