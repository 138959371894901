import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { version } from '../../package.json'

const AppInfo = () => {
  return (
    <Box
      borderTop="1px solid #d3d3d3"
      bottom={0}
      display="flex"
      fontSize="75%"
      justifyContent="flex-end"
      left={0}
      padding={0.5}
      position="fixed"
      style={{
        backgroundColor: '#eee',
        opacity: 0.33,
        zIndex: 1
      }}
      width="100%"
    >
      <Typography variant="caption" style={{ color: '#212121' }}>
        version: {version} | REACT_APP_ENV:{' '}
        {process.env.REACT_APP_ENV} | NODE_ENV: {process.env.NODE_ENV} |
        REACT_APP_API_BASE_URL: {process.env.REACT_APP_API_BASE_URL}
      </Typography>
    </Box>
  )
}

export default AppInfo
