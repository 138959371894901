import React, { useReducer, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import { CSVDownload } from 'react-csv'
import axios from 'axios'

import { parseError } from '../../../helpers'
import ItemsByBranchTable from './ItemsByBranchTable'
import ItemsBySKUTable from './ItemsBySKUTable'
import RadioButtonsGroup from '../../RadioButtonsGroup'
import reducer, { initialState, constants } from '../reducer'
import useDebounce from '../../../useDebounce'

const groupByOptions = [
  { label: 'By SKU', value: 'sku' },
  { label: 'By branch', value: 'branch' },
]

const RecallsTab = ({ filters, recallId }) => {
  const [state, dispatch] = useReducer(reducer, initialState('pull', 'desc'))
  const {
    branchFilter,
    data,
    error,
    isLoading,
    groupTableBy,
    pageIndex,
    pageSize,
    shouldUpdate,
    skuFilter,
    sortBy,
    sortDirection,
    totalItems,
  } = state
  const [csvData, setCsvData] = useState(false)

  const setPageIndex = useCallback(
    value => dispatch({ type: constants.SET_PAGE_INDEX, payload: value }),
    []
  )
  const setPageSize = useCallback(
    value => dispatch({ type: constants.SET_PAGE_SIZE, payload: value }),
    []
  )

  // third argument is callback, reset page to 0 after the filter changes
  const debouncedSKUFilter = useDebounce(
    skuFilter,
    300, // ms
    useCallback(() => {
      setPageIndex(0)
    }, [setPageIndex])
  )

  // third argument is callback, reset page to 0 after the filter changes
  const debouncedBranchFilter = useDebounce(
    branchFilter,
    300, // ms
    useCallback(() => {
      setPageIndex(0)
    }, [setPageIndex])
  )

  const makeRequest = useCallback(
    async (_groupTableBy, _pageIndex, _pageSize, sortBy, sortDirection) => {
      // console.log('request', debouncedSKUFilter)
      try {
        let url
        switch (_groupTableBy) {
          case 'sku':
            url = `/getRecallItemsBySKU/${recallId}?page=${_pageIndex +
              1}&perPage=${_pageSize}&skuFilter=${debouncedSKUFilter}&sortBy=${sortBy}&sortDirection=${sortDirection}`
            break
          case 'branch':
            url = `/getRecallItemsByBranch/${recallId}?page=${_pageIndex +
              1}&perPage=${_pageSize}&branchFilter=${debouncedBranchFilter}&sortBy=${sortBy}&sortDirection=${sortDirection}`
            break
          default:
            url = `/getRecallItemsBySKU/${recallId}?page=${_pageIndex +
              1}&perPage=${_pageSize}&skuFilter=${debouncedSKUFilter}&sortBy=${sortBy}&sortDirection=${sortDirection}`
        }

        const response = await axios.get(url)
        // console.log(response.data)
        return response
      } catch (e) {
        throw new Error(e)
      }
    },
    [recallId, debouncedSKUFilter, debouncedBranchFilter]
  )

  useEffect(() => {
    if (!shouldUpdate) return
    let didCancel = false

    const fetchData = async () => {
      try {
        dispatch({ type: constants.SET_LOADING, payload: true })
        const response = await makeRequest(
          groupTableBy,
          pageIndex,
          pageSize,
          sortBy,
          sortDirection
        )

        if (didCancel) return
        dispatch({
          type: constants.POPULATE_DATA,
          payload: {
            data: response.data.items,
            totalItems: response.data.totalItems,
          },
        })
      } catch (e) {
        if (didCancel) return
        dispatch({ type: constants.SET_ERROR, payload: parseError(e) })
      }
    }

    fetchData()

    return () => {
      didCancel = false
    }
  }, [
    makeRequest,
    groupTableBy,
    pageIndex,
    pageSize,
    shouldUpdate,
    sortBy,
    sortDirection,
  ])

  const makeCsvData = useCallback(async () => {
    const response = await makeRequest(groupTableBy, 0, 999999)

    const expandedData = []
    response.data.items.forEach(row => {
      row.subRows.forEach(subRow => {
        const rowData = { ...row }
        delete rowData.subRows
        expandedData.push({
          ...rowData,
          ...subRow,
        })
      })
    })

    setCsvData(expandedData)
  }, [groupTableBy, makeRequest])

  const handleSort = useCallback(
    sortBy => {
      dispatch({
        type: constants.SET_SORTING,
        payload: {
          sortBy,
          sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
        },
      })
    },
    [sortDirection, dispatch]
  )

  const setBranchFilter = useCallback(
    value => dispatch({ type: constants.SET_BRANCH_FILTER, payload: value }),
    [dispatch]
  )

  const setSKUFilter = useCallback(
    value => dispatch({ type: constants.SET_SKU_FILTER, payload: value }),
    [dispatch]
  )

  const handleGroupByChange = useCallback(
    e => {
      setCsvData(false)
      dispatch({ type: constants.SET_GROUP_BY, payload: e.target.value })
    },
    [dispatch]
  )

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <RadioButtonsGroup
          groupName="recall-grouping"
          options={groupByOptions}
          value={groupTableBy}
          handleChange={handleGroupByChange}
        />
        <Link onClick={makeCsvData} variant="body1" href="#">
          Download CSV file
        </Link>
        {csvData !== false && <CSVDownload data={csvData} target="_blank" />}
      </Box>
      {groupTableBy === 'sku' && (
        <ItemsBySKUTable
          data={data}
          error={error}
          filters={filters}
          handleSort={handleSort}
          isLoading={isLoading}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          setSKUFilter={setSKUFilter}
          sortBy={sortBy}
          sortDirection={sortDirection}
          skuFilter={skuFilter}
          totalItems={totalItems}
        />
      )}
      {groupTableBy === 'branch' && (
        <ItemsByBranchTable
          branchFilter={branchFilter}
          data={data}
          error={error}
          filters={filters}
          handleSort={handleSort}
          isLoading={isLoading}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setBranchFilter={setBranchFilter}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          sortBy={sortBy}
          sortDirection={sortDirection}
          totalItems={totalItems}
        />
      )}
    </>
  )
}

RecallsTab.propTypes = {
  filters: PropTypes.object.isRequired,
  recallId: PropTypes.string.isRequired,
}

export default RecallsTab
