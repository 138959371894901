import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { green, red, grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  label: {
    backgroundColor: grey[400],
    borderRadius: '0.25em',
    color: '#fff',
    display: 'inline-block',
    fontWeight: 700,
    lineHeight: 1,
    margin: theme.spacing(1),
    padding: '0.3em 0.6em',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  greenLabel: {
    backgroundColor: green[500],
  },
  redLabel: {
    backgroundColor: red[900],
  },
}))

const Label = ({ isSuccess, isError, text }) => {
  const classes = useStyles()

  return (
    <span
      className={clsx(
        classes.label,
        { [classes.redLabel]: isError },
        { [classes.greenLabel]: isSuccess }
      )}
    >
      {text}
    </span>
  )
}

Label.propTypes = {
  isError: PropTypes.bool,
  isSuccess: PropTypes.bool,
  text: PropTypes.string,
}
Label.defaultProps = {
  isError: false,
  isSuccess: false,
  text: '',
}

export default Label
