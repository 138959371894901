import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import marked from 'marked'

const DocsScreen = () => {
  const [markdown, setMarkdown] = useState(null)

  useEffect(() => {
    const file = require('../../docs/index.md')
    fetch(file)
      .then(response => response.text())
      .then(text => setMarkdown(marked(text)))
  }, [])

  return (
    <Container maxWidth="lg">
      {markdown ? (
        <Box mt={2}>
          {<article dangerouslySetInnerHTML={{ __html: markdown }} />}
        </Box>
      ) : (
        <></>
      )}
    </Container>
  )
}

export default DocsScreen
