import React from 'react'
import Typography from '@material-ui/core/Typography'

import Screen from './Screen'

const Page404 = () => {
  return (
    <Screen>
      <Typography variant="h4" component="h2" gutterBottom>
        404
      </Typography>
      <Typography>Not found</Typography>
    </Screen>
  )
}

export default Page404
