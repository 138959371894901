import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import InfoButton from '../../InfoButton'
import {
  setMinMarginAction,
  setMinMarginExcludedAction
} from '../../../redux/reducers/criteria/actions'

const marks = [
  { value: 0, label: '0%' },
  { value: 25, label: '25%' },
  { value: 50, label: '50%' },
  { value: 75, label: '75%' },
  { value: 100, label: '100%' }
]

const MinimumMargin = () => {
  const dispatch = useDispatch()
  const { minMargin, minMarginExcluded } = useSelector(state => state.criteria)
  const [localValue, setLocalValue] = useState(minMargin)

  const handleExcludeChecked = e => {
    dispatch(setMinMarginExcludedAction(e.target.checked))
  }

  return (
    <>
      <Box display="flex">
        <Typography id="minimum-margin">
          Minimum margin
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Minimum margin
                </Typography>
                <Typography variant="body2">
                  A SKU will only be considered for recall if its current margin
                  is greater than or equal to this value.
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <Slider
        aria-labelledby="minimum-margin"
        value={localValue}
        disabled={minMarginExcluded}
        getAriaValueText={v => v.toString()}
        marks={marks}
        max={100}
        min={0}
        onChange={(_, value) => setLocalValue(value)}
        onChangeCommitted={() => dispatch(setMinMarginAction(localValue))}
        step={1}
        valueLabelDisplay="auto"
        valueLabelFormat={x => `${x.toString()}%`}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={minMarginExcluded}
            onChange={handleExcludeChecked}
          />
        }
        label="Exclude this parameter"
      />
    </>
  )
}

export default MinimumMargin
