import React, { useEffect, useCallback, useState, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import useComponentSize from '@rehooks/component-size'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import { loadCSS } from 'fg-loadcss'
import { AuthenticatedComponent } from '@paversltd/react-stiletto-auth'
import {
  AppSidebar,
  StilettoToolbar,
  LoginForm
} from '@paversltd/react-stiletto-components'
import { SnackbarProvider } from 'notistack'
import { green } from '@material-ui/core/colors'
import { withSnackbar } from 'notistack'
import Divider from '@material-ui/core/Divider'
import Icon from '@material-ui/core/Icon'
import isEqual from 'lodash/isEqual'
import axios from 'axios'

import AppHeader from './components/AppHeader'
import ErrorScreen from './components/ErrorScreen'
import FindStockLoader from './components/FindStockLoader'
import IssuingRecallLoader from './components/IssuingRecallLoader'
import LoadingScreen from './components/LoadingScreen'
import Routes from './Routes'
import * as constants from './redux/constants'
import {
  resetAppAction,
  setSidebarOpenAction,
  setActiveScreenAction,
  setActiveRecallIdAction
} from './redux/reducers/global/actions'
import { fetchInitialDataAction } from './redux/reducers/data'
import useWebSocket from './useWebSocket'
import { getAppBarHeight } from './helpers'
import { version } from '../package.json'

import '@paversltd/react-stiletto-components/fonts/fonts.css'

// second argument for useMemo, prevents rerendering on new auth props object
const compareAuthProps = (prevProps, nextProps) => {
  if (isEqual(prevProps.auth, nextProps.auth)) return true
  return false
}

const useStyles = makeStyles(theme => {
  return {
    appBar: {
      borderTop: `4px solid ${green[100]}`
    },
    icon: {
      width: 32
    },
    typography: {
      fontFamily: theme.typography.fontFamily
    }
  }
})

const App = React.memo(({ auth, history, enqueueSnackbar }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const state = useSelector(state => state)
  const {
    criteria: { isDirty },
    global: { isSidebarOpen, theme, activeScreen, activeRecallId },
    data: {
      branches: { error: branchesError },
      productGroups: { error: productGroupsError },
      colours: { error: coloursError },
      genders: { error: gendersError },
      seasons: { error: seasonsError },
      shopTypes: { error: shopTypesError },
      sizes: { error: sizesError },
      suppliers: { error: suppliersError }
    },
    findStock: { isFindStockLoading, findStockData },
    recall: { isIssuingRecall }
  } = state
  const isInitialDataLoaded = useSelector(state => {
    const {
      branches,
      colours,
      genders,
      productGroups,
      seasons,
      shopTypes,
      sizes,
      suppliers
    } = state.data
    return (
      branches.isLoaded &&
      colours.isLoaded &&
      genders.isLoaded &&
      productGroups.isLoaded &&
      seasons.isLoaded &&
      shopTypes.isLoaded &&
      sizes.isLoaded &&
      suppliers.isLoaded
    )
  })

  useEffect(() => {
    // send with every request
    axios.defaults.headers.common['x-jwt-token'] =
      sessionStorage.getItem('stiletto.accessToken') || ''
  }, [])

  useEffect(() => {
    if (history.location.pathname === '/' && !activeScreen) {
      // handles browser back button navigation
      dispatch(setActiveScreenAction(constants.SCREEN_HOME))
    }
  }, [history.location.pathname, dispatch, activeScreen])

  const [appbarRef, setAppbarRef] = useState(useRef(null)) // we have to pass an empty ref in here else useComponentSize will throw

  // Dynamically get the height of the StilettoToolbar (it can vary according to theme), so we can offset the main content by the correct amount.
  const cbSetAppbarRef = useCallback(() => {
    setAppbarRef(appbarRef)
  }, [appbarRef])
  useEffect(cbSetAppbarRef, [cbSetAppbarRef])
  const size = useComponentSize(appbarRef)
  const toolbarHeight = size.height

  useWebSocket(enqueueSnackbar) // Handle web socket messages (custom hook)

  // Load font awesome CSS: https://material-ui.com/components/icons/#FontAwesome.js
  useEffect(() => {
    let didCancel = false
    if (!didCancel) {
      loadCSS(
        'https://use.fontawesome.com/releases/v5.1.0/css/all.css',
        document.querySelector('#font-awesome-css')
      )
    }
    return () => {
      didCancel = true
    }
  }, [])

  // Load data to populate the criteria controls
  const fetchInitialData = useCallback(() => {
    if (!auth.accessToken) return // don't refetch if have just logged out
    dispatch(fetchInitialDataAction())
  }, [dispatch, auth.accessToken])
  useEffect(() => {
    let didCancel = false
    if (!didCancel) {
      fetchInitialData()
    }
    return () => {
      didCancel = true
    }
  }, [fetchInitialData])

  const getAppbarRef = useCallback(ref => setAppbarRef(ref), [])
  const onSettingsClick = useCallback(() => history.push('/settings'), [
    history
  ])
  const handleSidebarClose = useCallback(
    () => dispatch(setSidebarOpenAction(false)),
    [dispatch]
  )
  const handleSidebarOpen = useCallback(
    () => dispatch(setSidebarOpenAction(true)),
    [dispatch]
  )

  const handleHomeClick = useCallback(() => {
    // clear the active recall id when navigate to home screen.
    dispatch(setActiveRecallIdAction(null))
    history.push('/')
    dispatch(setActiveScreenAction(constants.SCREEN_HOME))
  }, [dispatch, history])

  const handleCriteriaClick = useCallback(() => {
    // clear the active recall id when navigate to home screen.
    dispatch(setActiveRecallIdAction(null))
    history.push('/')
    dispatch(setActiveScreenAction(constants.SCREEN_CRITERIA))
  }, [dispatch, history])

  const handleSKUsClick = useCallback(() => {
    // clear the active recall id when navigate to home screen.
    dispatch(setActiveRecallIdAction(null))
    history.push('/')
    dispatch(setActiveScreenAction(constants.SCREEN_SKUS))
  }, [dispatch, history])

  const handleViewRecallClick = useCallback(
    () => history.push(`/recall/${state.global.activeRecallId}`),
    [history, state.global.activeRecallId]
  )

  const handleDocsClick = useCallback(() => history.push('/docs'), [history])

  const handleSettingsClick = useCallback(() => history.push('/settings'), [
    history
  ])

  const sidebarItems = useMemo(() => {
    return [
      [
        {
          id: 'home',
          title: 'Home',
          tooltip: 'Home',
          handleClick: handleHomeClick,
          icon: (
            <Icon className="fa fa-home" classes={{ root: classes.icon }} />
          ),
          isDisabled: false,
          isHidden: false,
          isSelected:
            activeScreen === constants.SCREEN_HOME &&
            !activeRecallId &&
            history.location.pathname === '/'
        },

        {
          id: 'new',
          title: 'New Recall',
          defaultExpanded: true,
          icon: (
            <Icon
              className="fa fa-truck-loading"
              classes={{ root: classes.icon }}
            />
          ),
          isHidden: !(
            isDirty ||
            (!isDirty && activeScreen === constants.SCREEN_CRITERIA)
          ),
          items: [
            {
              id: 'criteria',
              title: 'Criteria',
              tooltip: 'Criteria',
              handleClick: handleCriteriaClick,
              icon: (
                <Icon
                  className="fa fa-sliders-h"
                  classes={{ root: classes.icon }}
                />
              ),
              isDisabled: false,
              isSelected:
                activeScreen === constants.SCREEN_CRITERIA &&
                !activeRecallId &&
                history.location.pathname.indexOf('/settings') === -1 &&
                history.location.pathname.indexOf('/docs') === -1
            },
            {
              id: 'skus',
              title: 'SKUs',
              tooltip: 'SKUs',
              handleClick: handleSKUsClick,
              icon: (
                <Icon
                  className="fa fa-shoe-prints"
                  classes={{ root: classes.icon }}
                />
              ),
              isDisabled: false,
              isHidden:
                !findStockData ||
                findStockData.length === 0 ||
                isFindStockLoading,
              isSelected:
                activeScreen === constants.SCREEN_SKUS &&
                history.location.pathname.indexOf('/settings') === -1 &&
                history.location.pathname.indexOf('/docs') === -1
            }
          ]
        },

        {
          id: 'view-recall',
          title: 'View Recall',
          tooltip: 'View Recall',
          handleClick: handleViewRecallClick,
          icon: (
            <Icon className="fa fa-truck" classes={{ root: classes.icon }} />
          ),
          isDisabled: false,
          isHidden: !activeRecallId,
          isSelected:
            history.location.pathname !== '/recall' &&
            history.location.pathname.indexOf('/settings') === -1 &&
            history.location.pathname.indexOf('/docs') === -1
        }
      ],
      <div style={{ flexGrow: 1 }} />,
      <Divider />,
      [
        {
          id: 'docs',
          title: 'Documentation',
          tooltip: 'Documentation',
          handleClick: handleDocsClick,
          icon: (
            <Icon className="fa fa-question" classes={{ root: classes.icon }} />
          ),
          isDisabled: false,
          isSelected: history.location.pathname === '/docs'
        },
        {
          id: 'settings',
          title: 'Settings',
          tooltip: 'Settings',
          handleClick: handleSettingsClick,
          icon: <Icon className="fa fa-cog" />,
          isDisabled: false,
          isSelected: history.location.pathname === '/settings'
        }
      ]
    ]
  }, [
    activeRecallId,
    activeScreen,
    classes,
    findStockData,
    handleCriteriaClick,
    handleDocsClick,
    handleHomeClick,
    handleSKUsClick,
    handleSettingsClick,
    handleViewRecallClick,
    history.location.pathname,
    isDirty,
    isFindStockLoading
  ])

  // Any errors loading initial data?
  const allErrors = useMemo(
    () =>
      [
        branchesError,
        coloursError,
        gendersError,
        productGroupsError,
        shopTypesError,
        seasonsError,
        sizesError,
        suppliersError
      ].filter(e => !!e),
    [
      branchesError,
      coloursError,
      gendersError,
      productGroupsError,
      shopTypesError,
      seasonsError,
      sizesError,
      suppliersError
    ]
  )

  if (allErrors.length > 0) {
    return (
      <ErrorScreen
        action={fetchInitialData}
        actionButtonText="Try again"
        errors={allErrors}
      />
    )
  }

  // Loading screens
  if (!isInitialDataLoaded) {
    return (
      <LoadingScreen>
        <Typography>Loading initial data...</Typography>
      </LoadingScreen>
    )
  }

  if (isIssuingRecall) {
    return <IssuingRecallLoader />
  }

  if (isFindStockLoading) {
    return <FindStockLoader />
  }

  let username = ''
  if (auth && auth.user) username = auth.user.first_name || auth.username

  // The actual app
  return (
    <Box minHeight="100vh" display="flex">
      <StilettoToolbar
        appBarClasses={classes.appBar}
        auth={auth}
        getAppbarRef={getAppbarRef}
        hideHomeIcon
        onSettingsClick={onSettingsClick}
        title={
          <Box display="flex">
            <Typography
              variant="h6"
              color="inherit"
              className={classes.typography}
            >
              {process.env.REACT_APP_TITLE} v{version}
            </Typography>
            <Typography
              color="inherit"
              className={classes.typography}
              variant="body2"
            >
              <sup>BETA</sup>
            </Typography>
          </Box>
        }
        username={username}
      />
      <Box display="flex" mt={`${getAppBarHeight(theme)}px`} width="100%">
        <AppSidebar
          defaultOpen={isSidebarOpen}
          drawerOpenWidth={260}
          items={sidebarItems}
          onClose={handleSidebarClose}
          onOpen={handleSidebarOpen}
          top={toolbarHeight}
        />
        <Routes />
      </Box>
    </Box>
  )
}, compareAuthProps)

App.propTypes = {
  auth: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

App.displayName = 'App'
// App.whyDidYouRender = true

const EnhancedApp = React.memo(withSnackbar(App), compareAuthProps)
EnhancedApp.displayName = 'EnhancedApp'
// EnhancedApp.whyDidYouRender = true

const AppWithSnackbarProvider = React.memo(props => {
  const notistackRef = React.createRef()
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      action={key => (
        <IconButton aria-label="close" onClick={onClickDismiss(key)}>
          <CloseIcon />
        </IconButton>
      )}
      autoHideDuration={3000}
      ref={notistackRef}
    >
      <EnhancedApp {...props} />
    </SnackbarProvider>
  )
}, compareAuthProps)

AppWithSnackbarProvider.displayName = 'AppWithSnackbarProvider'
// AppWithSnackbarProvider.whyDidYouRender = true

const LoginWrapper = React.memo(
  props => (
    <Box display="flex" flexDirection="column" alignItems="center" p={4}>
      <AppHeader />
      <LoginForm {...props} />
    </Box>
  ),
  compareAuthProps
)

LoginWrapper.displayName = 'LoginWrapper'
// LoginWrapper.whyDidYouRender = true

const AppWithAuth = React.memo(({ history }) => {
  const dispatch = useDispatch()

  const renderAuth = useCallback(
    auth => {
      // console.log(auth)
      return <AppWithSnackbarProvider auth={auth} history={history} />
    },
    [history]
  )

  const onLogout = useCallback(() => dispatch(resetAppAction()), [dispatch])

  return (
    <AuthenticatedComponent
      blockedComponent={<LoginWrapper />}
      onLogout={onLogout}
      render={renderAuth}
      requiredPermissions="RECALL_V3"
    />
  )
})

AppWithAuth.propTypes = {
  history: PropTypes.object.isRequired // from react-router
}

AppWithAuth.displayName = 'AppWithAuth'
// AppWithAuth.whyDidYouRender = true

export default withRouter(AppWithAuth)
