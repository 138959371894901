import React from 'react'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'

const TableLoader = () => {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="33%"
      justifyContent="center"
      position="absolute"
      width="100%"
    >
      <CircularProgress />
    </Box>
  )
}

export default TableLoader
