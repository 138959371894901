import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import sortBy from 'lodash/sortBy'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import TextField from '@material-ui/core/TextField'
import { lightGreen } from '@material-ui/core/colors'
import uniqBy from 'lodash/uniqBy'

import {
  setProductGroupsAction,
  setProductGroupsIncludeAction
} from '../../../../redux/reducers/criteria/actions'
import {
  filterProductGroupsBySeasons,
  filterProductGroupsByGender
} from './index'
import RadioButtonsGroup from '../../../RadioButtonsGroup'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontFamily: theme.typography.fontFamily
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Row = ({ data, index, style }) => {
  const [isActive, setActive] = useState(false)
  const item = data[index]

  let background = item.isIncluded ? lightGreen[50] : null
  let opacity = 0.66
  if (isActive) opacity = 1

  return (
    <Box
      alignItems="center"
      display="flex"
      onClick={item.handleClick}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      style={{
        ...style,
        background,
        cursor: 'pointer',
        opacity,
        padding: 8
      }}
    >
      <Box flex={1}>{item.description}</Box>
      <Box>
        <Checkbox checked={item.isIncluded} />
      </Box>
    </Box>
  )
}
Row.propTypes = {
  data: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired
}

const ProductGroupsListDialog = React.memo(({ handleClose, isOpen }) => {
  const classes = useStyles()
  const {
    criteria: {
      gender: selectedGender,
      productGroups: { includeOrExclude, selectedItems },
      seasons: {
        includeOrExclude: seasonsInclude,
        selectedItems: selectedSeasonItems
      }
    },
    data: {
      productGroups: { isLoaded, items },
      seasons: { items: allSeasonItems }
    }
  } = useSelector(state => state)

  // console.log(items)

  const [filter, setFilter] = useState('')
  const [includedFilter, setIncludedFilter] = useState('all')
  const dispatch = useDispatch()

  const onClose = () => {
    handleClose()
    setFilter('')
  }

  let selectedSeasons = useMemo(() => {
    switch (seasonsInclude) {
      case 'include-all':
        return allSeasonItems.map(s => s.season_year)
      case 'include':
        return selectedSeasonItems.map(s => s.value)
      case 'exclude':
        return allSeasonItems
          .map(s => s.season_year)
          .filter(s => !selectedSeasonItems.map(s => s.value).includes(s))
      default:
        return []
    }
  }, [allSeasonItems, seasonsInclude, selectedSeasonItems])

  const createItemData = useCallback(
    (items, filter, includeOrExclude, selectedItems, toggleItem) => {
      const filteredBySeasons = filterProductGroupsBySeasons(
        selectedSeasons,
        items
      )

      const filteredByGender = uniqBy(
        filterProductGroupsByGender(selectedGender, filteredBySeasons),
        'description'
      )

      const filteredItems = filteredByGender.filter(i => {
        if (filter.length < 3) return true
        return i.description.toLowerCase().indexOf(filter) > -1
      })

      const sortedItems = sortBy(filteredItems, 'description').map(i => {
        return {
          ...i,
          handleClick: () => toggleItem(i)
        }
      })

      // console.log(sortedItems[0], selectedItems)

      const out = sortedItems.map(i => {
        let isIncluded = false
        if (includeOrExclude === 'include-all') {
          isIncluded = true
        } else if (includeOrExclude === 'include') {
          isIncluded = selectedItems.map(ii => ii.value).includes(i.description)
        } else if (includeOrExclude === 'exclude') {
          isIncluded = !selectedItems
            .map(ii => ii.value)
            .includes(i.description)
        }

        return {
          ...i,
          isIncluded
        }
      })

      return out
    },
    [selectedSeasons, selectedGender]
  )

  const toggleItem = useCallback(
    item => {
      // console.log(item)
      if (includeOrExclude === 'include-all') {
        dispatch(
          setProductGroupsAction([
            { label: item.description, value: item.description }
          ])
        )
        dispatch(setProductGroupsIncludeAction('exclude'))
      } else {
        if (!selectedItems.map(ii => ii.value).includes(item.description)) {
          dispatch(
            setProductGroupsAction([
              ...selectedItems,
              { label: item.description, value: item.description }
            ])
          )
        } else {
          dispatch(
            setProductGroupsAction(
              selectedItems.filter(i => i.value !== item.description)
            )
          )
        }
      }
    },
    [dispatch, includeOrExclude, selectedItems]
  )

  const sortedItems = createItemData(
    items,
    filter,
    includeOrExclude,
    selectedItems,
    toggleItem
  )

  const filteredItems = sortedItems.filter(i => {
    if (includedFilter === 'not-included') return !i.isIncluded
    if (includedFilter === 'included') return i.isIncluded
    return i
  })

  const handleFilterChange = e => setFilter(e.target.value)
  const handleRadioChange = e => setIncludedFilter(e.target.value)

  if (!isLoaded) return null

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullScreen
      scroll="paper"
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Product Groups
          </Typography>
          <Button autoFocus color="inherit" onClick={onClose}>
            Close
          </Button>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box display="flex" height="100%" width="100%" flexDirection="column">
          <Box mt={2}>
            <Typography>Only product groups with at least one associated SKU in <em>RMIS.dbo.Product_availability</em> can be selected.</Typography>
          </Box>
          <Box
            my={2}
            className={classes.filter}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              value={filter}
              onChange={handleFilterChange}
              placeholder="Filter"
            />
            <RadioButtonsGroup
              groupName="included"
              options={[
                { label: 'All', value: 'all' },
                {
                  label: (
                    <span style={{ background: lightGreen[50] }}>Included</span>
                  ),
                  value: 'included'
                },
                { label: 'Not included', value: 'not-included' }
              ]}
              value={includedFilter}
              handleChange={handleRadioChange}
            />
          </Box>
          <Box flex={1}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  className="List"
                  height={height}
                  itemCount={filteredItems.length}
                  itemData={filteredItems}
                  itemSize={40}
                  width={width}
                >
                  {Row}
                </FixedSizeList>
              )}
            </AutoSizer>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
})

ProductGroupsListDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

ProductGroupsListDialog.displayName = 'ProductGroupsListDialog'
// ProductGroupsListDialog.whyDidYouRender = true

export default ProductGroupsListDialog
