import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useDispatch, useSelector } from 'react-redux'

import RadioButtonsBroup from '../RadioButtonsGroup'
import {
  setThemeAction,
  setThemeTypeAction,
} from '../../redux/reducers/global/actions'

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(2),
  },
}))

const Theme = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { theme, themeType } = useSelector(state => state.global)

  return (
    <>
      <Box className={classes.container}>
        <RadioButtonsBroup
          groupName="theme"
          handleChange={e => dispatch(setThemeAction(e.target.value))}
          legend="Theme"
          value={theme}
          options={[
            {
              label: 'Pavers',
              value: 'pavers',
            },
            {
              label: 'Jones',
              value: 'jones',
            },
            {
              label: 'MUI default',
              value: 'mui',
            },
          ]}
        />
      </Box>
      <Box className={classes.container}>
        <RadioButtonsBroup
          groupName="themeType"
          handleChange={e => dispatch(setThemeTypeAction(e.target.value))}
          legend="Theme type"
          value={themeType}
          options={[
            {
              label: 'Light',
              value: 'light',
            },
            {
              label: 'Dark',
              value: 'dark',
            },
          ]}
        />
      </Box>
    </>
  )
}

export default Theme
