import React, { useCallback, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import uniqBy from 'lodash/uniqBy'

import Label from '../../../Label'
import DownshiftMultipleSelect from '../../DownshiftMultipleSelect'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'
import ErrorScreen from '../../../ErrorScreen'
import InfoButton from '../../../InfoButton'
import {
  setBranchesAction,
  setBranchesIncludeAction,
} from '../../../../redux/reducers/criteria/actions'
import { fetchBranchesAction } from '../../../../redux/reducers/data/branches'
import { getIncludedItemsCount } from '../../../../helpers'
import ReallocationBranchesListDialog from './ReallocationBranchesListDialog'

export const makeBranchOptions = items =>
  items.map(b => ({
    label: b.branch_desc,
    value: parseInt(b.branch_id, 10),
  }))

const Header = () => (
  <Typography variant="h5" component="h2">
    Reallocation Branches
    <InfoButton
      tooltipContent={
        <>
          <Typography variant="h6" gutterBottom>
            Reallocation Branches
          </Typography>
          <Typography variant="body2">
            Which branches should stock be reallocated to?
          </Typography>
        </>
      }
    />
  </Typography>
)

const ReallocationBranches = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const {
    data: {
      branches: { error, isLoaded, items },
    },
    criteria: {
      reallocationBranches: { includeOrExclude, selectedItems },
    },
  } = useSelector(state => state)

  const setSelectedItem = useCallback(
    item => dispatch(setBranchesAction('reallocation', item)),
    [dispatch]
  )

  const handleDialogClose = useCallback(() => setDialogOpen(false), [])
  const handleDialogOpen = useCallback(() => setDialogOpen(true), [])

  const branchOptions = useMemo(
    () =>
      uniqBy(
        makeBranchOptions(items).filter(s => !!s.label && !!s.value),
        'value'
      ),
    [items]
  )

  const count = getIncludedItemsCount(
    includeOrExclude,
    branchOptions.length,
    selectedItems.length
  )

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Select reallocation branches to include'
      case 'exclude':
        return 'Select reallocation branches to exclude'
      default:
        return 'Select reallocation branches'
    }
  }

  if (error)
    return (
      <>
        <Header />
        <ErrorScreen
          action={() => dispatch(fetchBranchesAction())}
          actionButtonText="Try again"
          hideAppHeader
          errors={[error]}
        />
      </>
    )

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Header />
        {isLoaded && (
          <Box display="flex" alignItems="center" mb={1}>
            <Label
              isError={count === 0}
              isSuccess={count > 0}
              text={`${count.toLocaleString()} / ${branchOptions.length}`}
            />
            <MuiLink as="button" onClick={handleDialogOpen}>
              View entire list
            </MuiLink>
          </Box>
        )}
      </Box>
      {isLoaded && (
        <>
          <Box display="flex" alignItems="center" mb={1}>
            <IncludeExcludeSelect
              value={includeOrExclude}
              name="branchInclude"
              onChange={e =>
                dispatch(
                  setBranchesIncludeAction('reallocation', e.target.value)
                )
              }
            />
            <Typography>branches</Typography>
          </Box>
          {includeOrExclude !== 'include-all' && (
            <DownshiftMultipleSelect
              id="reallocation-branches"
              maxHeight={300}
              placeholder={getPlaceholder()}
              selectedItem={selectedItems}
              setSelectedItem={setSelectedItem}
              suggestions={branchOptions}
            />
          )}
          <ReallocationBranchesListDialog
            isOpen={isDialogOpen}
            handleClose={handleDialogClose}
          />
        </>
      )}
    </>
  )
}

export default ReallocationBranches
