import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import axios from 'axios'
import { useSelector } from 'react-redux'
import useDebounce from '../../../../useDebounce'

import { parseError } from '../../../../helpers'

// if (process.env.NODE_ENV === 'development') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render')
//   whyDidYouRender(React)
// }

const fetchAltcodes = (searchValue, filters) => {
  return axios.post('/postSearchAltcodes', {
    searchValue,
    options: {
      omitAltcodes: [],
      limit: 10,
    },
    filters,
  })
    .then(response => response.data)
    .catch(e => ({ error: parseError(e) }))
}

const useStyles = makeStyles(theme => ({
  progress: {
    marginLeft: theme.spacing(),
  },
  transparent: {
    backgroundColor: 'transparent !important'
  }
}))

const AltcodeSearch = React.memo(({ placeholder, selectedItem, setSelectedItem }) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')
  const [altcodes, setAltcodes] = React.useState([])
  const [error, setError] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const menuRef = React.useRef(null)

  const {
    criteria: {
      colourLevelFilters,
      gender,
      productGroups,
      shopTypes,
      seasons,
      suppliers,
      maxWarehouseStock,
      maxWarehouseStockExcluded,
      minBranchStock,
      minBranchStockExcluded,
      minMargin,
      minRecallPrice,
      minMarginExcluded,
      minRecallPriceExcluded,
      sizeLevelFilters
    }
  } = useSelector(state => state)
  const filters = {
    colourLevelFilters,
    gender,
    maxWarehouseStock,
    maxWarehouseStockExcluded,
    minBranchStock,
    minBranchStockExcluded,
    minMargin,
    minMarginExcluded,
    minRecallPrice,
    minRecallPriceExcluded,
    productGroups,
    shopTypes,
    sizeLevelFilters,
    seasons,
    suppliers
  }

  const debouncedInputValue = useDebounce(inputValue, 500)

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  React.useEffect(() => {
    if (debouncedInputValue) {
      setError(false)
      setIsLoading(true)
      fetchAltcodes(debouncedInputValue, filters)
        .then(r => {
          if (r.error) {
            setError(r.error)
          } else {
            setAltcodes(r)
          }
          setIsLoading(false)
        })
    }
  }, [debouncedInputValue]) // eslint-disable-line

  const handleAltcodeClick = e => {
    const altcode = altcodes.find(a => a.id === e.target.value)
    if (!altcode) return
    setSelectedItem([
      ...selectedItem,
      {
        ...altcode,
        value: altcode.id,
        label: altcode.altcode
      }
    ])
    setAltcodes([])
    setInputValue('')
  }

  const handleMenuClose = () => {
    setAltcodes([])
    setError(false)
    setIsLoading(false)
  }

  return (
    <>
      <TextField
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
        placeholder={placeholder}
      >
          search
      </TextField>
        <>
          <Box mt={4} ref={menuRef} />
          <Menu
            anchorEl={menuRef.current}
            open={altcodes.length > 0 || isLoading || !!error}
            onClose={handleMenuClose}
          >
            {error && (
              <MenuItem
                className={classes.transparent}
                selected={false}
              >
                <Typography
                  color="error"
                  onClick={handleMenuClose}
                >
                  {error}
                </Typography>
              </MenuItem>
            )}
            {isLoading && (
              <MenuItem
                className={classes.transparent}
                selected={false}
              >
                Loading...
                <CircularProgress size={18} className={classes.progress} />
              </MenuItem>
            )}
            {!isLoading && altcodes.map(a => (
              <MenuItem
                key={a.id}
                value={a.id}
                onClick={handleAltcodeClick}
              >
                {a.altcode}
              </MenuItem>
            ))}
          </Menu>
        </>
    </>
  )
})

AltcodeSearch.propTypes = {
  placeholder: PropTypes.string,
  selectedItem: PropTypes.arrayOf(PropTypes.object).isRequired,
  setSelectedItem: PropTypes.func.isRequired,
}
AltcodeSearch.defaultProps = {
  placeholder: null,
}

// AltcodeSearch.whyDidYouRender = true

export default AltcodeSearch