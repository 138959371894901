import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import IssueRecallDialog from './IssueRecallDialog'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const IssueRecallButton = () => {
  const classes = useStyles()
  const { recallId } = useSelector(state => state.recall)
  const [isDialogOpen, setDialogOpen] = useState(false)

  const handleDialogClose = () => setDialogOpen(false)

  return (
    <>
      <Button
        className={classes.button}
        color="primary"
        disabled={!!recallId}
        onClick={() => setDialogOpen(true)}
        variant="contained"
      >
        Issue Recall
        <NavigateNextIcon className={classes.rightIcon} />
      </Button>
      <IssueRecallDialog
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
      />
    </>
  )
}

export default IssueRecallButton
