import React from 'react'
import { useSelector } from 'react-redux'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

const CumulativeWeeks = () => {
  const { cumulativeWeeks } = useSelector(state => state.criteria)

  return (
    <ListItem dense disableGutters>
      <ListItemText>Cumulative weeks:</ListItemText>
      <ListItemSecondaryAction>{cumulativeWeeks}</ListItemSecondaryAction>
    </ListItem>
  )
}

export default CumulativeWeeks
