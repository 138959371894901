import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

import LoadingScreen from './LoadingScreen'

const FindStockLoader = () => {
  const {
    criteria: { unitsToRecall },
    global: { webSocketMessage },
    findStock: { stockUnitsFound, timer }
  } = useSelector(state => state)

  return (
    <LoadingScreen hideCircularProgress>
      <Box
        display="flex"
        alignItems="center"
        minWidth={320}
        flexDirection="column"
      >
        <Typography gutterBottom>
          {webSocketMessage ? `${webSocketMessage}...` : ''}
        </Typography>
        <Box flexGrow={1} py={2} width="100%">
          {webSocketMessage && webSocketMessage.indexOf('Analysing') > -1 ? (
            <LinearProgress key="query" color="secondary" variant="query" />
          ) : (
            <LinearProgress
              key={stockUnitsFound}
              color="secondary"
              value={(100 * stockUnitsFound) / unitsToRecall}
              variant="determinate"
            />
          )}
        </Box>
        <Typography gutterBottom>Please be patient and don't refresh the page.</Typography>
        <Typography variant="body2">{timer}</Typography>
      </Box>
    </LoadingScreen>
  )
}

export default FindStockLoader
