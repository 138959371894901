import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import AppHeader from './AppHeader'

const useStyles = makeStyles(theme => ({
  progress: {
    marginBottom: theme.spacing(2),
  },
}))

const LoadingScreen = ({ children, hideCircularProgress }) => {
  const classes = useStyles()

  return (
    <Box p={4} display="flex" alignItems="center" flexDirection="column">
      <AppHeader />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {!hideCircularProgress && (
          <CircularProgress
            className={classes.progress}
            variant="indeterminate"
          />
        )}
        {children}
      </Box>
    </Box>
  )
}

LoadingScreen.propTypes = {
  children: PropTypes.any,
  hideCircularProgress: PropTypes.bool,
}
LoadingScreen.defaultProps = {
  children: <Typography>Loading...</Typography>,
  hideCircularProgress: false,
}

export default LoadingScreen
