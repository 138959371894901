import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'

import TableLoader from '../../TableLoader'
import Pagination from '../../Pagination'
import TableHeader from './TableHeader'
import RecallTableRow from './RecallTableRow'

const RecallTable = React.memo(props => {
  const {
    handleChangePage,
    handleChangeRowsPerPage,
    handleSort,
    isLoaded,
    isTestFilter,
    pageIndex,
    rows,
    rowsPerPage,
    setIsTestFilter,
    setTitleFilter,
    sortBy,
    sortDirection,
    titleFilter,
    totalItems,
  } = props

  return (
    <Box mt={2} position="relative">
      {!isLoaded && <TableLoader />}
      <Table size="small">
        <TableHeader
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          handleSort={handleSort}
          isTestFilter={isTestFilter}
          pageIndex={pageIndex}
          pageSize={rowsPerPage}
          setIsTestFilter={setIsTestFilter}
          setTitleFilter={setTitleFilter}
          sortBy={sortBy}
          sortDirection={sortDirection}
          titleFilter={titleFilter}
          totalItems={totalItems}
        />
        <TableBody>
          {rows.map(r => (
            <RecallTableRow key={r.recallId} rowData={r} />
          ))}
        </TableBody>
        <TableFooter>
          <Pagination
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            pageIndex={pageIndex}
            pageSize={rowsPerPage}
            totalItems={totalItems}
          />
        </TableFooter>
      </Table>
    </Box>
  )
})

RecallTable.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  isTestFilter: PropTypes.number.isRequired,
  pageIndex: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  setIsTestFilter: PropTypes.func.isRequired,
  setTitleFilter: PropTypes.func.isRequired,
  sortBy: PropTypes.oneOf([
    'title',
    'issuedDate',
    'unitsRecalled',
    'unitsReallocated',
    'unitsHomeShopping',
    'unitsWarehouse',
  ]).isRequired,
  sortDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
  titleFilter: PropTypes.string.isRequired,
  totalItems: PropTypes.number.isRequired,
}

RecallTable.displayName = 'RecallTable'
// RecallTable.whyDidYouRender = true

export default RecallTable
