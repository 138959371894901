import React from 'react'
import PropTypes from 'prop-types'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

const RadioButtonsGroup = ({
  className,
  groupName,
  legend,
  options,
  value,
  handleChange,
}) => {
  return (
    <FormControl component="fieldset" className={className}>
      {legend && <FormLabel component="legend">{legend}</FormLabel>}
      <RadioGroup
        aria-label={groupName}
        name={groupName}
        row
        value={value}
        onChange={handleChange}
      >
        {options.map(o => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio />}
            label={o.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

RadioButtonsGroup.propTypes = {
  className: PropTypes.string,
  legend: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.any.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
RadioButtonsGroup.defaultProps = {
  legend: null,
  value: null,
}

export default RadioButtonsGroup
