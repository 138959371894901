import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import InfoButton from '../../InfoButton'
import {
  setMinBranchStockAction,
  setMinBranchStockExcludedAction
} from '../../../redux/reducers/criteria/actions'

const marks = [
  { value: 0, label: '0' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' }
]

const MinimumBranchStock = () => {
  const dispatch = useDispatch()
  const { minBranchStock, minBranchStockExcluded } = useSelector(
    state => state.criteria
  )
  const [localValue, setLocalValue] = useState(minBranchStock)

  const handleExcludeChecked = e => {
    dispatch(setMinBranchStockExcludedAction(e.target.checked))
  }

  return (
    <>
      <Box display="flex">
        <Typography id="minimum-branch-stock">
          Minimum branch stock
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Minimum branch stock
                </Typography>
                <Typography variant="body2" gutterBottom>
                  A SKU will only be considered for recall if the sum of the
                  current stock ("available to sell") over all non-retail branches is greater than or
                  equal to this value.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Stock levels are taken from <em>RMIS.dbo.Product_Availability</em>.
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <Slider
        aria-labelledby="minimum-branch-stock"
        value={localValue}
        disabled={minBranchStockExcluded}
        getAriaValueText={v => v.toString()}
        marks={marks}
        max={30}
        min={0}
        onChange={(_, value) => setLocalValue(value)}
        onChangeCommitted={(_, value) => dispatch(setMinBranchStockAction(localValue))}
        step={1}
        valueLabelDisplay="auto"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={minBranchStockExcluded}
            onChange={handleExcludeChecked}
          />
        }
        label="Exclude this parameter"
      />
    </>
  )
}

export default MinimumBranchStock
