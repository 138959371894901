import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import Label from '../../Label'
import { countSKUsAction } from '../../../redux/reducers/data/skus'

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'relative',
    top: 2,
    margin: theme.spacing(0, 1),
  },
}))

const SKUCount = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    data: {
      skus: { total, isLoaded, error },
    },
  } = useSelector(state => state)

  const countSKUs = useCallback(() => {
    dispatch(countSKUsAction())
  }, [dispatch])

  useEffect(() => {
    let didCancel = false

    if (!didCancel) {
      countSKUs()
    }

    return () => {
      didCancel = true
    }
  }, [countSKUs])

  return (
    <>
      {isLoaded ? (
        <Label
          isError={!!error || total === 0}
          isSuccess={total > 0}
          text={error ? 'error' : total.toLocaleString()}
        />
      ) : (
        <CircularProgress size={18} className={classes.progress} />
      )}
    </>
  )
}

export default SKUCount
