import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/core/styles'

import { setNewRecallIdAction } from '../../redux/reducers/recall/actions'
import * as constants from '../../redux/constants'
import {
  setActiveScreenAction,
  resetAppAction,
} from '../../redux/reducers/global/actions'
import ConfirmResetAppDialog from '../ConfirmResetAppDialog'

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold',
  },
  paper: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(4),
    width: '100%',
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const NewRecallButton = React.memo(() => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    criteria: { isDirty },
  } = useSelector(state => state)
  const [isConfirmOpen, setConfirmOpen] = useState(false)

  const handleClick = useCallback(() => {
    // if initial criteria has not been changed,
    // there is no need to show the confirm dialog
    if (!isDirty) {
      dispatch(setNewRecallIdAction(null))
      dispatch(setActiveScreenAction(constants.SCREEN_CRITERIA))
    } else {
      setConfirmOpen(true)
    }
  }, [dispatch, isDirty])
  const handleConfirm = useCallback(() => {
    dispatch(resetAppAction())
    dispatch(setActiveScreenAction(constants.SCREEN_CRITERIA))
    setConfirmOpen(false)
  }, [dispatch])
  const handleDialogClose = useCallback(() => setConfirmOpen(false), [])

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleClick}>
        New recall
        <AddIcon className={classes.rightIcon} />
      </Button>
      <ConfirmResetAppDialog
        isOpen={isConfirmOpen}
        handleClose={handleDialogClose}
        handleConfirm={handleConfirm}
      />
    </>
  )
})

NewRecallButton.displayName = 'NewRecallButton'
// NewRecallButton.whyDidYouRender = true

export default NewRecallButton
