import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import NoPaddingContainer from '../../NoPaddingContainer'
import DefineNewCriteriaButton from './DefineNewCriteriaButton'
import LoadExistingCriteriaButton from './LoadExistingCriteriaButton'

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    overflow: 'auto',
    padding: theme.spacing(4),
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const Start = React.memo(() => {
  const classes = useStyles()

  return (
    <NoPaddingContainer maxWidth="sm">
      <Paper className={classes.paper} elevation={3}>
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} className={classes.item}>
            <DefineNewCriteriaButton />
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <Typography>or</Typography>
          </Grid>
          <Grid item xs={12} className={classes.item}>
            <LoadExistingCriteriaButton />
          </Grid>
        </Grid>
      </Paper>
    </NoPaddingContainer>
  )
})

Start.displayName = 'Start'
// Start.whyDidYouRender = true

export default Start
