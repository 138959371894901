import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}))

const Screen = ({ children, paperOverflow }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Box m={isXs ? 1 : 2} flex={1} p={2}>
      <Paper className={classes.paper} style={{ overflow: paperOverflow }}>
        {children}
      </Paper>
    </Box>
  )
}

Screen.propTypes = {
  children: PropTypes.any,
  paperOverflow: PropTypes.string,
}
Screen.defaultProps = {
  children: null,
  paperOverflow: null,
}

export default Screen
