import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'

import Start from './Start'
import Filters from './Filters'
import Parameters from './Parameters'
import Sorting from './Sorting'
import RecallRealloc from './RecallRealloc'
import SKUs from './SKUs'
import Confirm from './Confirm'

import steps from './criteriaSteps'
import { setStepAction } from '../../redux/reducers/criteria/actions'
import * as constants from '../../redux/constants'

const CriteriaScreen = React.memo(() => {
  const dispatch = useDispatch()
  const { criteria } = useSelector(state => state)
  const { activeStep } = criteria

  return (
    <>
      <Stepper activeStep={activeStep}>
        {steps.map(s => {
          const isCompleted = s.isCompleted(criteria)

          return (
            <Step key={s.id}>
              {isCompleted ? (
                <StepButton onClick={() => dispatch(setStepAction(s.id))}>
                  {s.label}
                </StepButton>
              ) : (
                <StepLabel>{s.label}</StepLabel>
              )}
            </Step>
          )
        })}
      </Stepper>
      {activeStep === constants.STEP_START && <Start />}
      {activeStep === constants.STEP_FILTERS && <Filters />}
      {activeStep === constants.STEP_PARAMETERS && <Parameters />}
      {activeStep === constants.STEP_SKUS && <SKUs />}
      {activeStep === constants.STEP_SORTING && <Sorting />}
      {activeStep === constants.STEP_RECALL_REALLOC && <RecallRealloc />}
      {activeStep === constants.STEP_CONFIRM && <Confirm />}
    </>
  )
})

CriteriaScreen.displayName = 'CriteriaScreen'
// CriteriaScreen.whyDidYouRender = true

export default CriteriaScreen
