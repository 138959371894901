import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Theme from './Theme'
// import WeekOffset from './WeekOffset'

const SettingsScreen = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h2" gutterBottom>
        Settings
      </Typography>
      <Box display="flex" flexDirection="column" mt={2}>
        <Theme />
        {/* <WeekOffset /> */}
      </Box>
    </Container>
  )
}

export default SettingsScreen
