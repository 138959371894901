import React, { useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import uniqBy from 'lodash/uniqBy'

import InfoButton from '../../../InfoButton'
import Label from '../../../Label'
import DownshiftMultipleSelect from '../../DownshiftMultipleSelect'
import IncludeExcludeSelect from '../../IncludeExcludeSelect'
import { getIncludedItemsCount } from '../../../../helpers'
import {
  setSuppliersAction,
  setSuppliersIncludeAction,
} from '../../../../redux/reducers/criteria/actions'
import SuppliersListDialog from './SuppliersListDialog'

export const makeSupplierOptions = items =>
  items.map(g => ({
    label: g.supp_name,
    value: parseInt(g.supp_id, 10),
  }))

const Suppliers = () => {
  const dispatch = useDispatch()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const {
    criteria: {
      suppliers: { includeOrExclude, selectedItems },
    },
    data: {
      suppliers: { isLoaded, items },
    },
  } = useSelector(state => state)

  const setSelectedItem = useCallback(
    item => {
      dispatch(setSuppliersAction(item))
    },
    [dispatch]
  )

  const handleDialogClose = useCallback(() => setDialogOpen(false), [])
  const handleDialogOpen = useCallback(() => setDialogOpen(true), [])

  const supplierOptions = useMemo(
    () =>
      items
        ? uniqBy(
            makeSupplierOptions(items).filter(s => !!s.label && !!s.value),
            'value'
          )
        : [],
    [items]
  )

  const count = getIncludedItemsCount(
    includeOrExclude,
    supplierOptions.length,
    selectedItems.length
  )

  const getPlaceholder = () => {
    switch (includeOrExclude) {
      case 'include':
        return 'Select suppliers to include'
      case 'exclude':
        return 'Select suppliers to exclude'
      default:
        return 'Select suppliers'
    }
  }

  return (
    <>
      <Box display="flex" alignItems="center" mb={1}>
        <Typography variant="h5" component="h2">
          Suppliers
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Suppliers
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Only SKUs from the included suppliers will be considered for
                  recall. Supplier information comes from{' '}
                  <em>RMIS.dbo.product_master.SUPP_ID</em>.
                </Typography>
                <Typography variant="body2">
                  The
                  <Label text="label" isSuccess />
                  shows how many suppliers are included. At least one must be
                  included.
                </Typography>
              </>
            }
          />
        </Typography>
        {isLoaded && (
          <Box display="flex" alignItems="center">
            <Label
              isError={count === 0}
              isSuccess={count > 0}
              text={`${count.toLocaleString()} / ${supplierOptions.length}`}
            />
            <MuiLink as="button" onClick={handleDialogOpen}>
              View entire list
            </MuiLink>
          </Box>
        )}
      </Box>
      {isLoaded && (
        <>
          <Box display="flex" alignItems="center" mb={1}>
            <IncludeExcludeSelect
              value={includeOrExclude}
              name="supplierInclude"
              onChange={e => {
                dispatch(setSuppliersIncludeAction(e.target.value))
              }}
            />
            <Typography>suppliers</Typography>
          </Box>
          {includeOrExclude !== 'include-all' && (
            <DownshiftMultipleSelect
              id="suppliers"
              maxHeight={300}
              placeholder={getPlaceholder()}
              selectedItem={selectedItems}
              setSelectedItem={setSelectedItem}
              suggestions={supplierOptions}
            />
          )}
        </>
      )}
      <SuppliersListDialog
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
      />
    </>
  )
}

export default Suppliers
