import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save'
import { withSnackbar } from 'notistack'

const useStyles = makeStyles(theme => ({
  editable: {
    cursor: 'pointer',
    flex: 1,
    borderBottom: '1px dotted #d3d3d3',
  },
  icon: {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
  },
  textField: {
    fontSize: '1.5rem',
    padding: 0,
  },
}))

const EditableInput = ({
  ContainerEl,
  initialValue,
  enqueueSnackbar,
  onSave,
  snackbarMessages,
}) => {
  const classes = useStyles()
  const [isEditMode, setEditMode] = useState(false)
  const [isSaving, setSaving] = useState(false)
  const [value, setValue] = useState(initialValue)

  const reset = () => {
    setValue(initialValue)
    setEditMode(false)
  }

  const save = async () => {
    if (value === initialValue) {
      // don't update if no change
      setEditMode(false)
      return
    }

    try {
      setSaving(true)
      setEditMode(false)

      await onSave(value)
      if (snackbarMessages.success) {
        enqueueSnackbar(snackbarMessages.success, { variant: 'success' })
      }
    } catch (e) {
      // console.log(e)
      if (snackbarMessages.error) {
        enqueueSnackbar(snackbarMessages.error(e), { variant: 'error' })
      }
      reset()
    } finally {
      setSaving(false)
    }
  }

  const inner = (
    <span key={1}>
      {isEditMode ? (
        <Box display="flex" alignItems="center">
          <TextField
            autoFocus
            disabled={isSaving}
            inputProps={{
              className: classes.textField,
            }}
            fullWidth
            value={value}
            onChange={e => setValue(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Escape') {
                e.preventDefault()
                reset()
              }
              if (e.key === 'Enter') {
                e.preventDefault()
                save()
              }
            }}
          />
          <SaveIcon
            disabled={isSaving}
            className={classes.icon}
            onClick={save}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => setEditMode(true)}
        >
          <span className={classes.editable}>{value}</span>
          <EditIcon className={classes.icon} />
        </Box>
      )}
    </span>
  )

  if (!ContainerEl) return inner

  return React.cloneElement(ContainerEl, {}, [inner])
}

EditableInput.propTypes = {
  ContainerEl: PropTypes.node,
  onSave: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  snackbarMessages: PropTypes.object,
}
EditableInput.defaultProps = {
  ContainerEl: null,
}

export default withSnackbar(EditableInput)
