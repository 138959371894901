import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import omit from 'lodash/omit'
import Box from '@material-ui/core/Box'
import axios from 'axios'

import NoPaddingContainer from '../../NoPaddingContainer'
import NavError from '../NavError'
import NavButtons from '../NavButtons'
import CriteriaLists from './CriteriaLists'
import CopyCriteriaButton from '../../CopyCriteriaButton'
import ExportCriteriaButton from '../../ExportCriteriaButton'
import * as constants from '../../../redux/constants'
import {
  setStepAction,
  setStepCompletedAction
} from '../../../redux/reducers/criteria/actions'
import { findStockAction } from '../../../redux/reducers/findStock/actions'
import RecallInProgressDialog from './RecallInProgressDialog'

const Confirm = React.memo(() => {
  const dispatch = useDispatch()
  const {
    criteria,
    data: {
      skus: { total: skusTotal }
    },
    findStock: { findStockData }
  } = useSelector(state => state)
  const [
    isRecallInProgressDialogOpen,
    setRecallInProgressDialogOpen
  ] = useState(false)
  const [inProgressRecalls, setInProgressRecalls] = useState([])

  const handleFindStock = useCallback(async () => {
    try {
      const response = await axios.get('/getInProgressRecalls')
      // console.log(response.data)
      if (response.data.length === 0) {
        dispatch(setStepCompletedAction(constants.STEP_CONFIRM, true))
        dispatch(findStockAction())
      } else {
        setInProgressRecalls(response.data)
        setRecallInProgressDialogOpen(true)
      }
    } catch (e) {
      // console.log(e)
    }
  }, [dispatch])

  const isFindStockData =
    !!findStockData && Array.isArray(findStockData) && findStockData.length > 0

  const handleBackButtonClick = isFindStockData
    ? null
    : useCallback(
        () => dispatch(setStepAction(constants.STEP_RECALL_REALLOC)),
        [dispatch]
      )

  const handleCloseDialog = useCallback(
    () => setRecallInProgressDialogOpen(false),
    []
  )

  const criteriaJSON = useMemo(
    () =>
      JSON.stringify(
        omit(criteria, ['activeStep', 'completedSteps', 'isDirty']),
        null,
        2
      ),
    [criteria]
  )

  return (
    <>
      <NoPaddingContainer maxWidth="sm">
        <CriteriaLists criteria={criteria} />
        <NavButtons
          backButtonText="Back"
          handleBack={handleBackButtonClick}
          handleNext={handleFindStock}
          isDisabled={!skusTotal || skusTotal === 0}
          leftContent={
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <CopyCriteriaButton criteriaJSON={criteriaJSON} hideText />
              <ExportCriteriaButton criteriaJSON={criteriaJSON} hideText />
            </Box>
          }
          nextButtonText="Find stock"
        />
        {skusTotal === 0 && (
          <NavError message="There must be at least one eligible SKU to continue." />
        )}
      </NoPaddingContainer>
      <RecallInProgressDialog
        isOpen={isRecallInProgressDialogOpen}
        handleClose={handleCloseDialog}
        recalls={inProgressRecalls}
      />
    </>
  )
})

Confirm.displayName = 'Confirm'
// Confirm.whyDidYouRender = true

export default Confirm
