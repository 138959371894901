import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { withSnackbar } from 'notistack'

import EditableText from '../../../../EditableText'

const SizeGroupTitle = ({ enqueueSnackbar, initialValue, onSave }) => {
  return (
    <Typography variant="h5">
      <EditableText
        initialValue={initialValue}
        onSave={onSave}
        enqueueSnackbar={enqueueSnackbar}
        snackbarMessages={{}}
      />
    </Typography>
  )
}

SizeGroupTitle.propTypes = {
  enqueueSnackbar: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired
}

export default withSnackbar(SizeGroupTitle)
