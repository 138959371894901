import React from 'react'
import PropTypes from 'prop-types'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'

const ExportCriteriaButton = ({ criteriaJSON, hideText }) => {
  const handleExportCriteria = () => {
    const element = document.createElement('a')
    const file = new Blob([criteriaJSON], {
      type: 'application/json',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'criteria.json'
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      {!hideText && <Typography>Export criteria</Typography>}
      <Tooltip title="Export criteria">
        <IconButton
          onClick={handleExportCriteria}
          aria-label="Export criteria JSON"
        >
          <Icon className={'fa fa-download'} />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

ExportCriteriaButton.propTypes = {
  criteriaJSON: PropTypes.string.isRequired,
  hideText: PropTypes.bool,
}
ExportCriteriaButton.defaultProps = {
  hideText: false,
}

export default ExportCriteriaButton
