import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import CheckboxMultipleSelect from '../../CheckboxMultipleSelect'

const useStyles = makeStyles(theme => ({
  altcode: {
    paddingLeft: 0,
  },
  deleteButton: {
    paddingRight: 0,
  },
}))

const AltcodeRow = ({
  altcode: a,
  countSKUs,
  handleColourChange,
  handleDelete,
  handleSizeChange,
}) => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell className={classes.altcode}>
        <Typography>{a.label}</Typography>
      </TableCell>
      <TableCell>
        <CheckboxMultipleSelect
          handleClose={countSKUs}
          handleChange={handleColourChange}
          name={`colour-multi-${a.id}`}
          options={a.colours.map(c => ({
            value: c.id.toString(),
            label: c.description,
          }))}
          selectedItems={a.selectedColours || []}
        />
      </TableCell>
      <TableCell>
        <CheckboxMultipleSelect
          handleClose={countSKUs}
          handleChange={handleSizeChange}
          name={`size-multi-${a.id}`}
          options={a.sizes.map(s => ({
            value: s.id.toString(),
            label: s.description,
          }))}
          selectedItems={a.selectedSizes || []}
        />
      </TableCell>
      <TableCell align="right" className={classes.deleteButton}>
        <IconButton edge="end" aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}

AltcodeRow.propTypes = {
  altcode: PropTypes.shape({
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    colours: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
      })
    ).isRequired,
    sizes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  countSKUs: PropTypes.func.isRequired,
  handleColourChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSizeChange: PropTypes.func.isRequired,
}

export default AltcodeRow
