export const initialState = (sortBy, sortDirection) => ({
  branchFilter: '',
  data: [],
  error: false,
  isLoading: false,
  groupTableBy: 'sku',
  pageIndex: 0,
  pageSize: 5,
  shouldUpdate: true,
  skuFilter: '',
  sortBy,
  sortDirection,
  totalItems: 0,
})

export const constants = {
  POPULATE_DATA: 'POPULATE_DATA',
  RESET: 'RESET',
  SET_BRANCH_FILTER: 'SET_BRANCH_FILTER',
  SET_ERROR: 'SET_ERROR',
  SET_GROUP_BY: 'SET_GROUP_BY',
  SET_LOADING: 'SET_LOADING',
  SET_PAGE_INDEX: 'SET_PAGE_INDEX',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_SKU_FILTER: 'SET_SKU_FILTER',
  SET_SORTING: 'SET_SORTING',
}

const reducer = (state, action) => {
  // console.log(action)
  switch (action.type) {
    case constants.RESET: {
      return initialState
    }
    case constants.POPULATE_DATA:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        totalItems: action.payload.totalItems,
        shouldUpdate: true,
      }
    case constants.SET_BRANCH_FILTER:
      return {
        ...state,
        pageIndex: 0,
        branchFilter: action.payload,
        shouldUpdate: false,
      }
    case constants.SET_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        shouldUpdate: true,
      }
    case constants.SET_GROUP_BY:
      return {
        ...state,
        data: [],
        groupTableBy: action.payload,
        pageIndex: 0,
        shouldUpdate: true,
      }
    case constants.SET_LOADING:
      return {
        ...state,
        data: [],
        error: false,
        isLoading: action.payload,
        shouldUpdate: true,
      }
    case constants.SET_PAGE_INDEX:
      return {
        ...state,
        pageIndex: action.payload,
        shouldUpdate: true,
      }
    case constants.SET_PAGE_SIZE:
      return {
        ...state,
        pageIndex: 0,
        pageSize: action.payload,
        shouldUpdate: true,
      }
    case constants.SET_SKU_FILTER:
      return {
        ...state,
        pageIndex: 0,
        shouldUpdate: false,
        skuFilter: action.payload,
      }
    case constants.SET_SORTING: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortDirection: action.payload.sortDirection,
      }
    }
    default:
      return state
  }
}

export default reducer
