import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}))

const NavButtons = ({
  backButtonText,
  handleBack,
  handleNext,
  isDisabled,
  nextButtonText,
  leftContent,
}) => {
  const classes = useStyles()

  return (
    <Box display="flex" justifyContent="flex-end" mt={2} alignItems="center">
      {leftContent && (
        <Box display="flex" justifyContent="flex-start" mt={2} flex={1}>
          {leftContent}
        </Box>
      )}
      <Box display="flex" mt={2}>
        {handleBack && (
          <Box>
            <Button className={classes.button} onClick={handleBack}>
              {backButtonText}
            </Button>
          </Box>
        )}
        {handleNext && (
          <Box>
            <Button
              className={classes.button}
              color="primary"
              disabled={isDisabled}
              onClick={handleNext}
              variant="contained"
            >
              {nextButtonText}
              <NavigateNextIcon className={classes.rightIcon} />
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

NavButtons.propTypes = {
  backButtonText: PropTypes.string,
  handleBack: PropTypes.func,
  handleNext: PropTypes.func,
  isDisabled: PropTypes.bool,
  leftContent: PropTypes.any,
  nextButtonText: PropTypes.string,
}
NavButtons.defaultProps = {
  backButtonText: null,
  handleBack: null,
  handleNext: null,
  isDisabled: false,
  leftContent: null,
  nextButtonText: null,
}

export default NavButtons
