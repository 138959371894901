import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { yellow, blueGrey } from '@material-ui/core/colors'
import clsx from 'clsx'
import { useSelector } from 'react-redux'

import InfoButton from '../InfoButton'
import BranchAnalysisDialog from './BranchAnalysisDialog'
import ProductImage from '../ProductImage'

const useStyles = makeStyles(theme => {
  // console.log(theme)

  return {
    highlight: {
      backgroundColor: yellow[50],
      color:
        theme.palette.type === 'dark'
          ? theme.palette.primary.contrastText
          : null
    },
    pull: {
      backgroundColor: blueGrey[50]
    },
    pullText: {
      color:
        theme.palette.type === 'dark'
          ? theme.palette.primary.contrastText
          : null,
      fontSize: theme.typography.htmlFontSize * 1.2
    },
    bold: {
      fontWeight: 700
    }
  }
})

const SKURow = ({
  cumulativeWeeks,
  row,
  sortValue,
  branchRecallPercentage,
  removeTriplicates,
  reallocatePercentage,
  recallTimeframe
}) => {
  // console.log(row)

  const explicitlyShowRefunds = useSelector(state => state.global.explicitlyShowRefunds)
  const classes = useStyles()
  const [isBranchDetailsOpen, setBranchDetailsOpen] = useState(false)

  const pullTotal = row.branchAnalysis.reduce(
    (sum, b) => sum + (b.pull || 0),
    0
  )

  const pushTotal = row.branchAnalysis.reduce(
    (sum, b) => sum + (![23, 47].includes(b.BRANCH_ID || b.branchID || b.branch_id) ? b.push || 0 : 0), // don't include Home Shopping || Warehouse
    0
  )

  // console.log(row)

  const canShowProductImage = row.ALT_CODE1 && row.COL_DESC_SHORT

  return (
    <>
      <TableRow>
        <TableCell align={canShowProductImage ? 'center' : 'right'}>
          {canShowProductImage ? (
            <>
              <ProductImage
                altCode={row.ALT_CODE1}
                colourDescShort={row.COL_DESC_SHORT}
                margin={row.CURRENT_MARGIN}
                productGroup={row.productGroup}
                sellPrice={row.current_sp}
                genericColour={row.GENERICCOLOUR}
              />
              <Typography>
                {row.ALT_CODE1} {row.COL_DESC_SHORT} {row.SIZES_DESC}
              </Typography>
            </>
          ) : (
            <>{row.SKU_ID}</>
          )}
        </TableCell>
        <TableCell align="right">
          {row.warehouseStockUnits + row.HSStockUnits}
        </TableCell>
        <TableCell align="right">
          {row.adjustedBranchStockUnits}
          {row.branchStockUnits !== row.adjustedBranchStockUnits && <> ({row.branchStockUnits})</>}
          {row.adjustedBranchStockUnits !== row.branchStockUnits && (
            <InfoButton
              tooltipContent={
                <Typography>
                  {row.branchStockUnits - row.adjustedBranchStockUnits} unit
                  {row.branchStockUnits - row.adjustedBranchStockUnits !== 1
                    ? 's'
                    : ''}{' '}
                  excluded - recall not actioned during last{' '}
                  {recallTimeframe} days.
                </Typography>
              }
            />
          )}
        </TableCell>
        <TableCell align="right">
          {row.adjustedBranchesWithStockCount}
          {row.branchesWithStockCount !== row.adjustedBranchesWithStockCount && <> ({row.branchesWithStockCount})</>}
          {row.adjustedBranchesWithStockCount !== row.branchesWithStockCount && (
            <InfoButton
              tooltipContent={
                <Typography>
                  {row.branchesWithStockCount - row.adjustedBranchesWithStockCount} branch
                  {row.branchesWithStockCount - row.adjustedBranchesWithStockCount !== 1
                    ? 'es'
                    : ''}{' '}
                  not counted - all branch stock recalled but not actioned during last{' '}
                  {recallTimeframe} days.
                </Typography>
              }
            />
          )}
        </TableCell>
        <TableCell
          align="center"
          className={clsx({
            [classes.pull]: pullTotal > 0
          })}
        >
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
          >
            <Typography className={clsx(classes.bold, classes.pullText)}>
              {pullTotal}
            </Typography>
            <Tooltip title="View branch details">
              <IconButton
                className={classes.pullText}
                onClick={() => setBranchDetailsOpen(true)}
              >
                <KeyboardArrowDownIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-sales'
          })}
        >
          {explicitlyShowRefunds ? row.lastWeeksRetailSalesUnits : row.lastWeeksRetailSalesUnits - row.lastWeeksRetailRefundUnits}
          {explicitlyShowRefunds && (
            <Typography variant="caption">{row.lastWeeksRetailRefundUnits > 0 && ` (-${row.lastWeeksRetailRefundUnits})`}</Typography>
          )}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-sales'
          })}
        >
          {explicitlyShowRefunds ? row.lastWeeksHSSalesUnits : row.lastWeeksHSSalesUnits - row.lastWeeksHSRefundUnits}
          {explicitlyShowRefunds && (
            <Typography variant="caption">{row.lastWeeksHSRefundUnits > 0 && ` (-${row.lastWeeksHSRefundUnits})`}</Typography>
          )}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-sales'
          })}
        >
          {explicitlyShowRefunds ? row.cumulativeRetailSalesUnits : row.cumulativeRetailSalesUnits - row.cumulativeRetailRefundUnits}
          {explicitlyShowRefunds && (
            <Typography variant="caption">{row.cumulativeRetailRefundUnits > 0 && ` (-${row.cumulativeRetailRefundUnits})`}</Typography>
          )}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-sales'
          })}
        >
          {explicitlyShowRefunds ? row.cumulativeHSSalesUnits : row.cumulativeHSSalesUnits - row.cumulativeHSRefundUnits}
          {explicitlyShowRefunds && (
            <Typography variant="caption">{row.cumulativeHSRefundUnits > 0 && ` (-${row.cumulativeHSRefundUnits})`}</Typography>
          )}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-profit'
          })}
        >
          GBP{' '}
          {row.lastWeeksProfit || row.lastWeeksProfit === 0
            ? row.lastWeeksProfit.toFixed(2)
            : ''}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-profit'
          })}
        >
          GBP{' '}
          {row.cumulativeProfit || row.cumulativeProfit === 0
            ? row.cumulativeProfit.toFixed(2)
            : ''}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'last-weeks-cover'
          })}
        >
          {row.lastWeeksCover || row.lastWeeksCover === 0
            ? row.lastWeeksCover.toFixed(2)
            : ''}
        </TableCell>
        <TableCell
          align="right"
          className={clsx({
            [classes.highlight]: sortValue === 'cumulative-cover'
          })}
        >
          {row.cumulativeCover || row.cumulativeCover === 0
            ? parseFloat(row.cumulativeCover).toFixed(2)
            : ''}
        </TableCell>
      </TableRow>
      <BranchAnalysisDialog
        branchRecallPercentage={branchRecallPercentage}
        cumulativeWeeks={cumulativeWeeks}
        isOpen={isBranchDetailsOpen}
        pullTotal={pullTotal}
        pushTotal={pushTotal}
        reallocatePercentage={reallocatePercentage}
        recallTimeframe={recallTimeframe}
        removeTriplicates={removeTriplicates}
        setOpen={setBranchDetailsOpen}
        sku={row}
      />
    </>
  )
}

SKURow.propTypes = {
  cumulativeWeeks: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  branchRecallPercentage: PropTypes.number.isRequired,
  reallocatePercentage: PropTypes.number.isRequired,
  recallTimeframe: PropTypes.number.isRequired,
  removeTriplicates: PropTypes.string.isRequired,
  sortValue: PropTypes.string.isRequired
}

export default SKURow
