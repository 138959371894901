import axios from 'axios'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  error: false,
  isLoaded: false,
  items: []
}

const FETCH_SIZES = 'FETCH_SIZES'
const POPULATE_SIZES = 'POPULATE_SIZES'
const SET_SIZES_ERROR = 'SET_SIZES_ERROR'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SIZES:
      return {
        ...state,
        items: [],
        isLoaded: false
      }
    case POPULATE_SIZES:
      return {
        ...state,
        sizes: action.payload.sizes,
        sizeGroups: action.payload.sizeGroups,
        isLoaded: true
      }
    case SET_SIZES_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true
      }
    default:
      return state
  }
}

// `data` is of the form { sizes: [], sizeGroups: [] }
export const setSizesAction = data => ({
  type: POPULATE_SIZES,
  payload: data
})

export const fetchSizesAction = () => async dispatch => {
  try {
    dispatch({ type: SET_SIZES_ERROR, payload: false })
    dispatch({ type: FETCH_SIZES })
    const response = await axios.get('/getSizes')
    // console.log(response.data)
    dispatch(setSizesAction(response.data))
  } catch (e) {
    dispatch({
      type: SET_SIZES_ERROR,
      payload: parseError(`fetchSizesAction - ${parseError(e)}`)
    })
  }
}

export default reducer
