import React from 'react'
import PropTypes from 'prop-types'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Zoom from '@material-ui/core/Zoom'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

import useStyles from './useStyles'
import BranchRow from './BranchRow'
import InfoButton from '../../InfoButton'
import { showRefundsAction } from '../../../redux/reducers/global/actions'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const TableHeader = ({ cumulativeWeeks }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const explicitlyShowRefunds = useSelector(state => state.global.explicitlyShowRefunds)

  const handleToggleShowRefunds = () => {
    dispatch(showRefundsAction(!explicitlyShowRefunds))
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>Branch</TableCell>
        <TableCell align="right">
          Current stock
          <InfoButton
            tooltipContent={(
              <>
                <Typography variant="h6" gutterBottom>
                  Current stock
                </Typography>
                <Typography variant="body2">
                  <em>RMIS.dbo.Product_Availability.AVAIL_TO_SELL</em>, with the exception of 999 Warehouse which shows <em>RMIS.dbo.Product_Availability.AVAIL_TO_PROMISE</em>.
                </Typography>
              </>
            )}
          />
        </TableCell>
        {explicitlyShowRefunds ? (
          <TableCell align="right">Last wk sales <Typography variant="caption">(-refunds)</Typography></TableCell>
        ) : (
          <TableCell align="right">Last wk net sales</TableCell>
        )}
        {explicitlyShowRefunds ? (
          <TableCell align="right">{cumulativeWeeks} wk sales <Typography variant="caption">(-refunds)</Typography></TableCell>
        ) : (
          <TableCell align="right">{cumulativeWeeks} wk net sales</TableCell>
        )}
        <TableCell align="right">
          Group cover
        </TableCell>
        <TableCell align="right">
          Style/colour 4 wk sales
        </TableCell>
        <TableCell align="right">
          Rank
          {' '}
          <Typography variant="body2">
            (Original rank)
            <InfoButton
              tooltipContent={
                <>
                  <Typography variant="h6" gutterBottom>
                    Rank
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <em>rank = (stock + 1.5 * groupCover / 100) - (4wkStyleColourSales * 0.027)</em>
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Original rank
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <em>originalRank = stock - (lastWeeksSales - lastWeeksRefunds) - (cumulativeSales - cumulativeRefunds) / 2</em>
                  </Typography>
                  <Typography variant="caption">
                    All -ve values are treated as zero in the above formula.
                  </Typography>
                </>
              }
            />
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          className={clsx(classes.pull, classes.pullText)}
        >
          Pull
        </TableCell>
        <TableCell
          align="right"
          className={clsx(classes.push, classes.pushText)}
        >
          Push
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={3} />
        <TableCell colSpan={2} align="center">
          <FormControlLabel
            className={classes.formControlLabel}
            control={
              <Checkbox
                checked={explicitlyShowRefunds}
                checkedIcon={<CheckBoxIcon className={classes.smallIcon} />}
                icon={<CheckBoxOutlineBlankIcon className={classes.smallIcon}  />}
                color="secondary"
                onChange={handleToggleShowRefunds}
                value="showRefunds"
              />
            }
            label={(
              <Typography variant="caption" color="secondary">
                Explicitly show refunds
              </Typography>
            )}
          />
        </TableCell>
        <TableCell colSpan={6} />
      </TableRow>
    </TableHead>
  )
}

TableHeader.propTypes = {
  cumulativeWeeks: PropTypes.number.isRequired,
}

const BranchAnalysisDialog = ({
  branchRecallPercentage,
  cumulativeWeeks,
  isOpen,
  pullTotal,
  pushTotal,
  reallocatePercentage,
  recallTimeframe,
  removeTriplicates,
  setOpen,
  sku
}) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      onClose={() => setOpen(false)}
      open={isOpen}
      scroll="paper"
      TransitionComponent={Transition}
    >
      <DialogTitle>
        {sku.ALT_CODE1} {sku.COL_DESC_SHORT} {sku.SIZES_DESC} Branch Analysis
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography gutterBottom>
          {
            sku.branchAnalysis.filter(b => ![23, 47].includes(b.BRANCH_ID))
              .length
          }{' '}
          branches | Pull: {pullTotal} unit
          {pullTotal === 1 ? '' : 's'}, triplicates
          {(removeTriplicates === 'no' || removeTriplicates === false) &&
            ' not'}{' '}
          removed | Push to branch: {pushTotal} unit
          {pushTotal === 1 ? '' : 's'}.
        </Typography>
        <Typography>
          <span className={classes.highlightWorst}>
            Worst performing {branchRecallPercentage}% of branches highlighted.
          </span>{' '}
          <span className={classes.highlightBest}>
            Reallocation branches highlighted.
          </span>{' '}
          {reallocatePercentage}% reallocated.
        </Typography>
        <Table size="small" stickyHeader className={classes.table}>
          <TableHeader cumulativeWeeks={cumulativeWeeks} />
          <TableBody>
            {sku.branchAnalysis.map(b => (
              <BranchRow
                key={b.BRANCH_ID || b.branchId || b.branchID}
                branch={b}
                recallTimeframe={recallTimeframe}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

BranchAnalysisDialog.propTypes = {
  branchRecallPercentage: PropTypes.number.isRequired,
  cumulativeWeeks: PropTypes.number.isRequired,
  reallocatePercentage: PropTypes.number.isRequired,
  recallTimeframe: PropTypes.number.isRequired,
  removeTriplicates: PropTypes.string.isRequired,
  sku: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  pullTotal: PropTypes.number.isRequired,
  pushTotal: PropTypes.number.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default BranchAnalysisDialog
