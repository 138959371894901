import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'

import InfoButton from '../../InfoButton'
import useStyles from './useStyles'
import { isNumeric } from '../../../helpers'

const warehouseAndHSIds = [23, 47]

const BranchRow = ({ branch: b, recallTimeframe }) => {
  const classes = useStyles()
  const branchItems = useSelector(state => state.data.branches.items)
  const explicitlyShowRefunds = useSelector(state => state.global.explicitlyShowRefunds)

  const branchIdValue = b.BRANCH_ID || b.branchID || b.branchId

  let branchDescription = null
  // let branchDescription = b.BRANCH_DESC
  let branchCode = null
  // look up the branch name if not in the data (it's not saved in the compressed data in the db)
  const branch = branchItems.find(
    bb => parseInt(bb.branch_id, 10) === parseInt(branchIdValue, 10)
  )
  branchDescription = branch
    ? branch.branch_desc
    : b.BRANCH_DESC || branchIdValue
  if (branch) {
    branchCode = branch.branch_code
  }

  if (branchIdValue === 23) {
    branchDescription = 'WHO Warehouse'
    branchCode = 999
  }
  if (branchIdValue === 47) {
    branchDescription = 'Home Shopping'
    branchCode = 800
  }

  return (
    <TableRow
      key={b.BRANCH_ID}
      className={clsx({
        [classes.highlightWorst]: b.isRecallBranch,
        [classes.highlightBest]:
          b.isReallocationBranch && !warehouseAndHSIds.includes(branchIdValue),
        [classes.warehouseAndHomeShopping]: warehouseAndHSIds.includes(
          branchIdValue
        ),
        [classes.fade]: !b.isRecallBranch && !b.isReallocationBranch
      })}
    >
      <TableCell>
        {b.comment && b.comment !== '' ? (
          <InfoButton tooltipContent={<Typography>{b.comment}</Typography>} />
        ) : (
          ''
        )}
      </TableCell>
      <TableCell>
        {branchCode ? `${branchCode} ` : ''}
        {branchDescription}
      </TableCell>
      <TableCell align="right">
        {b.adjustedStock}
        {b.adjustedStock !== b.stock && (
          <InfoButton
            tooltipContent={
              <Typography>
                {b.stock - b.adjustedStock} unit{b.stock - b.adjustedStock === 1 ? '' : 's'} excluded -
                recall not actioned during last {recallTimeframe} days.
              </Typography>
            }
          />
        )}
      </TableCell>
      <TableCell align="right">
        {explicitlyShowRefunds ? b.lastWeeksSales : b.lastWeeksSales - b.lastWeeksRefunds}
        {explicitlyShowRefunds && (
          <Typography variant="caption">{b.lastWeeksRefunds > 0 && ` (-${b.lastWeeksRefunds})`}</Typography>
        )}
      </TableCell>
      <TableCell align="right">
        {explicitlyShowRefunds ? b.cumulativeSales : b.cumulativeSales - b.cumulativeRefunds}
        {explicitlyShowRefunds && (
          <Typography variant="caption">{b.cumulativeRefunds > 0 && ` (-${b.cumulativeRefunds})`}</Typography>
        )}
      </TableCell>
      <TableCell align="right">
        {isNumeric(b.groupCover) ? b.groupCover.toFixed(2) : ''}
      </TableCell>
      <TableCell align="right">
        {b.SC4WK_SALES_UNITS}
      </TableCell>
      <TableCell align="right">
        {isNumeric(b.rank) && !warehouseAndHSIds.includes(branchIdValue) // don't show WH or H/S ranks, they are not actual data.
          ? b.rank.toFixed(2)
          : 'N/A'}
          <Typography variant="caption">
            {' ('}
            {isNumeric(b.originalRank) && !warehouseAndHSIds.includes(branchIdValue) // don't show WH or H/S ranks, they are not actual data.
              ? b.originalRank.toFixed(2)
              : 'N/A'}
            {')'}
          </Typography>
      </TableCell>
      <TableCell
        align="right"
        className={clsx({
          [classes.pull]: b.pull > 0 || b.push > 0,
          [classes.pullText]: b.pull > 0 || b.push > 0
        })}
      >
        {b.pull}
      </TableCell>
      <TableCell
        align="right"
        className={clsx({
          [classes.push]: b.pull > 0 || b.push > 0,
          [classes.pushText]: b.pull > 0 || b.push > 0
        })}
      >
        {b.push}
      </TableCell>
    </TableRow>
  )
}

BranchRow.propTypes = {
  branch: PropTypes.object.isRequired,
  recallTimeframe: PropTypes.number.isRequired
}

export default BranchRow
