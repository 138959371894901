import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import ProgressiveImage from 'react-progressive-image'

const useStyles = makeStyles(theme => ({
  list: {
    marginBottom: theme.spacing(2)
  },
  productImage: {
    border: `1px solid ${grey[100]}`,
    borderRadius: theme.spacing(1) / 2,
    cursor: 'pointer',
    height: 100,
    padding: theme.spacing(),
    width: 100
  },
  largeImage: {
    border: `1px solid ${grey[100]}`,
    borderRadius: theme.spacing(1) / 2,
    height: 400,
    padding: theme.spacing(),
    width: 400
  },
  title: {
    textAlign: 'center'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />
})

const ProductImage = ({
  altCode,
  colourDescShort,
  margin,
  productGroup,
  sellPrice,
  genericColour,
}) => {
  const classes = useStyles()
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <ProgressiveImage
        src={`${process.env.REACT_APP_STILETTO_URI}/image.aspx?altcode=${altCode}&colour=${colourDescShort}&w=100&h=100&checkBPM=true`}
        placeholder=""
      >
        {(src, loading) => {
          return loading ? (
            <Skeleton variant="rect" width={100} height={100} />
          ) : (
            <img
              alt={`${altCode} ${colourDescShort}`}
              className={classes.productImage}
              onClick={() => setOpen(true)}
              src={src}
            />
          )
        }}
      </ProgressiveImage>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        onClose={() => setOpen(false)}
      >
        <DialogTitle className={classes.title}>
          {altCode} {colourDescShort}
        </DialogTitle>
        <DialogContent>
          {(productGroup ||
            sellPrice ||
            sellPrice === 0 ||
            margin ||
            margin === 0 || genericColour) && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              mb={2}
            >
              {productGroup && <Typography>{productGroup}</Typography>}
              {genericColour && (
                <Typography>Generic Colour: {genericColour}</Typography>
              )}
              {(sellPrice || sellPrice === 0) && (
                <Typography>SP: GBP {sellPrice.toFixed(2)}</Typography>
              )}
              {(margin || margin === 0) && (
                <Typography>Margin: {margin.toFixed(2)}%</Typography>
              )}
            </Box>
          )}
          <ProgressiveImage
            src={`${process.env.REACT_APP_STILETTO_URI}/image.aspx?altcode=${altCode}&colour=${colourDescShort}&w=400&h=400&checkBPM=true`}
            placeholder=""
          >
            {(src, loading) => {
              return loading ? (
                <Skeleton variant="rect" width={400} height={400} />
              ) : (
                <img
                  alt={`${altCode} ${colourDescShort}`}
                  className={classes.largeImage}
                  onClick={() => setOpen(true)}
                  src={src}
                />
              )
            }}
          </ProgressiveImage>
        </DialogContent>
      </Dialog>
    </>
  )
}

ProductImage.propTypes = {
  altCode: PropTypes.string.isRequired,
  colourDescShort: PropTypes.string.isRequired,
  margin: PropTypes.number,
  productGroup: PropTypes.string,
  sellPrice: PropTypes.number,
  genericColour: PropTypes.string
}
ProductImage.defaultProps = {
  margin: null,
  productGroup: null,
  sellPrice: null,
  genericColour: null
}

export default ProductImage
