import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import sortBy from 'lodash/sortBy'

import FindStockReport from '../../FindStockReport'
import { parseError } from '../../../helpers'

const FindStockTab = ({ recall }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)
  const [findStockData, setFindStockData] = useState(null)

  useEffect(() => {
    let didCancel = false

    const fetchRecall = async () => {
      try {
        const response = await axios.get(
          `/getRecallFindStockData/${recall.recallId}`
        )

        const data =
          response.data.map(row => {
            const branchAnalysis = row.branchAnalysisJSON
            const out = {
              ...row,
              branchAnalysis
            }
            delete out.branchAnalysisJSON
            return out
          })

        let sortedData

        // Do the sorting
        switch (JSON.parse(recall.filtersJSON || '{}').sortValue) {
        case 'last-weeks-profit':
            sortedData = sortBy(data, 'lastWeeksProfit').reverse()
            break
        case 'last-weeks-cover':
            sortedData = sortBy(data, 'lastWeeksCover')
            break
        case 'last-weeks-sales':
            sortedData = sortBy(
                data,
                x => (x.lastWeeksTotalSalesUnits - x.lastWeeksTotalRefundUnits)
            ).reverse()
            break
        case 'cumulative-profit':
            sortedData = sortBy(data, 'cumulativeProfit').reverse()
            break
        case 'cumulative-cover':
            sortedData = sortBy(data, 'cumulativeCover')
            break
        case 'cumulative-sales':
            sortedData = sortBy(
                data,
                x => (x.cumulativeTotalSalesUnits - x.cumulativeTotalRefundUnits)
            ).reverse()
            break
        default:
            throw new Error('Invalid sort value')
        }

        if (didCancel) return
        setFindStockData(sortedData)
      } catch (e) {
        console.log(e)
        if (didCancel) return
        setError(parseError(e))
      } finally {
        setIsLoading(false)
      }
    }

    fetchRecall()

    return () => {
      didCancel = true
    }
  }, [recall])

  if (isLoading) return <CircularProgress />
  if (error) return <Typography color="error">{error}</Typography>

  return (
    <FindStockReport
      error={error}
      executionTimeMs={recall.executionTimeMs}
      findStockData={findStockData}
      hideReportTitle
      criteria={JSON.parse(recall.filtersJSON || '{}')}
    />
  )
}

FindStockTab.propTypes = {
  recall: PropTypes.object.isRequired
}

export default FindStockTab
