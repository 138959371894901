import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import { version } from '../../package.json'

const useStyles = makeStyles(theme => ({
  subHeader: {
    opacity: 0.66,
  },
}))

const AppHeader = ({ title, variant }) => {
  const classes = useStyles()

  return (
    <>
      <Typography variant={variant} component="h1">
        {title}
      </Typography>
      <Typography
        className={classes.subHeader}
        component="h2"
        paragraph
        variant="h5"
      >
        Version {version}
      </Typography>
    </>
  )
}

AppHeader.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
}
AppHeader.defaultProps = {
  title: process.env.REACT_APP_TITLE,
  variant: 'h2',
}

export default AppHeader
