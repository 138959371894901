import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import InfoButton from '../../InfoButton'

import RadioButtonsGroup from '../../RadioButtonsGroup'

import { setRemoveTriplicatesAction } from '../../../redux/reducers/criteria/actions'

const RemoveTriplicates = () => {
  const dispatch = useDispatch()
  const removeTriplicates = useSelector(
    state => state.criteria.removeTriplicates
  )

  return (
    <>
      <Box display="flex">
        <Typography>
          Remove triplicates and above if branch is part of recall
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Remove triplicates and above
                </Typography>
                <Typography variant="body2" gutterBottom>
                  If a SKU is to be recalled from a branch and this is set to
                  'Yes', we only leave a single unit in the branch.
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Otherwise a single unit is recalled, even if it is the only
                  one in the branch.
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <RadioButtonsGroup
        groupName="remove-triplicates"
        handleChange={e => dispatch(setRemoveTriplicatesAction(e.target.value))}
        options={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
        value={removeTriplicates || ''}
      />
    </>
  )
}

export default RemoveTriplicates
