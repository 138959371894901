import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import InfoButton from '../../InfoButton'

const ShopTypes = ({ handleChange, options, values }) => {
  const handleShopTypeChange = ({ target: { value, checked } }) => {
    if (values.includes(value)) {
      handleChange(values.filter(v => v !== value))
    } else {
      handleChange([...values, value])
    }
  }

  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Shop Types
        <InfoButton
          tooltipContent={
            <>
              <Typography variant="h6" gutterBottom>
                Shop Types
              </Typography>
              <Typography variant="body2">
                SKUs are filtered by shop type, defined in{' '}
                <em>RMIS.dbo.vw_ShopType</em>, which in turn looks at the{' '}
                <em>anal_id</em> fields in <em>RMIS.dbo.PRODUCT_MASTER_ANALYSIS</em>{' '}
                and <em>RMIS.dbo.product_master</em>.
              </Typography>
            </>
          }
        />
      </Typography>
      <Typography variant="body2" paragraph>
        Only SKUs with a parent altcode having one of the selected shop types
        will be included.
      </Typography>
      <FormGroup row>
        {options.map(o => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.includes(o.value)}
                  onChange={handleShopTypeChange}
                  value={o.value}
                />
              }
              key={o.value}
              label={o.label}
            />
          )
        })}
      </FormGroup>
    </>
  )
}

ShopTypes.propTypes = {
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  values: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default ShopTypes
