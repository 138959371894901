import * as constants from '../../redux/constants'

export default [
  {
    id: constants.STEP_START,
    label: 'Start',
    isCompleted: () => false
  },
  {
    id: constants.STEP_FILTERS,
    label: 'Filters',
    isCompleted: criteria =>
      criteria.completedSteps.includes(constants.STEP_FILTERS)
  },
  {
    id: constants.STEP_PARAMETERS,
    label: 'Parameters',
    isCompleted: criteria =>
      criteria.completedSteps.includes(constants.STEP_PARAMETERS)
  },
  {
    id: constants.STEP_SKUS,
    label: 'SKUs',
    isCompleted: criteria =>
      criteria.completedSteps.includes(constants.STEP_SKUS)
  },
  {
    id: constants.STEP_SORTING,
    label: 'Sorting',
    isCompleted: criteria =>
      criteria.completedSteps.includes(constants.STEP_SORTING)
  },
  {
    id: constants.STEP_RECALL_REALLOC,
    label: 'Recall',
    isCompleted: criteria =>
      criteria.completedSteps.includes(constants.STEP_RECALL_REALLOC)
  },
  {
    id: constants.STEP_CONFIRM,
    label: 'Confirm',
    isCompleted: criteria =>
      criteria.completedSteps.includes(constants.STEP_CONFIRM)
  }
]
