import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

import InfoButton from '../../InfoButton'
import {
  setMinRecallPriceAction,
  setMinRecallPriceExcludedAction
} from '../../../redux/reducers/criteria/actions'

const marks = [
  { value: 0, label: '£0' },
  { value: 50, label: '£50' },
  { value: 100, label: '£100' },
  { value: 150, label: '£150' },
  { value: 200, label: '£200' },
  { value: 250, label: '£250' },
  { value: 300, label: '£300' },
  { value: 350, label: '£350' }
]

const MinimumRecallPrice = () => {
  const dispatch = useDispatch()
  const { minRecallPrice, minRecallPriceExcluded } = useSelector(
    state => state.criteria
  )
  const [localValue, setLocalValue] = useState(minRecallPrice)

  const handleExcludeChecked = e => {
    dispatch(setMinRecallPriceExcludedAction(e.target.checked))
  }

  return (
    <>
      <Box display="flex">
        <Typography id="minimum-recall-price">
          Minimum recall price
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Minimum recall price
                </Typography>
                <Typography variant="body2">
                  A SKU will only be considered for recall if its current
                  selling price (SP) is greater than or equal to this value.
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <Slider
        aria-labelledby="minimum-recall-price"
        value={localValue}
        disabled={minRecallPriceExcluded}
        getAriaValueText={v => v.toString()}
        marks={marks}
        max={350}
        min={0}
        onChange={(_, value) => setLocalValue(value)}
        onChangeCommitted={() => dispatch(setMinRecallPriceAction(localValue))}
        step={1}
        valueLabelDisplay="auto"
        valueLabelFormat={x => `£${x.toString()}`}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={minRecallPriceExcluded}
            onChange={handleExcludeChecked}
          />
        }
        label="Exclude this parameter"
      />
    </>
  )
}

export default MinimumRecallPrice
