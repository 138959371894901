import React from 'react'
import ReactDOM from 'react-dom'
import { Provider, useSelector } from 'react-redux'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { AuthProvider } from '@paversltd/react-stiletto-auth'
import {
  jonesTheme,
  paversTheme,
  darkPaversTheme,
  darkJonesTheme
} from '@paversltd/react-stiletto-components'
import { PersistGate } from 'redux-persist/integration/react'
import { indigo } from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'
import { BrowserRouter as Router } from 'react-router-dom'

import App from './App'
import AppInfo from './components/AppInfo'
import createStore from './redux/store'
import './axiosSetup'

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

const { store, persistor } = createStore()

const getTheme = (themeState, themeTypeState) => {
  switch (themeTypeState.toLowerCase()) {
    case 'light': {
      switch (themeState.toLowerCase()) {
        case 'pavers':
          return overrideTheme(paversTheme)
        case 'jones':
          return overrideTheme(jonesTheme)
        case 'mui':
          return overrideTheme(createMuiTheme())
        default:
          return createMuiTheme()
      }
    }

    case 'dark': {
      switch (themeState.toLowerCase()) {
        case 'pavers':
          return overrideTheme(darkPaversTheme)
        case 'jones':
          return overrideTheme(darkJonesTheme)
        case 'mui':
          return overrideTheme(createMuiTheme({ palette: { type: 'dark' } }))
        default:
          return createMuiTheme()
      }
    }

    default: {
      return createMuiTheme()
    }
  }
}

const overrideTheme = theme => {
  // console.log(theme)
  return {
    ...theme,
    overrides: {
      ...theme.overrides,
      MuiLink: {
        root: {
          color: theme.palette.type === 'dark' ? indigo[100] : indigo[500],
          cursor: 'pointer'
        },
        underlineHover: {
          textDecoration: 'underline'
        }
      }
    }
  }
}

const ThemeWrapper = () => {
  const themeState = useSelector(state => state.global.theme)
  const themeTypeState = useSelector(state => state.global.themeType)
  const theme = getTheme(themeState, themeTypeState)
  // console.log(theme)

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  )
}

ReactDOM.render(
  <Router>
    <AuthProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {process.env.REACT_APP_SHOW_APP_INFO === 'true' && <AppInfo />}
          <ThemeWrapper />
        </PersistGate>
      </Provider>
    </AuthProvider>
  </Router>,
  document.getElementById('root')
)
