import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
  icon: {
    fontSize: '0.75rem',
  },
}))

function TablePaginationActions(props) {
  const classes = useStyles()
  const { count, page, rowsPerPage, onChangePage } = props

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0)
  }

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1)
  }

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1)
  }

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        aria-label="First Page"
        disabled={page === 0}
        onClick={handleFirstPageButtonClick}
      >
        <Icon className={clsx(classes.icon, 'fa', 'fa-angle-double-left')} />
      </IconButton>
      <IconButton
        aria-label="Previous Page"
        disabled={page === 0}
        onClick={handleBackButtonClick}
      >
        <Icon className={clsx(classes.icon, 'fa', 'fa-chevron-left')} />
      </IconButton>
      <IconButton
        aria-label="Next Page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleNextButtonClick}
      >
        <Icon className={clsx(classes.icon, 'fa', 'fa-chevron-right')} />
      </IconButton>
      <IconButton
        aria-label="Last Page"
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        onClick={handleLastPageButtonClick}
      >
        <Icon className={clsx(classes.icon, 'fa', 'fa-angle-double-right')} />
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

export default TablePaginationActions
