import axios from 'axios'
import omit from 'lodash/omit'

import * as constants from '../../constants'
import { parseError } from '../../../helpers'
import {
  resetAppAction,
  setWebSocketMessageAction,
  setActiveRecallIdAction
} from '../global/actions'
import { fetchRecallsAction } from '../data/recalls'
import pkg from '../../../../package.json'

export const setRecallProgressAction = recallUnitsIssued => ({
  type: constants.SET_RECALL_PROGRESS,
  payload: recallUnitsIssued
})

export const setNewRecallIdAction = value => ({
  type: constants.SET_NEW_RECALL_ID,
  payload: value
})

// param: history is the react-router history object, so we can redirect
export const issueRecallAction = (
  title,
  description,
  isTest,
  history
) => async (dispatch, getState) => {
  const {
    criteria,
    findStock: { findStockData , executionTimeMs }
  } = getState()

  try {
    dispatch({ type: constants.SET_RECALL_ERROR, payload: false })
    dispatch({ type: constants.ISSUE_RECALL })

    const recallResponse = await axios.post('/postCreateRecall', {
      title,
      description,
      filtersJSON: JSON.stringify(
        omit(criteria, ['isDirty', 'completedSteps'])
      ),
      findStockData,
      isTest,
      username: sessionStorage.getItem('stiletto.username'),
      executionTimeMs,
      uiVersion: pkg.version
    })
    // console.log(recallResponse.data)
    const { recallId } = recallResponse.data

    // reload all recalls, so the created one displays on the home screen
    dispatch(fetchRecallsAction())

    dispatch({
      type: constants.COMPLETE_ISSUE_RECALL,
      payload: recallResponse.data.recallId
    })
    dispatch({
      type: constants.SET_NEW_RECALL_ID,
      payload: recallResponse.data.recallId
    })
    dispatch(resetAppAction())
    dispatch(setActiveRecallIdAction(recallResponse.data.recallId))
    history.push(`/recall/${recallId}`)
  } catch (e) {
    dispatch({
      type: constants.SET_RECALL_ERROR,
      payload: parseError(`issueRecallAction - ${parseError(e)}`)
    })
  } finally {
    dispatch(setWebSocketMessageAction(null))
  }
}
