import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiLink from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import SKUs from './SKUs'
import Recall from './Recall'
import ReallocationBranches from './ReallocationBranches'
import RecallBranches from './RecallBranches'
import CumulativeWeeks from './CumulativeWeeks'
import Parameters from './Parameters'
import ProductGroups from './ProductGroups'
import Seasons from './Seasons'
import ShopType from './ShopType'
import Sorting from './Sorting'
import Suppliers from './Suppliers'
import Colour from './Colour'
import SizeGroups from './SizeGroups'
// import Altcodes from "./Altcodes"
import * as constants from '../../../redux/constants'
import { setStepAction } from '../../../redux/reducers/criteria/actions'

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(2),
    overflow: 'auto',
    padding: theme.spacing(2)
  }
}))

const CriteriaLists = ({ criteria }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleEdit = screen => {
    dispatch(setStepAction(screen))
  }

  return (
    <>
      <Paper className={classes.paper} elevation={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" component="h3">
            Filters
          </Typography>
          <MuiLink
            as="button"
            onClick={() => handleEdit(constants.STEP_FILTERS)}
          >
            Edit filters
          </MuiLink>
        </Box>
        <List>
          <ShopType />
          <Seasons />
          <ProductGroups />
          <Suppliers />
        </List>
      </Paper>

      <Paper className={classes.paper} elevation={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" component="h3">
            Parameters
          </Typography>
          <MuiLink
            as="button"
            onClick={() => handleEdit(constants.STEP_PARAMETERS)}
          >
            Edit parameters
          </MuiLink>
        </Box>
        <List>
          <Parameters />
        </List>
      </Paper>

      <Paper className={classes.paper} elevation={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" component="h3">
            SKUs
          </Typography>
          <MuiLink as="button" onClick={() => handleEdit(constants.STEP_SKUS)}>
            Edit SKUs
          </MuiLink>
        </Box>
        <List>
          <Colour />
          <SizeGroups />
          {/* <Altcodes /> */}
          <SKUs />
        </List>
      </Paper>

      <Paper className={classes.paper} elevation={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" component="h3">
            Sorting
          </Typography>
          <MuiLink
            as="button"
            onClick={() => handleEdit(constants.STEP_SORTING)}
          >
            Edit sorting
          </MuiLink>
        </Box>
        <List>
          <CumulativeWeeks />
          <Sorting />
        </List>
      </Paper>

      <Paper className={classes.paper} elevation={3}>
        <Box
          mb={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h5" component="h3">
            Recall
          </Typography>
          <MuiLink
            as="button"
            onClick={() => handleEdit(constants.STEP_RECALL_REALLOC)}
          >
            Edit recall
          </MuiLink>
        </Box>
        <List>
          <Recall />
          <RecallBranches />
          <ReallocationBranches />
        </List>
      </Paper>
    </>
  )
}

CriteriaLists.propTypes = {
  criteria: PropTypes.object.isRequired
}

export default CriteriaLists
