import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'

import TablePaginationActions from './TablePaginationActions'

const useStyles = makeStyles(theme => {
  // console.log(theme)
  return {
    pagination: {
      background:
        theme.palette.type === 'dark'
          ? theme.palette.grey[500]
          : theme.palette.grey[50],
    },
  }
})

const Pagination = props => {
  const classes = useStyles()

  return (
    <TableRow className={classes.pagination}>
      <TablePagination
        rowsPerPageOptions={props.rowsPerPageOptions}
        count={props.totalItems}
        rowsPerPage={props.pageSize}
        page={props.pageIndex}
        SelectProps={{
          inputProps: { 'aria-label': 'Rows per page' },
          native: true,
        }}
        onChangePage={props.handleChangePage}
        onChangeRowsPerPage={props.handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </TableRow>
  )
}

Pagination.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  pageIndex: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  totalItems: PropTypes.number.isRequired,
}
Pagination.defaultProps = {
  rowsPerPageOptions: [5, 10, 25],
}

export default Pagination
