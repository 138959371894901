import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

const ConfirmResetAppDialog = ({ isOpen, handleClose, handleConfirm }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm Reset</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          You are about reset criteria and clear any reports. Proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          autoFocus
          color="primary"
          onClick={handleConfirm}
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmResetAppDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
}

export default ConfirmResetAppDialog
