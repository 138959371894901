import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Screen from './components/Screen'
import HomeScreen from './components/HomeScreen'
import CriteriaScreen from './components/CriteriaScreen'
import FindStockScreen from './components/FindStockScreen'
import DocsScreen from './components/DocsScreen'
import SettingsScreen from './components/SettingsScreen'
import RecallDetailScreen from './components/RecallDetailScreen'
import Page404 from './components/Page404'
import * as constants from './redux/constants'

const Routes = React.memo(() => {
  const activeScreen = useSelector(state => state.global.activeScreen)

  return (
    <Switch>
      <Route exact path="/">
        <Screen
          paperOverflow={activeScreen === constants.SCREEN_SKUS ? 'auto' : null}
        >
          {activeScreen === constants.SCREEN_HOME && <HomeScreen />}
          {activeScreen === constants.SCREEN_CRITERIA && <CriteriaScreen />}
          {activeScreen === constants.SCREEN_SKUS && <FindStockScreen />}
        </Screen>
      </Route>
      <Route exact path="/recall/:id">
        <RecallDetailScreen />
      </Route>
      <Route exact path="/docs">
        <Screen>
          <DocsScreen />
        </Screen>
      </Route>
      <Route exact path="/settings">
        <Screen>
          <SettingsScreen />
        </Screen>
      </Route>
      <Route component={Page404} />
    </Switch>
  )
})

export default Routes
