import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import { setSortValueAction } from '../../../redux/reducers/criteria/actions'
import RadioButtonsGroup from '../../RadioButtonsGroup'
import InfoButton from '../../InfoButton'

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    padding: theme.spacing(2)
  },
  radioGroup: {
    marginTop: theme.spacing(2)
  }
}))

const SortValue = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const {
    criteria: { cumulativeWeeks, sortValue }
  } = useSelector(state => state)

  const handleSortValueChange = name => e => {
    // console.log(name, e.target.value)
    if (name === 'period') {
      dispatch(setSortValueAction(`${e.target.value}-${metric}`))
    } else if (name === 'metric') {
      dispatch(setSortValueAction(`${period}-${e.target.value}`))
    }
  }

  let metric
  let period
  switch (sortValue) {
    case 'last-weeks-profit':
      metric = 'profit'
      period = 'last-weeks'
      break
    case 'last-weeks-cover':
      metric = 'cover'
      period = 'last-weeks'
      break
    case 'last-weeks-sales':
      metric = 'sales'
      period = 'last-weeks'
      break
    case 'cumulative-profit':
      metric = 'profit'
      period = 'cumulative'
      break
    case 'cumulative-cover':
      metric = 'cover'
      period = 'cumulative'
      break
    case 'cumulative-sales':
      metric = 'sales'
      period = 'cumulative'
      break
    default:
      throw new Error('invalid sort value.')
  }

  return (
    <>
      <Typography variant="h5" component="h2" gutterBottom>
        Sorting
        <InfoButton
          tooltipContent={
            <>
              <Typography variant="h6" gutterBottom>
                Sorting
              </Typography>
              <Typography variant="body2" gutterBottom>
                SKUs are sorted by the metric selected here.
              </Typography>
              <Typography variant="caption">
                Note: <em>cover = currentStock / lastWeeksSales</em>. Cumulative cover is calculated as{' '}
                <em>currentStock / averageWeeklySales</em>.
              </Typography>
            </>
          }
        />
      </Typography>
      <Box display="flex" flexDirection="column">
        <RadioButtonsGroup
          className={classes.radioGroup}
          groupName="metric"
          handleChange={handleSortValueChange('metric')}
          legend="Sort SKUs by:"
          options={[
            { label: 'Profit', value: 'profit' },
            { label: 'Sales units', value: 'sales' },
            {
              label: 'Cover',
              value: 'cover'
            }
          ]}
          value={metric || ''}
        />
        <RadioButtonsGroup
          className={classes.radioGroup}
          groupName="period"
          handleChange={handleSortValueChange('period')}
          legend="Over the following period:"
          options={[
            {
              label: 'Last week',
              value: 'last-weeks'
            },
            {
              label: `${cumulativeWeeks} week`,
              value: 'cumulative'
            }
          ]}
          value={period || ''}
        />
      </Box>
    </>
  )
}

export default SortValue
