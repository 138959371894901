import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import IssueRecallButton from './IssueRecallButton'
import ErrorScreen from '../ErrorScreen'
import NoPaddingContainer from '../NoPaddingContainer'
import FindStockReport from '../FindStockReport'
import ReportTitle from '../FindStockReport/ReportTitle'
import * as constants from '../../redux/constants'
import { setActiveScreenAction } from '../../redux/reducers/global/actions'
import { setFindStockErrorAction } from '../../redux/reducers/findStock/actions'

const FindStockScreen = () => {
  const dispatch = useDispatch()

  const {
    criteria: {
      branchRecallPercentage,
      cumulativeWeeks,
      reallocatePercentage,
      recallTimeframe,
      removeTriplicates,
      sortValue,
      unitsToRecall
    },
    findStock: {
      findStockData,
      error: findStockError,
      executionTimeMs
    },
    recall: { error: recallError }
  } = useSelector(state => state)

  if (findStockError)
    return (
      <NoPaddingContainer maxWidth="lg">
        <ReportTitle />
        <ErrorScreen
          action={() => {
            dispatch(setFindStockErrorAction(false))
            dispatch(setActiveScreenAction(constants.SCREEN_CRITERIA))
          }}
          actionButtonText="Try again"
          hideAppHeader
          errors={[findStockError]}
        />
      </NoPaddingContainer>
    )

  if (!findStockData || findStockData.length === 0) {
    return (
      <NoPaddingContainer maxWidth="lg">
        <ReportTitle />
        <Typography>No data.</Typography>
      </NoPaddingContainer>
    )
  }

  const foundRecallUnits = findStockData.reduce((sum, sku) => {
    return (
      sum +
      sku.branchAnalysis.reduce(
        (branchSum, branch) => branchSum + branch.pull,
        0
      )
    )
  }, 0)

  return (
    <>
      <NoPaddingContainer maxWidth="lg">
        <FindStockReport
          error={recallError}
          executionTimeMs={executionTimeMs}
          findStockData={findStockData}
          criteria={{
            branchRecallPercentage,
            cumulativeWeeks,
            foundRecallUnits,
            reallocatePercentage,
            recallTimeframe,
            removeTriplicates,
            sortValue,
            unitsToRecall
          }}
        />
        <Box display="flex" justifyContent="flex-end">
          <IssueRecallButton />
        </Box>
      </NoPaddingContainer>
    </>
  )
}

export default FindStockScreen
