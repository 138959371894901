import React from 'react'
import Typography from '@material-ui/core/Typography'

const ReportTitle = () => (
  <Typography variant="h4" component="h2" gutterBottom>
    Top SKUs
  </Typography>
)

export default ReportTitle
