import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import NoPaddingContainer from '../../NoPaddingContainer'
import MaxWarehouseStock from './MaxWarehouseStock'
import MinimumBranchStock from './MinimumBranchStock'
import NavButtons from '../NavButtons'
import MinimumRecallPrice from './MinimumRecallPrice'
import MinimumMargin from './MinimumMargin'

import * as constants from '../../../redux/constants'
import {
  setStepAction,
  setStepCompletedAction,
} from '../../../redux/reducers/criteria/actions'

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    overflow: 'auto',
    padding: theme.spacing(4),
  },
}))

const Parameters = React.memo(() => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleBackButtonClick = useCallback(
    () => dispatch(setStepAction(constants.STEP_FILTERS)),
    [dispatch]
  )
  const handleNextButtonClick = useCallback(() => {
    dispatch(setStepCompletedAction(constants.STEP_PARAMETERS, true))
    dispatch(setStepAction(constants.STEP_SKUS))
  }, [dispatch])

  return (
    <NoPaddingContainer maxWidth="lg">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <MaxWarehouseStock />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <MinimumBranchStock />
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <MinimumMargin />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <MinimumRecallPrice />
          </Paper>
        </Grid>
      </Grid>

      <NavButtons
        backButtonText="Back"
        handleBack={handleBackButtonClick}
        handleNext={handleNextButtonClick}
        nextButtonText="SKUs"
      />
    </NoPaddingContainer>
  )
})

Parameters.displayName = 'Parameters'
// Parameters.whyDidYouRender = true

export default Parameters
