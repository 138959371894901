// Based on https://usehooks.com/useDebounce/
import { useState, useEffect } from 'react'

const useDebounce = (value, delay, cb) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
        if (cb && typeof cb === 'function') {
          cb()
        }
      }, delay)

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay, cb]
  )

  return debouncedValue
}

export default useDebounce
