import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import parseISO from 'date-fns/parseISO'
import max from 'date-fns/max'
import min from 'date-fns/min'

import SKURow from './SKURow'
import ReportHeader from './ReportHeader'
import TableHeader from './TableHeader'
import Pagination from '../Pagination'

const FindStockReport = ({
  criteria,
  error,
  executionTimeMs,
  findStockData,
  hideReportTitle
}) => {
  const modifiedDates = findStockData.map(x => parseISO(x.MODIFIED))
  const minModifiedDate = min(modifiedDates)
  const maxModifiedDate = max(modifiedDates)

  const {
    branchRecallPercentage,
    cumulativeWeeks,
    foundRecallUnits,
    reallocatePercentage,
    recallTimeframe,
    removeTriplicates,
    sortValue,
    unitsToRecall
  } = criteria

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const visibleData = findStockData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  return (
    <>
        <ReportHeader
          cumulativeWeeks={cumulativeWeeks}
          executionTimeMs={executionTimeMs}
          findStockData={findStockData}
          foundRecallUnits={foundRecallUnits}
          hideReportTitle={hideReportTitle}
          minModifiedDate={minModifiedDate}
          maxModifiedDate={maxModifiedDate}
          sortValue={sortValue}
          unitsToRecall={unitsToRecall}
        />
      {error && (
        <Box mb={2}>
          <Typography color="error">{error}</Typography>
        </Box>
      )}
      <Table size="small">
        <TableHeader
          cumulativeWeeks={cumulativeWeeks}
          reallocatePercentage={reallocatePercentage}
          sortValue={sortValue}
          totalItems={findStockData.length}
          setPage={setPage}
          page={page}
          setRowsPerPage={setRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
        <TableBody>
          {visibleData.map(row => (
            <SKURow
              key={`${row.SKU_ID}-${row.COL_DESC_SHORT}-${row.SIZES_DESC}-${row.productGroup}`}
              branchRecallPercentage={branchRecallPercentage}
              cumulativeWeeks={cumulativeWeeks}
              reallocatePercentage={reallocatePercentage}
              recallTimeframe={recallTimeframe}
              removeTriplicates={removeTriplicates}
              row={row}
              sortValue={sortValue}
            />
          ))}
        </TableBody>
        <TableFooter>
          <Pagination
            totalItems={findStockData.length}
            handleChangeRowsPerPage={e => {
              setPage(0)
              setRowsPerPage(parseInt(e.target.value, 10))
            }}
            handleChangePage={(_, newPage) => setPage(newPage)}
            pageIndex={page}
            pageSize={rowsPerPage}
          />
        </TableFooter>
      </Table>
    </>
  )
}

FindStockReport.propTypes = {
  criteria: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  executionTimeMs: PropTypes.number,
  findStockData: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideReportTitle: PropTypes.bool,
}
FindStockReport.defaultProps = {
  error: false,
  executionTimeMs: null,
  hideReportTitle: false,
}

export default FindStockReport
