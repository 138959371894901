import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'

import AddSizeGroupDialog from './AddSizeGroupDialog'

const useStyles = makeStyles(theme => ({
  rightIcon: {
    marginLeft: theme.spacing(1)
  }
}))

const AddSizeGroupButton = ({ handleClick }) => {
  const classes = useStyles()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const handleDialogClose = () => setDialogOpen(false)
  const handleDialogOpen = () => setDialogOpen(true)

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleDialogOpen}>
        New size group
        <AddIcon className={classes.rightIcon} />
      </Button>
      <AddSizeGroupDialog
        isOpen={isDialogOpen}
        handleClose={handleDialogClose}
        handleAdd={title => {
          handleClick(title)
          handleDialogClose()
        }}
      />
    </>
  )
}

AddSizeGroupButton.propTypes = {
  handleClick: PropTypes.func.isRequired
}

export default AddSizeGroupButton
