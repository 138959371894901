import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const NavError = ({ message }) => {
  return (
    <Box mt={1} display="flex" justifyContent="flex-end">
      <Typography color="error" variant="body2">
        <em>{message}</em>
      </Typography>
    </Box>
  )
}

NavError.propTypes = {
  message: PropTypes.string.isRequired,
}

export default NavError
