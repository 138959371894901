import React from 'react'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

export default {
  Header: () => null,
  id: 'expander',
  Cell: cell => {
    // console.log(cell.row)
    if (!cell.row.canExpand) return null

    // const originalOnClick = cell.row.getExpandedToggleProps().onClick
    const icon = cell.row.isExpanded ? 'fa-chevron-down' : 'fa-chevron-right'

    return (
      <IconButton aria-label="Expand" {...cell.row.getExpandedToggleProps()}>
        <Icon className={`fa ${icon}`} />
      </IconButton>
    )
  },
}
