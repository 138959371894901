import { makeStyles } from '@material-ui/core/styles'
import { red, blueGrey, green, teal } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingTop: 0
  },
  highlightBest: {
    backgroundColor: green[50],
    color:
      theme.palette.type === 'dark'
        ? `${theme.palette.primary.contrastText} !important`
        : null,

    '& td': {
      color:
        theme.palette.type === 'dark'
          ? `${theme.palette.primary.contrastText} !important`
          : null
    }
  },
  highlightWorst: {
    backgroundColor: red[50],
    color:
      theme.palette.type === 'dark'
        ? `${theme.palette.primary.contrastText} !important`
        : null,

    '& td': {
      color:
        theme.palette.type === 'dark'
          ? `${theme.palette.primary.contrastText} !important`
          : null
    }
  },
  pull: {
    backgroundColor: blueGrey[50]
  },
  pullText: {
    color:
      theme.palette.type === 'dark'
        ? `${theme.palette.primary.contrastText} !important`
        : null,
    fontSize: theme.typography.htmlFontSize * 1.2
  },
  push: {
    backgroundColor: blueGrey[50]
  },
  pushText: {
    color:
      theme.palette.type === 'dark'
        ? `${theme.palette.primary.contrastText} !important`
        : null,
    fontSize: theme.typography.htmlFontSize * 1.2
  },
  table: {
    marginTop: theme.spacing(2)
  },
  warehouseAndHomeShopping: {
    backgroundColor: teal[50],
    color:
      theme.palette.type === 'dark'
        ? `${theme.palette.primary.contrastText} !important`
        : null,

    '& td': {
      color:
        theme.palette.type === 'dark'
          ? `${theme.palette.primary.contrastText} !important`
          : null
    }
  },
  fade: {
    opacity: 0.66
  },
  smallIcon: {
    height: theme.typography.htmlFontSize,
    width: theme.typography.htmlFontSize,
  },
  formControlLabel: {
    marginRight: 0
  }
}))

export default useStyles
