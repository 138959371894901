import axios from 'axios'
import uuid from 'uuid-by-string'

import { parseError } from '../../../helpers'

const INITIAL_STATE = {
  cache: [],
  error: false,
  isLoaded: false,
  isRequestInProgress: false,
  total: null
}

const FETCH_SKUS_COUNT = 'FETCH_SKUS_COUNT'
const CLEAR_SKUS_CACHE = 'CLEAR_SKUS_CACHE'
const POPULATE_SKUS_COUNT = 'POPULATE_SKUS_COUNT'
const SET_REQUEST_IN_PROGRESS = 'SET_REQUEST_IN_PROGRESS'
const SET_SKUS_CACHE = 'SET_SKUS_CACHE'
const SET_SKUS_ERROR = 'SET_SKUS_ERROR'
const SET_SKUS_REQUEST_IN_PROGRESS = 'SET_SKUS_REQUEST_IN_PROGRESS'

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SKUS_COUNT:
      return {
        ...state,
        error: false,
        total: null,
        isLoaded: false
      }
    case POPULATE_SKUS_COUNT:
      return {
        ...state,
        total: action.payload,
        isLoaded: true
      }
    case SET_REQUEST_IN_PROGRESS: {
      return {
        ...state,
        isRequestInProgress: action.payload
      }
    }
    case CLEAR_SKUS_CACHE:
      return {
        ...state,
        cache: []
      }
    case SET_SKUS_CACHE: {
      const index = state.cache.findIndex(c => c.key === action.payload.key)
      let cache
      if (index === -1) {
        cache = [...state.cache, action.payload]
      } else {
        cache = state.cache.map((c, i) => {
          if (i === index) return action.payload
          return i
        })
      }
      return {
        ...state,
        cache
      }
    }
    case SET_SKUS_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoaded: true
      }
    case SET_SKUS_REQUEST_IN_PROGRESS:
      return {
        ...state,
        isRequestInProgress: action.payload
      }
    default:
      return state
  }
}

export const clearSKUsCountCacheAction = () => ({
  type: CLEAR_SKUS_CACHE
})

export const countSKUsAction = () => async (dispatch, getState) => {
  // console.log(getState().data.skus)
  const {
    criteria,
    data: {
      skus: { cache, isRequestInProgress }
    }
  } = getState()
  if (isRequestInProgress) {
    // console.log('countSKUsAction cancelled')
    return // prevent unnecessary requests
  }

  // console.log(criteria.maxWarehouseStockExcluded)

  const {
    skuLevelFilters,
    colourLevelFilters,
    // cumulativeWeeks,
    gender,
    maxWarehouseStock,
    maxWarehouseStockExcluded,
    minBranchStock,
    minBranchStockExcluded,
    minMargin,
    minMarginExcluded,
    minRecallPrice,
    minRecallPriceExcluded,
    productGroups,
    seasons,
    shopTypes,
    sizeLevelFilters,
    suppliers
  } = criteria
  const filters = {
    skuLevelFilters,
    colourLevelFilters,
    // cumulativeWeeks,
    gender,
    maxWarehouseStock,
    maxWarehouseStockExcluded,
    minBranchStock,
    minBranchStockExcluded,
    minMargin,
    minMarginExcluded,
    minRecallPrice,
    minRecallPriceExcluded,
    productGroups,
    seasons,
    shopTypes,
    sizeLevelFilters,
    suppliers,
  }

  // console.log(filters)

  // Obviously zero, no need to make a request:
  if (
    (filters.skuLevelFilters.includeOrExclude === 'include' && filters.skuLevelFilters.selectedItems.length === 0)
    || (filters.colourLevelFilters.includeOrExclude === 'include' && filters.colourLevelFilters.selectedItems.length === 0)
    || (filters.sizeLevelFilters.includeOrExclude === 'include' && filters.sizeLevelFilters.selectedItems.length === 0)
  ) {
    dispatch({ type: POPULATE_SKUS_COUNT, payload: 0 })
    return
  }

  // Look for the value in the keyed (by filtersUUID below) cache
  const filtersForUUID = JSON.parse(JSON.stringify(filters)) // need to deep clone here, Object.assign is not enough
  // the following cases are identical, get the value from the cache if
  // it's there rather than making unnecessary requests.
  if (filtersForUUID.skuLevelFilters.includeOrExclude === 'include-all') {
    filtersForUUID.skuLevelFilters.selectedItems = []
  }
  if (
    filtersForUUID.skuLevelFilters.includeOrExclude === 'exclude'
    && filtersForUUID.skuLevelFilters.selectedItems.length === 0
  ) {
    filtersForUUID.skuLevelFilters.includeOrExclude = 'include-all'
    filtersForUUID.skuLevelFilters.selectedItems = []
  }

  if (filtersForUUID.colourLevelFilters.includeOrExclude === 'include-all') {
    filtersForUUID.colourLevelFilters.selectedItems = []
  }
  if (
    filtersForUUID.colourLevelFilters.includeOrExclude === 'exclude'
    && filtersForUUID.colourLevelFilters.selectedItems.length === 0
  ) {
    filtersForUUID.colourLevelFilters.includeOrExclude = 'include-all'
    filtersForUUID.colourLevelFilters.selectedItems = []
  }

  if (filtersForUUID.sizeLevelFilters.includeOrExclude === 'include-all') {
    filtersForUUID.sizeLevelFilters.selectedItems = []
  }
  if (
    filtersForUUID.sizeLevelFilters.includeOrExclude === 'exclude'
    && filtersForUUID.sizeLevelFilters.selectedItems.length === 0
  ) {
    filtersForUUID.sizeLevelFilters.includeOrExclude = 'include-all'
    filtersForUUID.sizeLevelFilters.selectedItems = []
  }

  const filtersUUID = uuid(JSON.stringify(filtersForUUID))
  const cacheEntry = cache.find(c => c.key === filtersUUID)
  if (cacheEntry) {
    dispatch({ type: POPULATE_SKUS_COUNT, payload: cacheEntry.value })
    return
  }

  // Not in cache, make a request
  try {
    dispatch({ type: SET_SKUS_REQUEST_IN_PROGRESS, payload: true })
    dispatch({ type: FETCH_SKUS_COUNT })
    const response = await axios.post('/postCountSKUs', {
      filters
    })
    // console.log(response.data)
    dispatch({ type: POPULATE_SKUS_COUNT, payload: response.data.total })
    dispatch({
      type: SET_SKUS_CACHE,
      payload: {
        key: filtersUUID,
        value: response.data.total
      }
    })
  } catch (e) {
    // console.log(e)
    dispatch({
      type: SET_SKUS_ERROR,
      payload: parseError(`countSKUsAction - ${parseError(e)}`)
    })
  } finally {
    dispatch({ type: SET_SKUS_REQUEST_IN_PROGRESS, payload: false })
  }
}

export default reducer
