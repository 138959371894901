import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
}))

const NoPaddingContainer = ({ children, maxWidth }) => {
  const classes = useStyles()

  return (
    <Container maxWidth={maxWidth} className={classes.root}>
      {children}
    </Container>
  )
}

NoPaddingContainer.propTypes = {
  children: PropTypes.any,
  maxWidth: PropTypes.string.isRequired,
}
NoPaddingContainer.defaultProps = {
  children: null,
}

export default NoPaddingContainer
