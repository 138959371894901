// Based on https://material-ui.com/components/autocomplete/ downshift multi-select example

import React from 'react'
import PropTypes from 'prop-types'
import deburr from 'lodash/deburr'
import Downshift from 'downshift'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginRight: 0,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    height: 32, // same as Chip height, otherwise descenders are hidden
    width: 'auto',
    flexGrow: 1,
  },
}))

const renderInput = inputProps => {
  const { InputProps, classes, maxHeight, ref, ...other } = inputProps

  return (
    <TextField
      style={{
        maxHeight: maxHeight || null,
        overflowY: maxHeight ? 'scroll' : null,
      }}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  )
}

renderInput.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
}

const renderSuggestion = suggestionProps => {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps
  const isHighlighted = highlightedIndex === index
  const isSelected =
    (selectedItem || '').indexOf(suggestion.value.toString()) > -1

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  )
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.number,
  ]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
}

const getSuggestions = (
  suggestions,
  selectedItem,
  value,
  { showEmpty = false } = {}
) => {
  const inputValue = deburr(value.trim()).toLowerCase()
  const inputLength = inputValue.length
  let count = 0

  // Don't show already selected items
  const filteredSuggestions = suggestions.filter(s => {
    return !selectedItem.map(s => s.value).includes(s.value)
  })

  return inputLength === 0 && !showEmpty
    ? []
    : filteredSuggestions.filter(suggestion => {
        const keep =
          count < 5 &&
          // suggestion.label.slice(0, inputLength).toLowerCase() === inputValue
          suggestion.label
            .trim()
            .toLowerCase()
            .indexOf(inputValue.trim().toLowerCase()) > -1

        if (keep) {
          count += 1
        }

        return keep
      })
}

const DownshiftMultipleSelect = React.memo(
  ({
    id,
    label,
    maxHeight,
    placeholder,
    selectedItem,
    visibleSelectedItem,
    setSelectedItem,
    suggestions,
  }) => {
    const classes = useStyles()

    const [inputValue, setInputValue] = React.useState('')

    const handleKeyDown = event => {
      if (
        selectedItem.length &&
        !inputValue.length &&
        event.key === 'Backspace'
      ) {
        const newSelectedItem = selectedItem.slice(0, selectedItem.length - 1)
        setSelectedItem(newSelectedItem)
      }
    }

    const handleInputChange = e => {
      setInputValue(e.target.value)
    }

    const handleChange = item => {
      if (item === null) {
        // handle escape key when suggestions dropdown list is open
        setInputValue('')
        return
      }

      let newSelectedItem = [...selectedItem]
      if (newSelectedItem.indexOf(item) === -1) {
        newSelectedItem = [...newSelectedItem, item]
      }
      setInputValue('')
      setSelectedItem(newSelectedItem)
    }

    const handleDelete = item => () => {
      const newSelectedItem = [...selectedItem]
      newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
      setSelectedItem(newSelectedItem)
    }

    if (!visibleSelectedItem) {
      visibleSelectedItem = selectedItem.slice()
    }

    return (
      <div className={classes.root}>
        <Downshift
          id={id}
          inputValue={inputValue}
          itemToString={item => item.label}
          onChange={handleChange}
          selectedItem={selectedItem}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            highlightedIndex,
            inputValue: inputValue2,
            isOpen,
            selectedItem: selectedItem2,
          }) => {
            const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
              onKeyDown: handleKeyDown,
              placeholder: selectedItem2.length === 0 ? placeholder : null,
            })

            return (
              <div className={classes.container}>
                {renderInput({
                  classes,
                  fullWidth: true,
                  InputLabelProps: getLabelProps(),
                  InputProps: {
                    startAdornment: visibleSelectedItem.map((item, index) => {
                      return (
                        <Chip
                          key={item.value}
                          tabIndex={-1}
                          label={item.label}
                          className={classes.chip}
                          onDelete={handleDelete(item)}
                        />
                      )
                    }),
                    onBlur,
                    onChange: event => {
                      handleInputChange(event)
                      onChange(event)
                    },
                    onFocus,
                  },
                  inputProps,
                  maxHeight,
                  label,
                })}

                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(suggestions, selectedItem, inputValue2).map(
                      (suggestion, index) =>
                        renderSuggestion({
                          highlightedIndex,
                          index,
                          itemProps: getItemProps({ item: suggestion }),
                          selectedItem: selectedItem2,
                          suggestion,
                        })
                    )}
                  </Paper>
                ) : null}

                {visibleSelectedItem &&
                  selectedItem.length > visibleSelectedItem.length && (
                    <Box mt={2} display="flex" justifyContent="flex-end">
                      <Typography variant="caption">
                        <em>
                          Displaying first {visibleSelectedItem.length} selected
                          items only ({selectedItem.length} total).
                        </em>
                      </Typography>
                    </Box>
                  )}
              </div>
            )
          }}
        </Downshift>
      </div>
    )
  }
)

DownshiftMultipleSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  maxHeight: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  selectedItem: PropTypes.array.isRequired,
  visibleSelectedItem: PropTypes.array,
  setSelectedItem: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    })
  ).isRequired,
}
DownshiftMultipleSelect.defaultProps = {
  label: null,
  maxHeight: null,
  visibleSelectedItem: null,
}

DownshiftMultipleSelect.displayName = 'DownshiftMultipleSelect'
// DownshiftMultipleSelect.whyDidYouRender = true

export default DownshiftMultipleSelect
