import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'

import SizeGroupTitle from './SizeGroupTitle'
import SelectedSizesCount from './SelectedSizesCount'

const SizeGroupHeader = ({ handleTitleSave, title, sizesCount }) => {
  return (
    <Box display="flex" mb={2} alignItems="center">
      <Box flex={1} mr={2}>
        <SizeGroupTitle initialValue={title} onSave={handleTitleSave} />
      </Box>
      <Box
        flex={1}
        alignItems="center"
        display="flex"
        justifyContent="flex-end"
      >
        <SelectedSizesCount count={sizesCount} />
      </Box>
    </Box>
  )
}

SizeGroupHeader.propTypes = {
  handleTitleSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  sizesCount: PropTypes.number.isRequired
}

export default SizeGroupHeader
