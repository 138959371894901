import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

const SelectedSizesCount = ({ count }) => {
  return (
    <Typography>
      {count} size
      {count !== 1 ? 's' : ''} selected.
    </Typography>
  )
}

SelectedSizesCount.propTypes = {
  count: PropTypes.number.isRequired
}

export default SelectedSizesCount
