import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Button from '@material-ui/core/Button'
import MuiLink from '@material-ui/core/Link'
import { Link } from 'react-router-dom'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

const RecallInProgressDialog = ({ recalls, handleClose, isOpen }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth scroll="paper">
      <DialogTitle>Recall In Progress</DialogTitle>
      <DialogContent>
        <Typography gutterBottom>
          There is at least one recall that is currently being issued. Please
          wait for it to finish.
        </Typography>
        <Typography>
          The Find Stock procedure queries existing recalls to exclude units
          that have been requested for recall but not actioned by the store; incomplete data could yield incorrect results.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Table size="small">
          <TableBody>
            {recalls.map(r => (
              <TableRow key={r.recallId}>
                <TableCell>
                  <MuiLink component={Link} to={`/recall/${r.recallId}`}>
                    {r.title}
                  </MuiLink>
                </TableCell>
                <TableCell>
                  {format(parseISO(r.issuedDate), 'yyyy-MM-dd')}
                </TableCell>
                <TableCell>{r.lastStatus}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

RecallInProgressDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  recalls: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default RecallInProgressDialog
