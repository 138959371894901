import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Link from '@material-ui/core/Link'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { CSVLink } from 'react-csv'
import format from 'date-fns/format'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  formControl: {
    marginRight: 0,
  },
}))

const dateString = format(new Date(), 'yyyy-MM-dd-HH-mm-ss')

const CSVDownloadLink = ({ findStockData }) => {
  const classes = useStyles()
  const [csvData, setCsvData] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [isBranchAnalysisIncluded, setBranchAnalysisIncluded] = useState(false)

  const branchItems = useSelector(state => state.data.branches.items)

  useEffect(() => {
    let didCancel = false

    const makeCsvData = () => {
      setLoading(true)

      // This needs to be wrapped in setTimeout so that there is a rerender after the setLoading flag is set.
      // I don't quite understand why and it feels like a hack, but it works.
      setTimeout(() => {
        let newCsvData

        if (isBranchAnalysisIncluded) {
          // One row for each branch
          newCsvData = []

          findStockData.forEach(row => {
            const outRow = { ...row }
            delete outRow.branchAnalysis
            row.branchAnalysis.forEach(b => {
              const branchIdValue = b.BRANCH_ID || b.branchID || b.branchId
              let branchDescription = ''
              // look up the branch name (it's not saved in the compressed data in the db)
              const branch = branchItems.find(
                bb => parseInt(bb.branch_id, 10) === parseInt(branchIdValue, 10)
              )
              branchDescription = branch
                ? branch.branch_desc
                : b.BRANCH_DESC || branchIdValue
              if (branchIdValue === 23) {
                branchDescription = 'WHO Warehouse'
              }
              if (branchIdValue === 47) {
                branchDescription = 'Home Shopping'
              }

              const outBranch = { branchDescription, ...b,  comment: b.comment || '' } // make sure they all have a comment field
              delete b.SKU_ID
              newCsvData.push({
                ...outRow,
                ...outBranch,
              })
            })
          })
        } else {
          // A single row for each SKU, minus the branch analysis data
          newCsvData = findStockData.map(row => {
            const out = { ...row }
            delete out.branchAnalysis
            return out
          })
        }

        if (didCancel) return
        setCsvData(newCsvData)
        setLoading(false)
      }, 0)
    }

    makeCsvData()

    return () => {
      didCancel = true
    }
  }, [branchItems, findStockData, isBranchAnalysisIncluded])

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-end">
      {!isLoading ? (
        <Link
          component={CSVLink}
          data={csvData}
          filename={`${dateString}-recall-skus.csv`}
          variant="body1"
        >
          Download CSV file
        </Link>
      ) : (
        <Typography variant="body1">Generating CSV file...</Typography>
      )}
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            checked={isBranchAnalysisIncluded}
            onChange={e => setBranchAnalysisIncluded(e.target.checked)}
            value="ba"
            color="primary"
          />
        }
        disabled={isLoading}
        label="Include branch analysis"
      />
    </Box>
  )
}

CSVDownloadLink.propTypes = {
  findStockData: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CSVDownloadLink
