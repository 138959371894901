import * as constants from '../../constants'

export const resetAppAction = (overrides = {}) => ({
  type: constants.RESET_APP,
  payload: overrides
})

export const setActiveRecallIdAction = value => ({
  type: constants.SET_ACTIVE_RECALL_ID,
  payload: value
})

export const setActiveScreenAction = screen => ({
  type: constants.SET_ACTIVE_SCREEN,
  payload: screen
})

export const setSidebarOpenAction = value => ({
  type: constants.SET_SIDEBAR_OPEN,
  payload: value
})

export const setThemeAction = theme => ({
  type: constants.SET_THEME,
  payload: theme
})

export const setThemeTypeAction = themeType => ({
  type: constants.SET_THEME_TYPE,
  payload: themeType
})

export const setWebSocketMessageAction = message => ({
  type: constants.SET_WEBSOCKET_MESSAGE,
  payload: message
})

export const setWebSocketIdAction = value => ({
  type: constants.SET_WEBSOCKET_ID,
  payload: value
})

export const showRefundsAction = value => ({
  type: constants.SHOW_REFUNDS,
  payload: value,
})
