import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import InfoButton from '../../InfoButton'

import { setRecallTimeframeAction } from '../../../redux/reducers/criteria/actions'

const marks = [
  { value: 0, label: '0' },
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
  { value: 60, label: '60' },
  { value: 70, label: '70' },
  { value: 80, label: '80' },
  { value: 90, label: '90' },
  { value: 100, label: '100' }
]

const RecallTimeframe = () => {
  const dispatch = useDispatch()
  const recallTimeframe = useSelector(
    state => state.criteria.recallTimeframe
  )
  const [localValue, setLocalValue] = useState(recallTimeframe)

  return (
    <>
      <Box display="flex">
        <Typography id="units-to-recall">
          Recall timeframe (days)
          <InfoButton
            tooltipContent={
              <>
                <Typography variant="h6" gutterBottom>
                  Recall timeframe (days)
                </Typography>
                <Typography variant="body2" gutterBottom>
                  If a unit has been requested for recall from a branch within
                  this number of days and not actioned, we will not try to
                  recall it again. These units are also excluded from branch
                  stock figures.
                </Typography>
              </>
            }
          />
        </Typography>
      </Box>
      <Typography variant="caption" color="error">
          <em>This parameter is currently disabled pending implementation questions. It's set to 15 days, which is the current value of the recall timeframe parameter used to populate <em>RMIS.dbo.Product_Availability</em>.</em>
      </Typography>
      <Slider
        aria-labelledby="recallTimeframe"
        disabled
        value={localValue}
        getAriaValueText={v => v.toString()}
        marks={marks}
        max={100}
        min={0}
        onChange={(_, value) => setLocalValue(value)}
        onChangeCommitted={() => dispatch(setRecallTimeframeAction(localValue))}
        step={1}
        valueLabelDisplay="auto"
      />
    </>
  )
}

export default RecallTimeframe
