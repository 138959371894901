import React, { useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import uniqBy from 'lodash/uniqBy'

import NoPaddingContainer from '../../NoPaddingContainer'
import Gender from './Gender'
import ProductGroups from './ProductGroups'
import ShopTypes from './ShopTypes'
import Suppliers from './Suppliers'
import Seasons from './Seasons'
import NavButtons from '../NavButtons'
import InfoButton from '../../InfoButton'
import Label from '../../Label'
import NavError from '../NavError'
import {
  setGenderAction,
  setShopTypesAction,
  setStepAction,
  setStepCompletedAction
} from '../../../redux/reducers/criteria/actions'
import * as constants from '../../../redux/constants'
import { getIncludedItemsCount } from '../../../helpers'

const useStyles = makeStyles(theme => ({
  paper: {
    height: '100%',
    padding: theme.spacing(2)
  }
}))

const Filters = React.memo(() => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const {
    criteria: { productGroups, shopTypes, gender, seasons, suppliers },
    data: {
      genders: { items: genderItems },
      productGroups: { items: productGroupsItems },
      shopTypes: { isLoaded: shopTypesIsLoaded, items: shopTypeItems },
      seasons: { items: seasonItems },
      suppliers: { items: supplierItems }
    }
  } = useSelector(state => state)

  if (!shopTypesIsLoaded) return null

  const shopTypeOptions = useMemo(
    () =>
      uniqBy(
        shopTypeItems.map(s => ({
          label: s.shopType,
          value: s.shopType.toLowerCase()
        })),
        'value'
      ),
    [shopTypeItems]
  )
  const genderOptions = useMemo(
    () =>
      genderItems.map(g => ({
        label: g.gender,
        value: g.gender
      })),
    [genderItems]
  )

  const productGroupsCount = useMemo(
    () =>
      getIncludedItemsCount(
        productGroups.includeOrExclude,
        productGroupsItems.length,
        productGroups.selectedItems.length
      ),
    [productGroups, productGroupsItems]
  )
  const seasonsCount = useMemo(
    () =>
      getIncludedItemsCount(
        seasons.includeOrExclude,
        seasonItems.length,
        seasons.selectedItems.length
      ),
    [seasons, seasonItems]
  )
  const suppliersCount = useMemo(
    () =>
      getIncludedItemsCount(
        suppliers.includeOrExclude,
        supplierItems.length,
        suppliers.selectedItems.length
      ),
    [suppliers, supplierItems]
  )

  const isNextButtonDisabled =
    shopTypes.length === 0 ||
    productGroupsCount === 0 ||
    seasonsCount === 0 ||
    suppliersCount === 0

  const handleShopTypesChange = useCallback(
    values => dispatch(setShopTypesAction(values)),
    [dispatch]
  )
  const handleGenderChange = useCallback(
    e => dispatch(setGenderAction(e.target.value)),
    [dispatch]
  )
  const handleNextButtonClick = useCallback(() => {
    dispatch(setStepCompletedAction(constants.STEP_FILTERS, true))
    dispatch(setStepAction(constants.STEP_PARAMETERS))
  }, [dispatch])

  return (
    <NoPaddingContainer maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={3}>
            <ShopTypes
              handleChange={handleShopTypesChange}
              options={shopTypeOptions}
              values={shopTypes}
            />
          </Paper>
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <Seasons />
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper className={classes.paper} elevation={3}>
            <Typography variant="h5" component="h2" gutterBottom>
              Product Groups
              <InfoButton
                tooltipContent={
                  <>
                    <Typography variant="h6" gutterBottom>
                      Product Groups
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      Only SKUs in the included product groups will be
                      considered for recall. Product group information comes
                      from <em>RMIS.dbo.product_master`</em> via{' '}
                      <em>j_code_link</em> to{' '}
                      <em>RMIS.dbo.Group_Master.description</em>.
                    </Typography>
                    <Typography variant="body2" paragraph>
                      The
                      <Label text="label" isSuccess />
                      shows how many product groups are included. At least one
                      must be included.
                    </Typography>
                  </>
                }
              />
            </Typography>
            <Typography variant="body2" paragraph>
              Available product groups depend on the seasons selected above.
            </Typography>
            <Typography variant="body2" paragraph>
              Select gender to filter product groups further.
            </Typography>
            <Gender
              handleChange={handleGenderChange}
              options={genderOptions}
              value={gender}
            />
            <ProductGroups />
          </Paper>
        </Grid>

        <Grid item sm={6}>
          <Paper className={classes.paper} elevation={3}>
            <Suppliers />
          </Paper>
        </Grid>
      </Grid>

      <NavButtons
        backButtonText="Back"
        // handleBack={handleBackButtonClick}
        handleNext={handleNextButtonClick}
        isDisabled={isNextButtonDisabled}
        nextButtonText="Parameters"
      />
      {shopTypes.length === 0 && (
        <NavError message="At least one shop type must be selected to continue." />
      )}
      {productGroupsCount === 0 && (
        <NavError message="There must be at least one product group included to continue." />
      )}
      {seasonsCount === 0 && (
        <NavError message="There must be at least one season included to continue." />
      )}
      {suppliersCount === 0 && (
        <NavError message="There must be at least one supplier included to continue." />
      )}
    </NoPaddingContainer>
  )
})

Filters.displayName = 'Filters'
// Filters.whyDidYouRender = true

export default Filters
