import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import LoadingScreen from './LoadingScreen'

const IssuingRecallLoader = () => {
  const {
    global: { webSocketMessage }
  } = useSelector(state => state)

  return (
    <LoadingScreen hideCircularProgress>
      <Box
        display="flex"
        alignItems="center"
        minWidth={320}
        flexDirection="column"
      >
        <Typography>Issuing recall</Typography>
        <Box my={2}>
          <CircularProgress />
        </Box>
        <Typography>
          {webSocketMessage ? `${webSocketMessage}...` : ''}
        </Typography>
      </Box>
    </LoadingScreen>
  )
}

export default IssuingRecallLoader
