import * as constants from '../../constants'

export const recallInitialState = {
  error: false,
  isIssuingRecall: false,
  newRecallId: null,
  recallUnitsIssued: 0,
}

const reducer = (state = recallInitialState, action) => {
  switch (action.type) {
    case constants.COMPLETE_ISSUE_RECALL:
      return {
        ...state,
        isIssuingRecall: false,
      }
    case constants.SET_NEW_RECALL_ID:
      return {
        ...state,
        newRecallId: action.payload,
      }
    case constants.ISSUE_RECALL:
      return {
        ...state,
        isIssuingRecall: true,
        recallUnitsIssued: 0,
      }
    case constants.RESET_APP:
      return recallInitialState
    case constants.SET_RECALL_PROGRESS:
      return {
        ...state,
        recallUnitsIssued: action.payload,
      }
    case constants.SET_RECALL_ERROR:
      return {
        ...state,
        error: action.payload,
        isIssuingRecall: false,
      }
    default:
      return state
  }
}

export default reducer
